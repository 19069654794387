@use '_shared-styles' as shared-styles;

.c-bds-docs__height-demo {
  @extend %bolt-demo-wrapper;
  margin-bottom: 2rem;
}

.c-bds-docs__height-item {
  overflow: hidden;
  margin-top: 0.75rem;
  border: 1px solid var(--bolt-color-navy-light);
}
