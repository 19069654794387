@import '@bolt/core';

/* ------------------------------------ *\
    Teaser
\* ------------------------------------ */

.c-bolt-teaser {
  --c-bolt-teaser-gutter: unset;
  --c-bolt-teaser-signifier-aspect-ratio: 16/9;

  display: grid;
  align-content: start;
  column-gap: var(--c-bolt-teaser-gutter);
  position: relative;
  margin-bottom: var(--bolt-spacing-y-medium);

  &:last-child {
    margin-bottom: 0;
  }

  &.c-bolt-teaser--no-aspect-ratio {
    .c-bolt-teaser__signifier {
      aspect-ratio: unset;

      .c-bolt-teaser__signifier-image {
        all: unset;
      }
    }
  }
}

.c-bolt-teaser--horizontal {
  --c-bolt-teaser-signifier-aspect-ratio: 1/1;

  align-items: center;
  grid-template-columns: auto 1fr;
}

.c-bolt-teaser--responsive {
  @include bolt-mq(medium) {
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
}

.c-bolt-teaser--gutter-small {
  --c-bolt-teaser-gutter: var(--bolt-spacing-x-small);
}

.c-bolt-teaser--gutter-medium {
  --c-bolt-teaser-gutter: var(--bolt-spacing-x-medium);
}

.c-bolt-teaser--gutter-large {
  --c-bolt-teaser-gutter: var(--bolt-spacing-x-large);
}

@mixin bolt-teaser-horizontal-variant-card {
  padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);

  .c-bolt-teaser__signifier,
  .c-bolt-teaser__signifier img {
    border-radius: bolt-border-radius(small);
  }

  &.c-bolt-teaser--border-radius-large {
    .c-bolt-teaser__signifier,
    .c-bolt-teaser__signifier img {
      border-radius: 0.37em;
    }
  }

  .c-bolt-teaser__content {
    padding: 0;
  }

  &.c-bolt-teaser--spacing-medium {
    padding: var(--bolt-spacing-y-medium) var(--bolt-spacing-x-medium);
  }

  &.c-bolt-teaser--spacing-large {
    padding: var(--bolt-spacing-y-large) var(--bolt-spacing-x-large);
  }
}

.c-bolt-teaser--variant-card {
  @include bolt-shadow('level-20');

  border-radius: bolt-border-radius(small);

  &:before {
    border-radius: bolt-border-radius(small);
  }

  .c-bolt-teaser__signifier,
  .c-bolt-teaser__signifier img {
    border-top-right-radius: bolt-border-radius(small);
    border-top-left-radius: bolt-border-radius(small);
  }

  &.c-bolt-teaser--hoverable {
    will-change: transform;
    transition: transform var(--bolt-transition);

    &:before {
      @include bolt-shadow('level-30');
      content: '';
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      will-change: opacity;
      transition: opacity var(--bolt-transition);
    }

    &:hover,
    &:focus-within,
    &:active {
      @include bolt-shadow('level-30');
      transform: translate3d(0, -1px, 0);

      &:before {
        opacity: 1;
      }
    }

    .c-bolt-teaser__eyebrow,
    .c-bolt-teaser__description,
    .c-bolt-teaser__chips {
      pointer-events: none;
    }
  }

  &[class*='u-bolt-bg-gradient'] {
    border-color: var(--m-bolt-bg);
  }

  &:not([class*='t-bolt-']) {
    @include bolt-base-light-mode;
    @include bolt-base-color-defaults;
  }

  &.c-bolt-teaser--spacing-medium {
    .c-bolt-teaser__content {
      padding: var(--bolt-spacing-y-medium) var(--bolt-spacing-x-medium);
    }
  }

  &.c-bolt-teaser--spacing-large {
    .c-bolt-teaser__content {
      padding: var(--bolt-spacing-y-large) var(--bolt-spacing-x-large);
    }
  }

  &.c-bolt-teaser--border-radius-large {
    border-radius: bolt-border-radius(large);

    &:before {
      border-radius: bolt-border-radius(large);
    }

    .c-bolt-teaser__signifier,
    .c-bolt-teaser__signifier img {
      border-top-right-radius: bolt-border-radius(large);
      border-top-left-radius: bolt-border-radius(large);
    }
  }

  .c-bolt-teaser__signifier {
    --c-bolt-teaser-signifier-aspect-ratio: unset;
    margin-bottom: 0;
  }

  .c-bolt-teaser__signifier-image {
    all: unset;
  }

  .c-bolt-teaser__content {
    padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
  }

  &.c-bolt-teaser--horizontal {
    @include bolt-teaser-horizontal-variant-card;
  }

  @include bolt-mq(medium) {
    &.c-bolt-teaser--responsive {
      @include bolt-teaser-horizontal-variant-card;
    }
  }
}

.c-bolt-teaser__signifier-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &--valign-start {
    justify-content: flex-start;
  }

  &--valign-center {
    justify-content: center;
  }

  &--valign-end {
    justify-content: flex-end;
  }
}

.c-bolt-teaser__signifier {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: var(--bolt-spacing-x-xsmall);
  aspect-ratio: var(--c-bolt-teaser-signifier-aspect-ratio);
  // @supports can be removed when aspect-ratio is fully supported by the newest browsers. See caniuse for the reference.
  @supports not (aspect-ratio: auto) {
    height: 0;
    padding-bottom: calc(9 / 16 * 100%);
  }

  @at-root .c-bolt-teaser--horizontal #{&} {
    width: clamp(10ch, 20vw, 14ch);

    // @supports can be removed when aspect-ratio is fully supported by the newest browsers. See caniuse for the reference.
    @supports not (aspect-ratio: auto) {
      padding-bottom: 100%;
    }
  }

  @at-root .c-bolt-teaser--responsive #{&} {
    @include bolt-mq(medium) {
      margin-bottom: auto;
    }
  }
}

.c-bolt-teaser__signifier-image {
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  place-items: center;

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  bolt-image {
    width: 100%;
  }
}

.c-bolt-teaser__signifier-locked {
  display: grid;
  position: absolute;
  top: var(--bolt-spacing-y-small);
  left: var(--bolt-spacing-x-small);
  width: 1.8em;
  height: 1.8em;
  font-size: var(--bolt-type-font-size-small);
  line-height: 1;
  border-radius: bolt-border-radius(full);
  box-shadow: bolt-shadow('level-10');
  background-color: var(--bolt-color-teal-light);
  place-items: center;

  @at-root .c-bolt-teaser--horizontal #{&} {
    top: var(--bolt-spacing-y-xsmall);
    left: var(--bolt-spacing-x-xsmall);
  }
}

.c-bolt-teaser__signifier-type {
  position: absolute;
  bottom: var(--bolt-spacing-y-small);
  left: var(--bolt-spacing-x-small);

  @at-root .c-bolt-teaser--horizontal #{&} {
    bottom: var(--bolt-spacing-y-xsmall);
    left: var(--bolt-spacing-x-xsmall);
  }
}

.c-bolt-teaser__signifier-type--video {
  display: block;
  transform: scale(0.1);
  width: 100%;
  height: 120%;
  overflow: hidden;
  border-radius: bolt-border-radius(large);
  box-shadow: 0 0 2em var(--bolt-color-gray);
  background-color: var(--bolt-color-yellow);
  transform-origin: bottom left;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 25%;
    height: 45%;
    background-color: var(--bolt-color-black);
    clip-path: polygon(0 0, 0 100%, 100% 50%);
  }

  @at-root .c-bolt-teaser--horizontal #{&} {
    transform: scale(0.25);
    height: 100%;

    &:before {
      width: 45%;
    }

    @include bolt-mq(small) {
      transform: scale(0.15);
    }
  }
}

.c-bolt-teaser__signifier-time {
  position: absolute;
  right: var(--bolt-spacing-x-small);
  bottom: var(--bolt-spacing-y-small);
  max-width: calc(100% - var(--bolt-spacing-x-xsmall) * 2);
  overflow: hidden;
  padding: var(--bolt-spacing-y-xxsmall) var(--bolt-spacing-x-xxsmall);
  font-size: var(--bolt-type-font-size-tiny);
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--bolt-color-white);
  line-height: var(--bolt-type-line-height-tiny);
  border-radius: bolt-border-radius(small);
  background-color: rgba(black, 0.6);
  white-space: nowrap;
  text-overflow: ellipsis;

  @at-root .c-bolt-teaser--horizontal #{&} {
    right: var(--bolt-spacing-x-xsmall);
    bottom: var(--bolt-spacing-y-xsmall);
  }
}

.c-bolt-teaser__signifier-description {
  display: grid;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: var(--bolt-spacing-y-medium) 0;
  color: var(--bolt-color-white);
  background-color: black;
  place-items: center;
  transition: opacity var(--bolt-transition);

  @at-root .c-bolt-teaser:hover #{&} {
    opacity: 0.8;
  }
}

.c-bolt-teaser__signifier-description-text {
  display: grid; // Grid and place-items required to center shorter descriptions
  place-items: center;
  position: relative;
  width: 197px; // Setting a width is the only way to make the ellipsis work in 99% of use cases, without complex JS counting lines and characters.
  max-width: 100%;
  height: calc(
    var(--bolt-type-line-height-small) * 3em
  ); // Height and line-height must match to limit to 3 lines of text.
  overflow: hidden;
  font-size: var(--bolt-type-font-size-small);
  font-weight: var(--bolt-type-font-weight-semibold);
  line-height: var(
    --bolt-type-line-height-small
  ); // Height and line-height must match to limit to 3 lines of text.
  hyphens: auto;
  word-break: normal;
}

.c-bolt-teaser__signifier-description-text--line-clamp {
  // https://css-tricks.com/almanac/properties/l/line-clamp/
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.c-bolt-teaser__content {
  display: flex;
  flex-direction: column;

  > * {
    order: 2;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.c-bolt-teaser__eyebrow,
.c-bolt-teaser__headline,
.c-bolt-teaser__subheadline {
  margin-bottom: var(--bolt-spacing-y-xxsmall);
}

.c-bolt-teaser__description {
  margin-bottom: var(--bolt-spacing-y-small);
}

.c-bolt-teaser__headline {
  & + .c-bolt-teaser__subheadline {
    margin-top: calc(var(--bolt-spacing-y-xxsmall) * -2);
  }

  @at-root .c-bolt-teaser--responsive #{&} {
    @include bolt-mq(medium) {
      margin-bottom: var(--bolt-spacing-y-xsmall);
    }
  }
}

.c-bolt-teaser__eyebrow {
  order: 1;
}

.c-bolt-teaser__action-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--bolt-spacing-y-xxsmall);
  column-gap: var(--bolt-spacing-x-small);
  order: 0;
  position: relative;
  z-index: 1; // Must use z-index to fix stacking order in FF.
  margin: 0 0 var(--bolt-spacing-y-small) 0;
  padding: 0;
  font-size: var(--bolt-type-font-size-xsmall);
  list-style: none;
  line-height: var(--bolt-type-line-height-xsmall);
  white-space: nowrap;

  @at-root .c-bolt-teaser--horizontal #{&} {
    order: 2;
    margin-bottom: var(--bolt-spacing-y-xsmall);
  }

  @at-root .c-bolt-teaser--responsive #{&} {
    @include bolt-mq(medium) {
      order: 2;
      margin-bottom: var(--bolt-spacing-y-xsmall);
    }
  }
}

.c-bolt-teaser__action-list-item {
  margin: 0;
  padding: 0;

  .e-bolt-text-link {
    display: grid;
    grid-template-columns: 1fr auto;
  }
}

.c-bolt-teaser__views {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--bolt-spacing-x-xxsmall);
  font-size: var(--bolt-type-font-size-xsmall);
  line-height: var(--bolt-type-line-height-xsmall);
}

.c-bolt-teaser__views-icon {
  @include bolt-inline-icon-wrapper;
}
