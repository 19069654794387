@use 'sass:list';
@use '@bolt/core/styles/01-settings/settings-typography/settings-typography' as settings-typography;

@mixin bolt-deprecated-type-vars() {
  // Font size system only for type element
  @each $size in settings-typography.$bolt-type-font-size-system--deprecated {
    $size-name: list.nth($size, 1);
    $size-value: list.nth($size, 2);

    --bolt-type-font-size-#{$size-name}: #{$size-value};
  }
  // Line height system only for type element
  @each $height in settings-typography.$bolt-type-line-height-system--deprecated {
    $height-name: list.nth($height, 1);
    $height-value: list.nth($height, 2);

    --bolt-type-line-height-#{$height-name}: #{$height-value};
  }

  @at-root [lang='ja'] & {
    --bolt-type-font-size-xxxlarge: 2.1rem;
    --bolt-type-font-size-xxlarge: 1.6rem;
    --bolt-type-font-size-xlarge: 1.35rem;
    --bolt-type-font-size-large: 1.125rem;
    --bolt-type-font-size-medium: 1rem;
    --bolt-type-font-size-small: 0.85rem;
    --bolt-type-font-size-xsmall: 0.75rem;
    --bolt-type-font-size-xxsmall: 0.65rem;

    --bolt-type-line-height-xxxlarge: 1.25;
    --bolt-type-line-height-xxlarge: 1.35;
    --bolt-type-line-height-xlarge: 1.3;
    --bolt-type-line-height-large: 1.3;
    --bolt-type-line-height-medium: 1.5;
    --bolt-type-line-height-small: 1.4;
    --bolt-type-line-height-xsmall: 1.4;
    --bolt-type-line-height-xxsmall: 1.35;
  }

  @at-root [data-bolt-info-density='compact'] & {
    --bolt-type-font-size-xxxlarge: 2.1rem;
    --bolt-type-font-size-xxlarge: 1.6rem;
    --bolt-type-font-size-xlarge: 1.35rem;
    --bolt-type-font-size-large: 1.125rem;
    --bolt-type-font-size-medium: 1rem;
    --bolt-type-font-size-small: 0.85rem;
    --bolt-type-font-size-xsmall: 0.75rem;
    --bolt-type-font-size-xxsmall: 0.65rem;

    --bolt-type-line-height-xxxlarge: 1.25;
    --bolt-type-line-height-xxlarge: 1.35;
    --bolt-type-line-height-xlarge: 1.3;
    --bolt-type-line-height-large: 1.3;
    --bolt-type-line-height-medium: 1.5;
    --bolt-type-line-height-small: 1.4;
    --bolt-type-line-height-xsmall: 1.4;
    --bolt-type-line-height-xxsmall: 1.35;
  }
  @at-root [data-bolt-info-density='comfortable'] & {
    --bolt-type-font-size-xxxlarge: 2.75rem;
    --bolt-type-font-size-xxlarge: 1.9rem;
    --bolt-type-font-size-xlarge: 1.4rem;
    --bolt-type-font-size-large: 1.2rem;
    --bolt-type-font-size-medium: 1rem;
    --bolt-type-font-size-small: 0.9rem;
    --bolt-type-font-size-xsmall: 0.8rem;
    --bolt-type-font-size-xxsmall: 0.7rem;

    --bolt-type-line-height-xxxlarge: 1.25;
    --bolt-type-line-height-xxlarge: 1.35;
    --bolt-type-line-height-xlarge: 1.35;
    --bolt-type-line-height-large: 1.45;
    --bolt-type-line-height-medium: 1.6;
    --bolt-type-line-height-small: 1.5;
    --bolt-type-line-height-xsmall: 1.45;
    --bolt-type-line-height-xxsmall: 1.4;
  }
}
