
@use 'sass:math';
@use '@bolt/core' as core;

$device-shadow: 0 1px 2px 1px rgba(6, 10, 36, 0.1),
  0 5px 10px 1px rgba(6, 10, 36, 0.15), 0 15px 30px 0 rgba(6, 10, 36, 0.25);

$bolt-iphone8-viewer-height: 667px + (105px * 2);
$bolt-iphone8-viewer-width: 375px + (24px * 2);

.c-bolt-iphone8-viewer {
  width: 100%;
  max-width: $bolt-iphone8-viewer-width * 0.8;
  border-top-right-radius: (math.div(56px, $bolt-iphone8-viewer-width) * 100%)
    (math.div(56px, $bolt-iphone8-viewer-height) * 100%);

  border-top-left-radius: (math.div(56px, $bolt-iphone8-viewer-width) * 100%)
    (math.div(56px, $bolt-iphone8-viewer-height) * 100%);
  border-bottom-right-radius: (math.div(56px, $bolt-iphone8-viewer-width) * 100%)
    (math.div(56px, $bolt-iphone8-viewer-height) * 100%);
  border-bottom-left-radius: (math.div(56px, $bolt-iphone8-viewer-width) * 100%)
    (math.div(56px, $bolt-iphone8-viewer-height) * 100%);

  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2), $device-shadow;
  background-color: #d9dbdc;

  &.c-bolt-iphone8-viewer--landscape {
    max-width: $bolt-iphone8-viewer-height * 0.8;
    border-top-right-radius: (math.div(56px, $bolt-iphone8-viewer-height) * 100%)
      (math.div(56px, $bolt-iphone8-viewer-width) * 100%);

    border-top-left-radius: (math.div(56px, $bolt-iphone8-viewer-height) * 100%)
      (math.div(56px, $bolt-iphone8-viewer-width) * 100%);
    border-bottom-right-radius: (math.div(56px, $bolt-iphone8-viewer-height) * 100%)
      (math.div(56px, $bolt-iphone8-viewer-width) * 100%);
    border-bottom-left-radius: (math.div(56px, $bolt-iphone8-viewer-height) * 100%)
      (math.div(56px, $bolt-iphone8-viewer-width) * 100%);
  }

  &.c-bolt-iphone8-viewer--gold {
    background-color: #f9e7d3;
  }

  &.c-bolt-iphone8-viewer--black {
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.7), $device-shadow;
    background-color: #464646;
  }
}

.c-bolt-iphone8-viewer__inner {
  padding: (math.div(105px, $bolt-iphone8-viewer-width) * 100%)
    (math.div(24px, $bolt-iphone8-viewer-width) * 100%);

  .c-bolt-iphone8-viewer--landscape & {
    padding: (math.div(24px, $bolt-iphone8-viewer-height) * 100%)
      (math.div(105px, $bolt-iphone8-viewer-height) * 100%);
  }

  &:before {
    top: math.div(6px, $bolt-iphone8-viewer-height) * 100%;
    right: math.div(6px, $bolt-iphone8-viewer-width) * 100%;
    bottom: math.div(6px, $bolt-iphone8-viewer-height) * 100%;
    left: math.div(6px, $bolt-iphone8-viewer-width) * 100%;
    width: auto;
    height: auto;
    border-top-right-radius: (math.div(50px, $bolt-iphone8-viewer-width) * 100%)
      (math.div(50px, $bolt-iphone8-viewer-height) * 100%);

    border-top-left-radius: (math.div(50px, $bolt-iphone8-viewer-width) * 100%)
      (math.div(50px, $bolt-iphone8-viewer-height) * 100%);
    border-bottom-right-radius: (math.div(50px, $bolt-iphone8-viewer-width) * 100%)
      (math.div(50px, $bolt-iphone8-viewer-height) * 100%);
    border-bottom-left-radius: (math.div(50px, $bolt-iphone8-viewer-width) * 100%)
      (math.div(50px, $bolt-iphone8-viewer-height) * 100%);

    background-color: #f8f8f8;

    .c-bolt-iphone8-viewer--landscape & {
      top: math.div(6px, $bolt-iphone8-viewer-width) * 100%;
      right: math.div(6px,  $bolt-iphone8-viewer-height) * 100%;
      bottom: math.div(6px, $bolt-iphone8-viewer-width) * 100%;
      left: math.div(6px, $bolt-iphone8-viewer-height) * 100%;
      border-top-right-radius: (math.div(50px, $bolt-iphone8-viewer-height) * 100%)
        (math.div(50px, $bolt-iphone8-viewer-width) * 100%);
      border-top-left-radius: (math.div(50px, $bolt-iphone8-viewer-height) * 100%)
        (math.div(50px, $bolt-iphone8-viewer-width) * 100%);
      border-bottom-right-radius: (math.div(50px, $bolt-iphone8-viewer-height) * 100%)
        (math.div(50px, $bolt-iphone8-viewer-width) * 100%);
      border-bottom-left-radius: (math.div(50px, $bolt-iphone8-viewer-height) * 100%)
        (math.div(50px, $bolt-iphone8-viewer-width) * 100%);
    }

    .c-bolt-iphone8-viewer--black & {
      background-color: #080808;
    }
  }

  &:after {
    content: '';

    top: math.div(8px, $bolt-iphone8-viewer-height) * 100%;
    right: math.div(8px, $bolt-iphone8-viewer-width) * 100%;
    bottom: math.div(8px, $bolt-iphone8-viewer-height) * 100%;
    left: math.div(8px, $bolt-iphone8-viewer-width) * 100%;

    width: auto;
    height: auto;
    border-top-right-radius: (math.div(48px, $bolt-iphone8-viewer-width) * 100%)
      (math.div(48px, $bolt-iphone8-viewer-height) * 100%);

    border-top-left-radius: (math.div(48px, $bolt-iphone8-viewer-width) * 100%)
      (math.div(48px, $bolt-iphone8-viewer-height) * 100%);
    border-bottom-right-radius: (math.div(48px, $bolt-iphone8-viewer-width) * 100%)
      (math.div(48px, $bolt-iphone8-viewer-height) * 100%);
    border-bottom-left-radius: (math.div(48px, $bolt-iphone8-viewer-width) * 100%)
      (math.div(48px, $bolt-iphone8-viewer-height) * 100%);

    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 6px 3px #fff;

    .c-bolt-iphone8-viewer--landscape & {
      top: math.div(8px, $bolt-iphone8-viewer-width) * 100%;
      right: math.div(8px, $bolt-iphone8-viewer-height) * 100%;
      bottom: math.div(8px, $bolt-iphone8-viewer-width) * 100%;
      left: math.div(8px, $bolt-iphone8-viewer-height) * 100%;
      border-top-right-radius: (math.div(48px, $bolt-iphone8-viewer-height) * 100%)
        (math.div(48px, $bolt-iphone8-viewer-width) * 100%);
      border-top-left-radius: (math.div(48px, $bolt-iphone8-viewer-height) * 100%)
        (math.div(48px, $bolt-iphone8-viewer-width) * 100%);
      border-bottom-right-radius: (math.div(48px, $bolt-iphone8-viewer-height) * 100%)
        (math.div(48px, $bolt-iphone8-viewer-width) * 100%);
      border-bottom-left-radius: (math.div(48px, $bolt-iphone8-viewer-height) * 100%)
        (math.div(48px, $bolt-iphone8-viewer-width) * 100%);
    }

    .c-bolt-iphone8-viewer--black & {
      box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 6px 3px #212121;
    }
  }
}

.c-bolt-iphone8-viewer__screen {
  padding-bottom: 177.78636649%;
  box-shadow: 0 0 0 3px #111;
  background-color: var(--bolt-color-black);
  background-image: linear-gradient(to bottom, #b3b3b7 0%, #424044 100%);

  .c-bolt-iphone8-viewer--landscape & {
    padding-bottom: 56.24728261%;
  }
}

.c-bolt-iphone8-viewer__top-bar,
.c-bolt-iphone8-viewer__bottom-bar {
  right: 0;
  z-index: core.bolt-z-index('background') - 5;
  height: 3px;
  background-color: black;

  .c-bolt-iphone8-viewer--gold & {
    background-color: white;
  }

  .c-bolt-iphone8-viewer--black & {
    background-color: #212121;
  }
}

// .c-bolt-iphone8-viewer__middle-bar {
//   width: 3px;
//   height: 4px;
//   top: 0px;
//   left: 90px;
//   background-color: black;
// }

.c-bolt-iphone8-viewer__home {
  bottom: math.div(22px, $bolt-iphone8-viewer-height) * 100%;
  left: 50%;
  transform: translateX(-50%); // Center align without knowing size of element

  width: math.div(68px, $bolt-iphone8-viewer-width) * 100%;
  height: math.div(68px, $bolt-iphone8-viewer-height) * 100%;

  background-color: rgb(48, 50, 51);
  background-image: linear-gradient(
    135deg,
    rgba(48, 50, 51, 1) 0%,
    rgba(181, 183, 185, 1) 50%,
    rgba(240, 242, 242, 1) 69%,
    rgba(48, 50, 51, 1) 100%
  );

  .c-bolt-iphone8-viewer--landscape & {
    top: 50%;
    bottom: auto;
    left: math.div(22px, $bolt-iphone8-viewer-height) * 100%;
    transform: translateY(-50%);
    width: math.div(68px, $bolt-iphone8-viewer-height) * 100%;
    height: math.div(68px, $bolt-iphone8-viewer-width) * 100%;
  }

  &:before {
    content: '';
    position: absolute;

    top: math.div(4px, 68px) * 100%;
    right: math.div(4px, 68px) * 100%;
    bottom: math.div(4px, 68px) * 100%;
    left: math.div(4px, 68px) * 100%;

    width: auto;
    height: auto;
    border-radius: 100%;
    background-color: #f8f8f8;

    .c-bolt-iphone8-viewer--black & {
      background-color: #080808;
    }
  }

  .c-bolt-iphone8-viewer--gold & {
    background-color: rgb(206, 187, 169);
    background-image: linear-gradient(
      135deg,
      rgba(206, 187, 169, 1) 0%,
      rgba(249, 231, 211, 1) 50%,
      rgba(206, 187, 169, 1) 100%
    );
  }

  .c-bolt-iphone8-viewer--black & {
    background-color: rgb(8, 8, 8);
    background-image: linear-gradient(
      135deg,
      rgba(8, 8, 8, 1) 0%,
      rgba(70, 70, 70, 1) 50%,
      rgba(8, 8, 8, 1) 100%
    );
  }
}

.c-bolt-iphone8-viewer__top-bar {
  position: absolute;
  top: 68px;
  left: 0;
  height: 14px;
  background-color: #bfbfc0;

  .c-bolt-iphone8-viewer--landscape & {
    top: 0;
    left: calc(100% - 68px - 14px);
    width: 14px;
    height: 100%;
  }
}

.c-bolt-iphone8-viewer__bottom-bar {
  position: absolute;
  bottom: 68px;
  left: 0;
  height: 14px;
  background-color: #bfbfc0;

  .c-bolt-iphone8-viewer--landscape & {
    top: 0;
    left: 68px;
    width: 14px;
    height: 100%;
  }
}

.c-bolt-iphone8-viewer__sleep {
  position: absolute;
  top: 28%;
  right: -1.25%;
  width: 1.25%;
  height: 10%;
  border-radius: 0 2px 2px 0;
  background-color: #d9dbdc;

  .c-bolt-iphone8-viewer--landscape & {
    top: 100%;
    right: 28%;
    width: 10%;
    height: 1.25%;
    border-radius: 0 0 2px 2px;
  }

  .c-bolt-iphone8-viewer--gold & {
    background-color: #f9e7d3;
  }

  .c-bolt-iphone8-viewer--black & {
    background-color: #464646;
  }
}

.c-bolt-iphone8-viewer__volume {
  position: absolute;
  top: 28%;
  left: -1.25%;
  z-index: bolt-z-index('background');
  width: 1.25%;
  height: 10%;
  border-radius: 2px 0 0 2px;
  background-color: #d9dbdc;

  .c-bolt-iphone8-viewer--landscape & {
    top: -1.25%;
    left: calc(100% - 28% - 10%);
    width: 10%;
    height: 1.25%;
    border-radius: 2px 2px 0 0;
  }

  .c-bolt-iphone8-viewer--gold & {
    background-color: #f9e7d3;
  }

  .c-bolt-iphone8-viewer--black & {
    background-color: #464646;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -120%;
    right: 0;
    width: 50%;
    height: 60%;
    border-radius: 2px 0 0 2px;
    background: inherit;

    .c-bolt-iphone8-viewer--landscape & {
      top: 50%;
      right: -120%;
      left: auto;
      width: 60%;
      height: 50%;
      border-radius: 2px 2px 0 0;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 120%;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px 0 0 2px;
    background: inherit;

    .c-bolt-iphone8-viewer--landscape & {
      top: 0;
      left: -120%;
      width: 100%;
      height: 100%;
      border-radius: 2px 2px 0 0;
    }
  }
}

.c-bolt-iphone8-viewer__camera {
  top: math.div(24px, $bolt-iphone8-viewer-height) * 100%;
  left: 50%;

  transform: translateX(-50%); // Center align without knowing size of element
  width: math.div(12px, $bolt-iphone8-viewer-width) * 100%;
  height: math.div(12px, $bolt-iphone8-viewer-height) * 100%;
  background-color: #3c3d3d;

  .c-bolt-iphone8-viewer--black & {
    background-color: #080808;
  }

  .c-bolt-iphone8-viewer--landscape & {
    top: 50%;
    right: math.div(32px, $bolt-iphone8-viewer-height) * 100%;
    left: auto;
    transform: translateY(-50%); // Center align without knowing size of element
    // left: calc(100% - 32px);
    // margin-left: 0px;

    width: math.div(12px, $bolt-iphone8-viewer-height) * 100%;
    height: math.div(12px, $bolt-iphone8-viewer-width) * 100%;
  }
}

.c-bolt-iphone8-viewer__sensor {
  position: absolute;
  top: math.div(49px, $bolt-iphone8-viewer-height) * 100%;
  left: math.div(134px, $bolt-iphone8-viewer-width) * 100%;

  z-index: bolt-z-index('backgroundTop');

  width: math.div(16px, $bolt-iphone8-viewer-width) * 100%;
  height: math.div(16px, $bolt-iphone8-viewer-height) * 100%;
  border-radius: 100%;
  background-color: #3c3d3d;

  .c-bolt-iphone8-viewer--landscape & {
    top: math.div(134px, $bolt-iphone8-viewer-width) * 100%;
    right: math.div(49px, $bolt-iphone8-viewer-height) * 100%;
    left: auto;

    width: math.div(16px, $bolt-iphone8-viewer-height) * 100%;
    height: math.div(16px, $bolt-iphone8-viewer-width) * 100%;
  }
}

.c-bolt-iphone8-viewer__speaker {
  position: absolute;
  top: math.div(54px, $bolt-iphone8-viewer-height) * 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: bolt-z-index('backgroundTop');
  width: math.div(70px, $bolt-iphone8-viewer-width) * 100%;
  height: math.div(6px, $bolt-iphone8-viewer-height) * 100%;
  border-radius: 6px;
  background-color: #292728;

  .c-bolt-iphone8-viewer--landscape & {
    top: 50%;
    right: math.div(math.div(54px, $bolt-iphone8-viewer-width) * 100%, 2);
    left: auto;
    transform: translateY(-50%);
    width: math.div(6px, $bolt-iphone8-viewer-height) * 100%;
    height: math.div(70px, $bolt-iphone8-viewer-width) * 100%;
    margin-left: 0px;
  }
}
