// Local Variables
$bolt-ol-bullet-size: calc(var(--bolt-type-line-height-xsmall) * 1rem);
$bolt-ol-bullet-bg-color: var(--m-bolt-tertiary);
$bolt-li-spacing: calc(#{$bolt-ol-bullet-size} + var(--bolt-spacing-x-xsmall));
$bolt-li-bullet-level-1: '\25CF'; // black-circle
$bolt-li-bullet-level-2: '\26AC'; // white-circle
$bolt-li-bullet-level-3: '\25A0'; // black-square

bolt-li {
  display: block; // Fixes FF bug where OL numbers are positioned above circle
}

.c-bolt-li {
  box-sizing: border-box;
  position: relative;
  margin-bottom: var(--bolt-spacing-y-xxsmall);
  margin-inline-start: $bolt-li-spacing;
  padding: 0;

  &--last-item {
    margin-bottom: 0;
  }
}

// unordered-list specific list-item styles
.c-bolt-li--ul-item {
  &:before {
    content: $bolt-li-bullet-level-1;
    display: block;
    box-sizing: border-box;
    position: absolute;
    top: 1px;
    inset-inline-start: calc(#{$bolt-li-spacing} * -1);
    transform: scale(0.8);
    width: $bolt-ol-bullet-size;
    height: $bolt-ol-bullet-size;
    font-family: var(--bolt-type-font-family-code);
    font-size: var(--bolt-type-font-size-xsmall);
    font-weight: var(--bolt-type-font-weight-regular);
    line-height: $bolt-ol-bullet-size;
    text-align: center;
  }

  &.c-bolt-li--level {
    &-2 {
      &:before {
        content: $bolt-li-bullet-level-2;
      }
    }

    &-3 {
      &:before {
        content: $bolt-li-bullet-level-3;
      }
    }
  }
}

.c-bolt-ul {
  .c-bolt-ul {
    .c-bolt-li--ul-item {
      &:before {
        content: $bolt-li-bullet-level-2;
      }
    }

    .c-bolt-ul {
      .c-bolt-li--ul-item {
        &:before {
          content: $bolt-li-bullet-level-3;
        }
      }
    }
  }
}

bolt-ol,
.c-bolt-ol {
  > bolt-li {
    position: relative;

    &:before {
      content: counter(li);
      position: absolute;
      top: 1px;
      inset-inline-start: 0;
      width: $bolt-ol-bullet-size;
      height: $bolt-ol-bullet-size;
      font-family: var(--bolt-type-font-family-headline);
      font-size: var(--bolt-type-font-size-xsmall);
      font-weight: var(--bolt-type-font-weight-bold);
      counter-increment: li;
      line-height: $bolt-ol-bullet-size;
      text-align: center;
    }
  }
}

// ordered-list specific list-item styles
.c-bolt-li--ol-item:before {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  inset-inline-start: calc(#{$bolt-li-spacing} * -1);
  width: $bolt-ol-bullet-size;
  height: $bolt-ol-bullet-size;
  color: var(--m-bolt-headline);
  line-height: $bolt-ol-bullet-size;
  border-radius: $bolt-ol-bullet-size;
  background-color: $bolt-ol-bullet-bg-color;
}
