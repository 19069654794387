@import '@bolt/core';

/* ------------------------------------ *\
   Grid
\* ------------------------------------ */

bolt-grid {
  --c-bolt-grid-column-gap: var(--bolt-spacing-x-medium);
  --c-bolt-grid-row-gap: var(--bolt-spacing-y-medium);

  display: grid;
  grid-template-columns: repeat(12, 1fr); // 12-column grid is Bolt's default.
  grid-auto-rows: minmax(min-content, max-content);
  row-gap: var(--c-bolt-grid-row-gap);
  column-gap: var(--c-bolt-grid-column-gap);

  @each $size in $bolt-spacing-multiplier-system {
    $size-name: nth($size, 1);

    @if (
      $size-name !=
        'xxsmall' and
        $size-name !=
        'xsmall' and
        $size-name !=
        'xlarge' and
        $size-name !=
        'xxlarge'
    ) {
      &[gutter='#{$size-name}'] {
        --c-bolt-grid-column-gap: clamp(
          var(--bolt-spacing-x-small),
          2.5vw,
          var(--bolt-spacing-x-#{$size-name})
        );
      }

      &[row-gutter='#{$size-name}'] {
        --c-bolt-grid-row-gap: clamp(
          var(--bolt-spacing-y-small),
          2.5vw,
          var(--bolt-spacing-y-#{$size-name})
        );
      }
    }
  }

  &[gutter='none'] {
    --c-bolt-grid-column-gap: 0;
  }

  &[row-gutter='none'] {
    --c-bolt-grid-row-gap: 0;
  }
}
