/* ------------------------------------ *\
   Page Header (mobile and desktop
   shared style)
\* ------------------------------------ */

@use '@bolt/core' as core;
@use 'page-header-settings-and-tools' as page-header-settings-and-tools;

.c-bolt-page-header {
  @include core.bolt-shadow('level-10');
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: core.bolt-z-index(navFixed);
  color: var(--m-bolt-text);
  background-color: var(--m-bolt-bg);

  *::-webkit-scrollbar {
    width: 11px;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--bolt-color-gray) var(--bolt-color-gray-xlight);
  }

  *::-webkit-scrollbar-track {
    background: var(--bolt-color-gray-xlight);
  }

  *::-webkit-scrollbar-thumb {
    border: 3px solid var(--bolt-color-gray-xlight);
    border-radius: 6px;
    background-color: var(--bolt-color-gray);
  }

  @media (prefers-reduced-motion) {
    &,
    *,
    *:before,
    *:after {
      transition: none !important;
    }
  }
}

.c-bolt-page-header--static {
  position: relative;
}

.c-bolt-page-header__primary,
.c-bolt-page-header__secondary {
  @include core.bolt-full-bleed;

  &.is-hidden-on-scroll {
    position: absolute;
    transform: translate3d(0, -100%, 0);
  }
}

// Primary section container
.c-bolt-page-header__primary {
  display: grid;
  align-items: center;
  position: relative;
  z-index: 2; // Primary nav (with dropdowns) has a higher stacking order than secondary.
  transition: transform var(--bolt-transition);
}

// Secondary section container
.c-bolt-page-header__secondary {
  position: relative;
  z-index: 1;
}

// Logo
.c-bolt-page-header__logo {
  @include page-header-settings-and-tools.bolt-page-header-action-trigger;

  & {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: page-header-settings-and-tools.$bolt-page-header-click-target-size;
  }
}

.c-bolt-page-header__logo__img {
  display: block;
  height: 30px;
  max-height: 100%;

  img {
    width: var(--c-bolt-page-header-logo-max-width);
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left center;
    filter: var(--m-bolt-img-filter);
  }
}

// Toolbar
.c-bolt-page-header__toolbar {
  display: flex;
  align-items: center;
}

.c-bolt-page-header__action-trigger {
  @include page-header-settings-and-tools.bolt-page-header-action-trigger;

  & {
    height: page-header-settings-and-tools.$bolt-page-header-click-target-size;
  }

  &:active {
    &:before {
      transform: scale(0.85);
    }
  }

  &[aria-expanded='true'] {
    &:before {
      opacity: 0.1;
    }
  }
}

.c-bolt-page-header__action-trigger__text {
  @include core.bolt-visuallyhidden;
}

.c-bolt-page-header__action-trigger__icon {
  display: grid;
  transform: scale(1) rotate(0deg);
  font-size: var(--bolt-type-font-size-large);
  line-height: 1;
  transition: transform ease-out 500ms;
  place-items: center;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 0;
  }
}

.c-bolt-page-header__action-trigger__icon--close {
  transform: scale(0) rotate(360deg);
}

.c-bolt-page-header__action-trigger[aria-expanded='true'] {
  .c-bolt-page-header__action-trigger__icon:not(.c-bolt-page-header__action-trigger__icon--close) {
    transform: scale(0) rotate(360deg);
  }

  .c-bolt-page-header__action-trigger__icon--close {
    transform: scale(1) rotate(0deg);
  }
}

// Search panel
.c-bolt-page-header__search {
  display: block;
  visibility: hidden;
  position: fixed;
  top: var(--bolt-page-header-height);
  right: 0;
  left: 0;
  padding-top: var(--bolt-spacing-y-medium);
  padding-bottom: var(--bolt-spacing-y-medium);
  border-top-color: var(--m-bolt-border);
  border-top-style: core.$bolt-border-style;
  border-top-width: core.$bolt-border-width;
  background-color: var(--m-bolt-bg);

  &:after {
    content: '';
    opacity: 0.8;
    position: fixed;
    top: 100%;
    right: 0;
    left: 0;
    height: 100vh;
    background-color: var(--m-bolt-headline);
  }

  @at-root .c-bolt-page-header__action-trigger--search[aria-expanded='true']
      ~ #{&} {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
}

// Nav link
.c-bolt-page-header__nav-link {
  text-decoration: none;
}

.c-bolt-page-header__nav-link__content {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  @include core.bolt-mq(xlarge) {
    > bolt-icon {
      transform: translateY(
        0.5px
      ); // Temporary fix for icon + text bad vertical alignment above xlarge breakpoint.
    }
  }
}

.c-bolt-page-header__nav-link__content__signifier {
  display: grid;
  line-height: 1;
  place-items: center;
}

.c-bolt-page-header__nav-link__content__signifier {
  img {
    width: 1.2em;
    height: 1.2em;
    border-radius: core.bolt-border-radius(full);
  }
}

// Subheadline
.c-bolt-page-header__subheadline {
  font-size: var(--bolt-type-font-size-tiny);
  font-weight: var(--bolt-type-font-weight-semibold);
  line-height: calc(
    var(--bolt-type-line-height-tiny) *
      var(--bolt-type-line-height-multiplier-tight)
  );
  white-space: nowrap;
}

// Main CTA
.c-bolt-page-header__cta {
  @include core.bolt-button-native-styles-reset;

  display: grid;
  white-space: nowrap;
}

// User flag
.c-bolt-page-header__user-flag {
  display: grid;
}

.c-bolt-page-header__user-flag__img {
  overflow: hidden;
  border-radius: core.bolt-border-radius(full);
}

.c-bolt-page-header__user-flag__content {
  word-break: break-word;
  hyphens: auto;
}

// Related Sites Grid View Lists
.c-bolt-page-header__related-sites-list {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;

  .c-bolt-page-header__related-sites-list {
    margin-inline-start: var(--bolt-spacing-x-medium);
  }
}

.c-bolt-page-header__related-sites-list-item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'signifier link'
    'signifier description'
    'child child';
  margin: 0;

  > .c-bolt-page-header__related-sites-list-item__signifier {
    grid-area: signifier;
  }

  > .c-bolt-page-header__related-sites-list-item__link {
    grid-area: link;
  }

  > .c-bolt-page-header__related-sites-list-item__description {
    grid-area: description;
  }

  > .c-bolt-page-header__related-sites-list {
    grid-area: child;
  }
}

.c-bolt-page-header__related-sites-list-item__signifier {
  width: 2.75em;
  margin-inline-end: var(--bolt-spacing-x-small);

  img {
    width: 100%;
    height: auto;
    border-radius: core.bolt-border-radius(full);
    object-fit: cover;
  }
}

.c-bolt-page-header__related-sites-list-item__link,
.c-bolt-page-header__related-sites-list-item__description {
  display: block;
}

.c-bolt-page-header__related-sites-list-item__link {
  font-family: var(--bolt-type-font-family-headline);
  font-weight: var(--bolt-type-font-weight-semibold);
}

.c-bolt-page-header__related-sites-list-item__description {
  margin-bottom: var(--bolt-spacing-y-small);
  font-family: var(--bolt-type-font-family-body);
  font-weight: var(--bolt-type-font-weight-regular);
}

// Counter
.c-bolt-page-header__counter {
  padding: 2px;
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--bolt-color-white);
  line-height: 1;
  text-decoration: none;
  border: 2px solid var(--m-bolt-bg);
  border-radius: core.bolt-border-radius(full);
  background-color: var(--bolt-color-error);

  &:empty {
    width: 0.9em;
    height: 0.9em;
    padding: 0;
  }
}
