/* ------------------------------------ *\
   Chip
\* ------------------------------------ */

@import '@bolt/core';

// Register Custom Block Element
@include bolt-custom-element('bolt-chip', block, medium);

/**
 * Long-form comment.
 *
 * This spans multiple lines and is also constrained to no longer than 80
 * characters in width.
 *
 * 1. Provide line-comments like this.
 */
.c-bolt-chip {
  --c-bolt-chip-border-radius: #{bolt-border-radius(full)};
  --c-bolt-chip-icon-only-size: 1.1em;
  @include bolt-button-native-styles-reset;

  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  gap: var(--bolt-spacing-x-xxsmall);
  padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-small);
  font-family: var(--bolt-type-font-family-body);
  font-size: var(--bolt-type-font-size-xsmall);
  font-weight: var(--bolt-type-font-weight-regular);
  color: var(--m-bolt-text);
  line-height: calc(
    var(--bolt-type-line-height-xsmall) *
      var(--bolt-type-line-height-multiplier-tight)
  );
  text-decoration: none;
  cursor: auto;
  border-radius: var(--c-bolt-chip-border-radius);
  transition: color var(--bolt-transition),
    background-color var(--bolt-transition),
    text-decoration var(--bolt-transition);

  // use the psuedo-element for background color for the purposes of hover and focus styles
  &:before {
    content: '';
    opacity: 0.16;
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: var(--bolt-color-gray);
  }
}

// move the background color of themes to the psuedo-element
.c-bolt-chip[class^='t-bolt-'] {
  background-color: transparent;

  &:before {
    opacity: 1;
    background-color: var(--m-bolt-bg);
  }
}

// link specific styles
a.c-bolt-chip {
  color: var(--m-bolt-link);
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--m-bolt-headline);
    text-decoration: underline;

    &:before {
      opacity: 0.08;
    }
  }

  &:active,
  &:focus:active {
    color: var(--m-bolt-headline);
    text-decoration: none;
  }

  &[class^='t-bolt-'] {
    &:hover,
    &:focus {
      &:before {
        opacity: 0.8;
      }
    }
  }
}

// button specific styles
button.c-bolt-chip {
  cursor: pointer;

  &:hover,
  &:focus {
    &:before {
      opacity: 0.08;
    }
  }

  &[class^='t-bolt-'] {
    &:hover,
    &:focus {
      &:before {
        opacity: 0.8;
      }
    }
  }
}

// icon styles
.c-bolt-chip--icon-only {
  width: calc(
    var(--bolt-spacing-y-small) +
      calc(
        var(--bolt-type-line-height-xsmall) *
          var(--bolt-type-line-height-multiplier-tight)
      ) * 1em
  ); /* [4] */
  height: calc(
    var(--bolt-spacing-y-small) +
      calc(
        var(--bolt-type-line-height-xsmall) *
          var(--bolt-type-line-height-multiplier-tight)
      ) * 1em
  );
  padding: 0;
}

[class*='c-bolt-chip__icon'] {
  @include bolt-inline-icon-wrapper; /* [1] */
}

.c-bolt-chip__icon-before {
  margin-right: calc(
    var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xxsmall) * 1em
  ); /* [2] */

  > bolt-icon[size] {
    margin-right: var(--bolt-spacing-x-xxsmall);
  }

  > bolt-icon[name*='chevron-'] {
    margin-left: calc(
      var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xxsmall) * -2em
    ); /* [3] */
  }
}

.c-bolt-chip__icon-after {
  margin-left: calc(
    var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xxsmall) * 1em
  ); /* [2] */

  > bolt-icon[size] {
    margin-left: var(--bolt-spacing-x-xxsmall);
  }

  > bolt-icon[name*='chevron-'] {
    margin-right: calc(
      var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xxsmall) * -2em
    ); /* [3] */
  }
}

.c-bolt-chip__icon-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 1em;
  height: 1em;
  font-size: var(--c-bolt-chip-icon-only-size);

  > * {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate3d(-50%, -50%, 0) !important;
  }
}

// sizes
$_bolt-chip-sizes: (xsmall, medium);
// can ignore small, since it is the default, others pulled from schema
@each $size in $_bolt-chip-sizes {
  .c-bolt-chip--size-#{$size} {
    padding: calc(var(--bolt-spacing-y-#{$size}) / 2)
      var(--bolt-spacing-x-#{$size});
    @if $size == medium {
      --c-bolt-chip-icon-only-size: 1.4em;
    }

    &.c-bolt-chip--icon-only {
      width: calc(
        var(--bolt-spacing-y-#{$size}) +
          calc(
            var(--bolt-type-line-height-xsmall) *
              var(--bolt-type-line-height-multiplier-tight)
          ) *
          1em
      ); /* [4] */
      height: calc(
        var(--bolt-spacing-y-#{$size}) +
          calc(
            var(--bolt-type-line-height-xsmall) *
              var(--bolt-type-line-height-multiplier-tight)
          ) *
          1em
      );
      padding: 0;
    }
  }
}

// border-radius
@each $radius-name, $radius-value in $bolt-border-radius-values {
  .c-bolt-chip--border-radius-#{$radius-name} {
    --c-bolt-chip-border-radius: #{$radius-value};
  }
}
