@import '@bolt/core';

/* ------------------------------------ *\
   Stack
\* ------------------------------------ */

// Register Custom Block Element
@include bolt-custom-element('bolt-stack', block, medium);

// Spcaing options
@each $size in $bolt-spacing-multiplier-system {
  $size-name: nth($size, 1);
  @if ($size-name != 'xxsmall' and $size-name != 'xxlarge') {
    bolt-stack:not(:last-child)[spacing='#{$size-name}'] {
      margin-bottom: var(--bolt-spacing-y-#{$size-name});
    }
  }
}

bolt-stack[spacing='none'] {
  margin-bottom: 0;
}
