.c-bds-docs__text-decoration-demo {
  @extend %bolt-demo-wrapper;
  margin-bottom: 2rem;
}

.c-bds-docs__text-decoration-item {
  @extend %bolt-demo-item-border;

  h4 + & {
    margin-top: 0.75rem;
  }
}
