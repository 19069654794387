@import '@bolt/core';

// Dev Notes
// 1. https://stackoverflow.com/questions/57455245/how-to-i-solve-this-sass-interpolation-issue

.e-bolt-icon {
  --e-bolt-icon-size: 1em;
  --e-bolt-icon-color: inherit;
  display: inline-block;
  width: var(--e-bolt-icon-size);
  height: var(--e-bolt-icon-size);
  color: var(--e-bolt-icon-color);
  vertical-align: middle;

  > * {
    fill: currentColor;
  }
}

.e-bolt-icon--small {
  --e-bolt-icon-size: 1rem;
}

.e-bolt-icon--medium {
  --e-bolt-icon-size: 1.5rem;
}

.e-bolt-icon--large {
  --e-bolt-icon-size: 2rem;
}

.e-bolt-icon--xlarge {
  --e-bolt-icon-size: 2.375rem;
}

$_bolt-icon-color: teal, navy, yellow, orange, pink, berry, wine, violet;

@each $color in $_bolt-icon-color {
  // [1]
  .e-bolt-icon--#{'' + $color} {
    @if $color == yellow {
      --e-bolt-icon-color: var(--bolt-color-#{$color}-dark);
    } @else {
      --e-bolt-icon-color: var(--bolt-color-#{$color});
    }
  }
}
