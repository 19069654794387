/* ------------------------------------ *\
   Base animations tool set
\* ------------------------------------ */

/**
 * Dev notes:
 * 1. Safari performance issue (elements flickers in random places after animation starts).
 * 2. This is default animation duration which corresponds to 'short' (200ms).
 */

.a-bolt-base {
  visibility: hidden; /* [1] */
  animation-delay: var(--bolt-animation-delay);
  animation-duration: var(--bolt-animation-duration); /* [2] */
  animation-fill-mode: both;
  animation-play-state: paused;

  @media print, (prefers-reduced-motion: reduce) {
    animation: none !important;
  }
}

.a-bolt-in {
  animation-timing-function: ease-out;
}

.a-bolt-out {
  animation-timing-function: ease-in;
  @media print, (prefers-reduced-motion: reduce) {
    opacity: 0;
  }
}

.a-bolt-running {
  visibility: visible; /* [1] */
  animation-play-state: running;
}

.a-bolt-duration-long {
  animation-duration: calc(var(--bolt-animation-duration) * 2.5);
}

.a-bolt-delay-long {
  --bolt-animation-delay: 500ms;
  animation-delay: var(--bolt-animation-delay);
}

.a-bolt-delay-short {
  --bolt-animation-delay: 200ms;
  animation-delay: var(--bolt-animation-delay);
}
