$bolt-namespace: bolt;
/* ------------------------------------ *\
   Background
\* ------------------------------------ */

@import '@bolt/core';

$bolt-bg-dark-overlay-opacity-light: 0.3;
$bolt-bg-dark-overlay-opacity-medium: 0.55;
$bolt-bg-dark-overlay-opacity-heavy: 0.8;
$bolt-bg-shape-offset-bottom-default: -100px;
$bolt-bg-shape-offset-side-default: $bolt-bg-shape-offset-bottom-default * 1.2;
$bolt-bg-shape-offset-bottom-small: -150px;
$bolt-bg-shape-offset-side-small: $bolt-bg-shape-offset-bottom-small * 1.2;
$bolt-bg-shape-offset-bottom-large: -300px;
$bolt-bg-shape-offset-side-large: $bolt-bg-shape-offset-bottom-large * 1.2;

// Zoom animation treatment
@keyframes a-bolt-zoom-in-out {
  0% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1.05);
  }
}

bolt-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
  user-select: none;
}

.c-bolt-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.c-bolt-background__item {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  > * {
    flex-grow: 1;
  }
}

.c-bolt-background__item--blur {
  transform: scale(1.05);
  filter: blur(0.5rem);
}

.c-bolt-background__item--grayscale {
  filter: grayscale(100%);
}

.c-bolt-background__item--zoom-in-out {
  animation: a-bolt-zoom-in-out 45s ease-in-out infinite both;
}

.c-bolt-background__image {
  top: -9999px;
  right: -9999px;
  bottom: -9999px;
  left: -9999px;

  .is-expanded & {
    opacity: bolt-opacity(100);
    filter: blur(10px);
  }
}

.c-bolt-background__image {
  position: absolute;
  min-width: 100%;
  max-width: none; // Required to handle instances where a max-width is previously set.
  min-height: 100%;
  margin: auto;

  @supports (object-fit: cover) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-bolt-background__item--video {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 180%;
    @include bolt-mq($until: medium) {
      width: unset;
      height: 120vh;
    }
  }
}

.c-bolt-background__item--video img {
  display: block;
}

.c-bolt-background__item--video img + * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 180%;
}

@media screen and (max-aspect-ratio: 3/4) {
  .c-bolt-background__item--video img + * {
    display: none;
  }
}

@media screen and (max-width: 49.99em) {
  .c-bolt-background__item--video img + * {
    display: none;
  }

  .c-bolt-background__video-toggle-button {
    display: none;
  }
}

.c-bolt-background__video-toggle-button {
  position: absolute;
  right: 20px;
  bottom: -20px;
  z-index: 1;
  pointer-events: auto;

  svg {
    width: 1.5em;
    height: 1.5em;
  }
}

.c-bolt-background__overlay {
  content: '';
  display: block;
  position: absolute;

  // This overlay has a history of rounding errors (which can leave a stripe on one side of the background that's not
  // covered by the overlay).  The following is a hack that all but guarantees that can't happen.
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
}

.c-bolt-background__overlay--medium-opacity {
  opacity: $bolt-bg-dark-overlay-opacity-medium; // TODO: This number is only working for dark colors, it does not work for light colors. Therefore light and xlight themes with a background image would not be accessible yet.
}

.c-bolt-background__overlay--heavy-opacity {
  opacity: $bolt-bg-dark-overlay-opacity-heavy; // TODO: This number is only working for dark colors, it does not work for light colors. Therefore light and xlight themes with a background image would not be accessible yet.
}

.c-bolt-background__overlay--light-opacity {
  opacity: $bolt-bg-dark-overlay-opacity-light; // TODO: This number is only working for dark colors, it does not work for light colors. Therefore light and xlight themes with a background image would not be accessible yet.
}

.c-bolt-background__overlay--solid-fill {
  background-color: var(--m-bolt-bg);
}

.c-bolt-background__shapes {
  position: absolute;
  right: $bolt-bg-shape-offset-side-default;
  bottom: $bolt-bg-shape-offset-bottom-default;
  transform: rotate(-45deg);

  @include bolt-mq(small) {
    right: $bolt-bg-shape-offset-side-small;
    bottom: $bolt-bg-shape-offset-bottom-small;
  }

  @include bolt-mq(large) {
    right: $bolt-bg-shape-offset-side-large;
    bottom: $bolt-bg-shape-offset-bottom-large;
  }
}

.c-bolt-background__shapes--left {
  right: auto;
  left: $bolt-bg-shape-offset-side-default;
  transform: rotate(45deg);

  @include bolt-mq(small) {
    left: $bolt-bg-shape-offset-side-small;
  }

  @include bolt-mq(large) {
    left: $bolt-bg-shape-offset-side-large;
  }
}

// @todo: move into Academy-specific styles
.c-bolt-background-crop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: calc(var(--bolt-spacing-y-xxxlarge) * -100%);
  pointer-events: none;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: drop-shadow(0 12px 55px #50e3c2)
    drop-shadow(-13px -22px 58px rgba(0, 0, 0, 0.36));

  @include bolt-mq(medium) {
    justify-content: flex-end;
  }

  svg {
    min-width: 3000px;

    @include bolt-mq($from: 1100px) {
      min-width: 0;
    }
  }
}

.has-transparent-background {
  background-color: transparent !important;
}

:root,
.c-bolt-background__overlay--bottom-right-focus {
  --c-bolt-background-gradient-angle: -35deg;
}

.c-bolt-background__overlay--bottom-center-focus {
  --c-bolt-background-gradient-angle: 0deg;
}

.c-bolt-background__overlay--bottom-left-focus {
  --c-bolt-background-gradient-angle: 35deg;
}

.c-bolt-background__overlay--center-right-focus {
  --c-bolt-background-gradient-angle: -90deg;
}

.c-bolt-background__overlay--center-center-focus {
  --c-bolt-background-gradient-angle: 0deg;
}

.c-bolt-background__overlay--center-left-focus {
  --c-bolt-background-gradient-angle: 90deg;
}

.c-bolt-background__overlay--top-right-focus {
  --c-bolt-background-gradient-angle: -125deg;
}

.c-bolt-background__overlay--top-center-focus {
  --c-bolt-background-gradient-angle: 180deg;
}

.c-bolt-background__overlay--top-left-focus {
  --c-bolt-background-gradient-angle: 125deg;
}

.c-bolt-background__overlay--gradient-fill {
  background: linear-gradient(
    var(--c-bolt-background-gradient-angle),
    rgba(130, 130, 130, 0.5),
    var(--m-bolt-bg) 50%
  );
}

/* DEPRECATED. Linear gradient is deprecated. Commented for future reference */

// .c-bolt-background__overlay--linear-gradient-fill {
//   &.c-bolt-background__overlay-fill-color--navy {
//     background: linear-gradient(
//       350.07deg,
//       var(--bolt-color-navy-light) 0%,
//       var(--bolt-color-navy) 6.56%,
//       var(--bolt-color-navy-dark) 100%
//     );
//   }

//   &.c-bolt-background__overlay-fill-color--pink {
//     background: linear-gradient(
//       350.07deg,
//       var(--bolt-color-pink) 0%,
//       var(--bolt-color-berry) 6.56%,
//       var(--bolt-color-wine) 100%
//     );
//   }

//   &.c-bolt-background__overlay-fill-color--black {
//     background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0.02%, #000000 100%);
//   }
// }
