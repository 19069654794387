:root {
  --page-padding-x: calc((100vw - min(80vw, 86ch)) / 2);
}

.c-drupal-admin-ui {
  padding: 2rem var(--page-padding-x);

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.17rem;
  }

  h4 {
    font-size: 1rem;
  }

  form {
    margin-bottom: 1.35rem;
  }

  form ul {
    margin-left: 0;
    list-style: none;
  }

  form li {
    margin-bottom: 0.33rem;
  }

  &__grid {
    display: grid;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.25rem;
    grid-template-columns: repeat(auto-fit, minmax(20ch, 1fr));
  }

  fieldset {
    padding: 1.65rem 2rem;
    border: 1px solid rgba(105, 105, 105, 0.2);
    border-radius: 0.5rem;
  }

  fieldset > ul > li > details {
    padding-bottom: 0.5rem;

    &[open] {
      padding-bottom: 1rem;
    }
  }

  legend {
    display: table;
    box-sizing: border-box;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0;
    font-size: 0.67rem;
    font-weight: 700;
    color: inherit;
    white-space: normal;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  small {
    display: inline-block;
    line-height: 1.35;
  }

  [indented] {
    padding-left: 1rem;
  }

  input,
  textarea,
  select {
    width: 100%;
  }

  input[type='radio'],
  input[type='checkbox'] {
    width: auto;
  }

  input,
  label {
    &:hover {
      cursor: pointer;
    }
  }

  button {
    @include bolt-mq($until: medium) {
      width: 100%;
      margin-bottom: 0.3rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .enable-animation {
    & ~ .c-drupal-admin-ui__info,
    & ~ .c-drupal-admin-ui__grid {
      display: none;
    }

    &:checked {
      & ~ .c-drupal-admin-ui__info,
      & ~ .c-drupal-admin-ui__grid {
        display: grid;
      }
    }
  }

  blockquote {
    padding-left: 2rem;
    border-left: 4px solid blue;

    > p {
      margin-bottom: 0.4125rem;
      font-size: 1.17rem;
    }
  }

  hr {
    margin-top: 3.3rem;
    margin-bottom: 3.3rem;
  }

  &__info {
    display: block;
    padding: 3px;
    font-size: 0.8rem;
    border-left: 3px solid #0076d1;
    background-color: #d6edff;

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
    min-height: 324px;
    border: 1px solid lightgray;
    background-color: #f4f4f4;
    @include bolt-mq($until: medium) {
      min-height: auto;
    }

    &:before {
      content: 'Select a tab on the side';
      display: grid;
      place-items: center;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: calc(100% - 180px);
      color: gray;
      @include bolt-mq($until: medium) {
        display: none;
      }
    }

    > section {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% - 180px);
      height: 100%;
      overflow-y: auto;
      padding: 0.75rem 1rem;
      background-color: white;

      &:last-of-type {
        & + a {
          border-bottom: 1px solid lightgray;
        }
      }

      @include bolt-mq($until: medium) {
        position: relative;
        width: 100%;
      }

      &:target {
        display: block;

        & + a {
          color: black;
          text-decoration: none;
          border-right-color: white;
          background-color: white;
        }
      }
    }

    > a {
      width: 180px;
      padding: 0.75rem 1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-right: inherit;
      @include bolt-mq($until: medium) {
        order: -1;
        width: 100%;
        border-right: none;
      }

      & ~ a {
        border-top: inherit;
      }
    }
  }

  small {
    display: inline;
  }

  i {
    vertical-align: middle;

    svg {
      width: auto;
      height: 0.9em;
    }
  }

  &__placeholder {
    display: block;
    padding: 0.5rem;
    text-align: center;
    word-break: break-word;
    hyphens: auto;
    border: 1px dashed #0076d1;

    &[inline] {
      display: flex;
      gap: 0.5rem;
    }
  }

  &__action-blocks {
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

    > a {
      padding: 2rem;
      color: white;
      text-align: center;
      text-decoration: none;
      background: #0076d1;
    }

    svg {
      width: 2rem;
      height: 2rem;
      fill: currentColor;
    }
  }

  &__accordion {
    display: block;
    padding: 0.5rem;
    border: 1px solid;

    details + details {
      border-top: inherit;
    }

    details {
      margin: 0;
      padding: 0;

      &[open] {
        padding-bottom: 1rem;
      }
    }

    summary {
      padding: 0.5rem;
    }
  }
}
