////
/// @group Settings: Border
/// @author Mike Mai
////

/* ------------------------------------ *\
   Border Radius Scale
\* ------------------------------------ */

@use 'sass:map';
@use '@bolt/tokens' as tokens;

/// Bolt's definition of border-radius scale.
/// @type Map
$bolt-border-radius-values: map.get(tokens.$tokens, border-radius) !default;
