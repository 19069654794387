@import '@bolt/core';
@import './action-blocks-settings-and-tools';

/* ------------------------------------ *\
   Action Blocks Container
\* ------------------------------------ */

bolt-action-blocks {
  display: block;
  overflow: hidden;
}

.c-bolt-action-blocks {
  display: flex;
  flex-wrap: wrap;
  transform: translate3d(0, 0, 0);
  margin: -1.5px 0 0 -1px; // 1.5px fixes the subpixel issue of the border showing up as the window resizes.
  padding: 0;
  list-style: none;
}

.c-bolt-action-blocks__item {
  display: block;
  flex-grow: 1;
  position: relative;
  min-width: 150px;

  &:before {
    content: '';
    display: block;
    opacity: bolt-opacity(100);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-top-color: var(--m-bolt-border);
    border-top-style: $bolt-action-blocks-border-style;
    border-top-width: $bolt-action-blocks-border-width;
    border-left-color: var(--m-bolt-border);
    border-left-style: $bolt-action-blocks-border-style;
    border-left-width: $bolt-action-blocks-border-width;
    transition: opacity var(--bolt-transition);
  }

  &:hover {
    &:before {
      opacity: bolt-opacity(0);
    }
  }
}

.c-bolt-action-blocks--borderless > .c-bolt-action-blocks__item:before {
  border-top-color: transparent;
  border-left-color: transparent;
}

.c-bolt-action-blocks--valign-start {
  .c-bolt-action-block {
    justify-content: flex-start;
  }
}

.c-bolt-action-blocks--valign-center {
  .c-bolt-action-block {
    justify-content: center;
  }
}

.c-bolt-action-blocks--valign-end {
  .c-bolt-action-block {
    justify-content: flex-end;
  }
}

@for $number from 2 through 12 {
  .c-bolt-action-blocks--item-max-#{$number} > .c-bolt-action-blocks__item {
    flex-basis: calc(100% / #{$number} - #{$bolt-action-blocks-border-width});
  }
}
