/* ------------------------------------ *\
   Search
\* ------------------------------------ */

@use '@bolt/core' as core;

$bolt-search-filter-transition: var(--bolt-transition);

.c-bolt-search-filter {
  display: block;
}

.c-bolt-search-filter__trigger {
  display: block;
  position: fixed;
  inset-inline-end: var(--bolt-spacing-x-medium);
  bottom: var(--bolt-spacing-y-medium);
  z-index: 99998;

  @include core.bolt-mq(medium) {
    display: none;
  }
}

.c-bolt-search-filter__panel {
  @media screen and (max-width: (core.bolt-breakpoint(medium) - 1px)) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background-color: var(--bolt-color-white);

    &,
    > * {
      transition: all $bolt-search-filter-transition;
    }

    &:not(:target) {
      opacity: core.bolt-opacity(0);
      pointer-events: none;

      .c-bolt-search-filter__panel-content,
      .c-bolt-search-filter__panel-controls--close,
      .c-bolt-search-filter__panel-controls--submit {
        transform: translateY(-2rem);
      }
    }

    &:target {
      opacity: core.bolt-opacity(100);

      .c-bolt-search-filter__panel-content,
      .c-bolt-search-filter__panel-controls--close,
      .c-bolt-search-filter__panel-controls--submit {
        transform: translateY(0%);
      }
    }
  }
}

.c-bolt-search-filter__panel-content {
  @media screen and (max-width: (core.bolt-breakpoint(medium) - 1px)) {
    display: block;
    flex: 1;
    padding: var(--bolt-spacing-y-medium) var(--bolt-spacing-x-medium);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: var(--bolt-color-white);
  }
}

.c-bolt-search-filter__panel-controls {
  display: block;
  box-shadow: 0px -2px 4px var(--m-bolt-border);

  @include core.bolt-mq(medium) {
    display: none;
  }
}

.c-bolt-search-filter__panel-controls--close {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  z-index: 99999;
}

.c-bolt-search-filter__panel-controls--submit {
  width: 100%;
  padding: calc(var(--bolt-spacing-y-medium) / 2) var(--bolt-spacing-x-medium);
  background-color: var(--bolt-color-white);
}

.c-bolt-search-inline-tags {
  display: inline;
  margin: 0;
  padding: 0;
}

.c-bolt-search-inline-tag {
  display: inline;
  display: inline-flex;
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--m-bolt-link);
  text-decoration: none;
  transition: all var(--bolt-transition);

  &:after {
    content: ',';
    display: inline-block;
    *display: inline;
    color: var(--m-bolt-text);
    vertical-align: middle;

    // are these two lines still necessary? isn't this an old IE workaround?
    zoom: 1;
  }

  &:hover {
    text-decoration: line-through;
  }
}
