
@use 'sass:color';
@use '@bolt/core' as core;

.c-bolt-swatch {
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  > * {
    width: 100%;
  }
}

.c-bolt-swatch__color {
  display: block;

  &:not(.c-bolt-swatch__color--large) {
    padding: calc(var(--bolt-spacing-y-small) * 2) var(--bolt-spacing-x-small);
  }
}

.c-bolt-swatch__color:nth-last-child(n + 4)
  ~ .c-bolt-swatch__color:last-child:nth-child(even) {
  margin-bottom: auto;
}

.c-bolt-swatch__color--large {
  padding: var(--bolt-spacing-y-large) var(--bolt-spacing-x-small);

  &:first-child {
    margin-top: auto;
  }

  &:last-child {
    margin-bottom: auto;
  }
}

.c-bolt-swatch__text {
  display: flex;
  flex-flow: row wrap;
}

.c-bolt-swatch__meta {
  display: block;
  width: 100%;
  font-size: var(--bolt-type-font-size-xsmall);
  font-weight: var(--bolt-type-font-weight-bold);
  line-height: var(--bolt-type-line-height-xsmall);
  text-transform: uppercase;
}

.c-bolt-swatch__name {
  font-size: var(--bolt-type-font-size-medium);
  font-weight: var(--bolt-type-font-weight-bold);
  line-height: var(--bolt-type-line-height-medium);
}

.c-bolt-swatch__value {
  margin-left: auto;
  font-size: var(--bolt-type-font-size-medium);
  font-weight: var(--bolt-type-font-weight-bold);
  line-height: var(--bolt-type-line-height-medium);
  text-transform: uppercase;
}

.c-bolt-accessibility-table {
  margin-bottom: var(--bolt-spacing-y-small);
  padding: var(--bolt-spacing-y-xsmall) var(--bolt-spacing-x-small);
  border-radius: core.bolt-border-radius(large);
  box-shadow: core.bolt-shadow('level-10');
}

.c-bolt-accessibility-table__table {
  margin-bottom: 0;
  margin-left: 0;
}

.c-bolt-accessibility-table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  white-space: nowrap;

  &:after {
    content: '';
    display: block;
    opacity: 0.3;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    border-bottom-color: currentColor;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  &:last-child:after {
    display: none;
  }
}

.c-bolt-accessibility-table__column {
  display: block;
  align-items: center;
  margin: 0;
  padding-top: var(--bolt-spacing-y-xsmall);
  padding-bottom: var(--bolt-spacing-y-xsmall);
  font-size: var(--bolt-type-font-size-xsmall);
  line-height: var(--bolt-type-line-height-xsmall);
}

.c-bolt-accessibility-table__column:nth-child(1) {
  min-width: 70px;
}

.c-bolt-accessibility-table__title {
  padding: var(--bolt-spacing-y-medium) 0;
  font-size: var(--bolt-type-font-size-regular);
  font-weight: var(--bolt-type-font-weight-bold);
  line-height: var(--bolt-type-line-height-regular);
}

.c-bolt-accessibility-table__text--large {
  font-size: var(--bolt-type-font-size-xlarge);
  font-weight: var(--bolt-type-font-weight-regular);
  line-height: 1;
}

.c-bolt-accessibility-table__text--medium {
  font-size: var(--bolt-type-font-size-medium);
  font-weight: var(--bolt-type-font-weight-regular);
  line-height: 1;
}

.c-bolt-accessibility-table__column:not(:first-child) {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  margin: 0 0 0 var(--bolt-spacing-x-xsmall);
  text-align: right;
}

.c-bolt-accessibility-table__results {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: var(--bolt-type-font-size-small);
  color: var(--bolt-color-gray-xdark);
  line-height: var(--bolt-type-line-height-small);
  border: solid 1px var(--bolt-color-gray-light);
  border-radius: 4px;
  background-color: var(--bolt-color-gray-light);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-bolt-accessibility-table__results--aa18 {
  background-color: var(--bolt-color-yellow);
}

.c-bolt-accessibility-table__results--fail {
  background-color: var(--bolt-color-warning);
}

.c-bolt-accessibility-table__text {
  display: inline-block;
  margin-right: var(--bolt-spacing-x-xsmall);

  &:last-child {
    margin-right: 0;
  }
}

.c-bolt-accessibility-table__column--white {
  color: var(--bolt-color-white);
}

.c-bolt-accessibility-table__column--black {
  color: var(--bolt-color-black);
}

.c-bds-color-chip {
  display: inline-block;
  width: 3ch;
  height: 0.85em;
  line-height: 1;
  border-radius: 1em;
  box-shadow: 0 0 2px var(--bolt-color-gray);
}

.c-bds-color-chip--border {
  background-color: var(--m-bolt-border);
}

.c-bds-color-chip--text {
  background-color: var(--m-bolt-text);
}

.c-bds-color-chip--background {
  background-color: var(--m-bolt-bg);
}

.c-bds-color-chip--bg-highlight {
  background-color: var(--m-bolt-bg-highlight);
}

.c-bds-color-chip--bg-lowlight {
  background-color: var(--m-bolt-bg-lowlight);
}

.c-bds-color-chip--primary {
  background-color: var(--m-bolt-primary);
}

.c-bds-color-chip--secondary {
  background-color: var(--m-bolt-secondary);
}

.c-bds-color-chip--tertiary {
  background-color: var(--m-bolt-tertiary);
}

.c-bds-color-chip--text-on-primary {
  background-color: var(--m-bolt-text-on-primary);
}

.c-bds-color-chip--text-on-secondary {
  background-color: var(--m-bolt-text-on-secondary);
}

.c-bds-color-chip--text-on-tertiary {
  background-color: var(--m-bolt-text-on-tertiary);
}

.c-bds-color-chip--link {
  background-color: var(--m-bolt-link);
}

.c-bds-color-chip--text-disabled {
  background-color: var(--m-bolt-text-on-disabled);
}

.c-bds-color-chip--disabled {
  background-color: var(--m-bolt-disabled);
}

.c-bds-color-chip--headline {
  background-color: var(--m-bolt-headline);
}

.c-bds-color-chip--neutral {
  background-color: var(--m-bolt-neutral);
}

[data-bds-color-shade] {
  display: none;
}

.c-bds-color-shade-toggle__label {
  display: inline-flex;
  position: relative;
  margin-bottom: var(--bolt-spacing-y-medium);
  padding-left: 5.5ch;
  font-size: var(--bolt-type-font-size-xsmall);
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--m-bolt-text);
  line-height: 2ch;
  text-transform: uppercase;
  letter-spacing: var(--bolt-type-letter-spacing-wide);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 2px;
    height: 2ch;
    border-radius: bolt-border-radius(full);
    box-shadow: 0 0 0 2px var(--bolt-color-gray-light);
    transition: all var(--bolt-transition);
  }

  &:before {
    width: 4ch;
    background-color: var(--bolt-color-gray-light);
  }

  &:after {
    width: 2ch;
    background-color: var(--bolt-color-white);
  }
}

.c-bds-color-shade-toggle {
  @include core.bolt-visuallyhidden;
}

.c-bds-color-shade-toggle:checked ~ * [data-bds-color-shade] {
  display: block;
}

.c-bds-color-shade-toggle:checked ~ .c-bds-color-shade-toggle__label {
  &:before {
    box-shadow: 0 0 0 2px var(--bolt-color-success);
    background-color: var(--bolt-color-success);
  }

  &:after {
    transform: translate3d(100%, 0, 0);
  }
}

.c-bds-color-shade-toggle:focus ~ .c-bds-color-shade-toggle__label,
.c-bds-color-shade-toggle__label:hover {
  text-decoration: underline;

  &:before {
    box-shadow: 0 0 0.5em var(--m-bolt-link);
  }
}
