$bolt-namespace: bolt;
/* ------------------------------------ *\
  #SITE WRAPPER
\* ------------------------------------ */

// Provides over-arching page wrapper + sticky footer functionality

.c-bolt-site {
  display: grid;
  grid-template-areas:
    "header"
    "content"
    "footer";
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
}

.c-page-header,
.c-bolt-site__header {
  grid-area: header;
}

.c-bolt-site__content {
  grid-area: content;
  width: 100%;
}

.c-bolt-site__footer {
  grid-area: footer;
}
