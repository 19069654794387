/* ------------------------------------ *\
   Japanese specific system
   Note: this ignores info density vars.
\* ------------------------------------ */

[lang='ja'] {
  --bolt-spacing-x: 1.085;
  --bolt-spacing-y: 0.945;

  --bolt-type-font-size-largedisplay: 4.15rem;
  --bolt-type-font-size-display: 3.5rem;
  --bolt-type-font-size-xxxlarge: 2.75rem;
  --bolt-type-font-size-xxlarge: 2.1rem;
  --bolt-type-font-size-xlarge: 1.6rem;
  --bolt-type-font-size-large: 1.35rem;
  --bolt-type-font-size-medium: 1.125rem;
  --bolt-type-font-size-regular: 1rem;
  --bolt-type-font-size-small: 0.85rem;
  --bolt-type-font-size-xsmall: 0.75rem;
  --bolt-type-font-size-tiny: 0.65rem;

  --bolt-type-line-height-largedisplay: 1.27;
  --bolt-type-line-height-display: 1.28;
  --bolt-type-line-height-xxxlarge: 1.27;
  --bolt-type-line-height-xxlarge: 1.25;
  --bolt-type-line-height-xlarge: 1.35;
  --bolt-type-line-height-large: 1.3;
  --bolt-type-line-height-medium: 1.3;
  --bolt-type-line-height-regular: 1.5;
  --bolt-type-line-height-small: 1.4;
  --bolt-type-line-height-xsmall: 1.4;
  --bolt-type-line-height-tiny: 1.35;

  --bolt-type-font-weight-bold: 700;

  --bolt-type-font-family-headline: -apple-system, BlinkMacSystemFont,
    'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック',
    '游ゴシック体', YuGothic, 'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ ゴシック',
    'MS Gothic', HiraKakuProN-W3, 'TakaoExゴシック', TakaoExGothic,
    'MotoyaLCedar', 'Droid Sans Japanese', sans-serif;
  --bolt-type-font-family-body: -apple-system, BlinkMacSystemFont,
    'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック',
    '游ゴシック体', YuGothic, 'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ ゴシック',
    'MS Gothic', HiraKakuProN-W3, 'TakaoExゴシック', TakaoExGothic,
    'MotoyaLCedar', 'Droid Sans Japanese', sans-serif;
}
