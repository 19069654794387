@use '@bolt/core' as core;
@use 'sass:list';

/* ------------------------------------ *\
   List
\* ------------------------------------ */

// Custom element display and built-in spacing
@include core.bolt-repeat-rule(('bolt-list', ':host')) {
  // Clear-fix required to prevent margin collapse due to negative margin on Bolt List.
  // Happens when List is inside Stack component, for example.
  // https://css-tricks.com/snippets/css/clear-fix/
  @include core.bolt-clearfix;

  display: block;
  margin-bottom: var(--bolt-spacing-y-medium);

  &:last-child {
    margin: 0;
  }
}

// List base styles
//
// 1. Reset typography so it doesn't inherit from a higher level container.
// 2. Reset text-align so it doesn't conflict with the align prop which handles the horizontal alignment of inline items in a list, not the text-align within.
// 3. Full width must be defined in order for the list to display correctly in Firefox.
// 4. Full width and 1px offset must be defined in order for the list to display correctly in Safari.
// 5. Full width and 0.01px (fix for rounding) must be defined in order for the list to display correctly in Firefox.

.c-bolt-list {
  margin: 0;
  padding: 0;
  font-family: var(--bolt-type-font-family-body); // [1]
  font-size: var(--bolt-type-font-size-regular); // [1]
  font-weight: var(--bolt-type-font-weight-regular); // [1]
  list-style: none;
  line-height: var(--bolt-type-line-height-regular);
  text-align: start; // [2]
}

// Display Prop
.c-bolt-list--display-block {
  display: block;
}

.c-bolt-list--display-inline {
  display: inline-flex;
  flex-flow: row wrap;
}

.c-bolt-list--display-flex {
  display: flex;
  flex-flow: row wrap;
}

@include core.bolt-repeat-rule(
  (
    '.c-bolt-list--display-flex bolt-list-item',
    '.c-bolt-list--display-flex ::slotted(bolt-list-item)'
  )
) {
  flex: 1;
}

@each $breakpoint in core.$bolt-breakpoints {
  $breakpoint-name: list.nth($breakpoint, 1);

  @if (
    $breakpoint-name !=
      'large' and
      $breakpoint-name !=
      'xlarge' and
      $breakpoint-name !=
      'xxlarge'
  ) {
    .c-bolt-list--display-inline\@#{$breakpoint-name} {
      display: flex;
      flex-flow: column wrap;

      @include core.bolt-mq($breakpoint-name) {
        display: inline-flex;
        flex-flow: row wrap;
      }
    }
  }
}

// The inline here is talking about the items inside, the List component itself is still a block level element that would fill up the space of any container.
.c-bolt-list--display-inline,
.c-bolt-list--display-flex {
  width: 100%; // [3]
}

@each $breakpoint in core.$bolt-breakpoints {
  $breakpoint-name: list.nth($breakpoint, 1);

  @if (
    $breakpoint-name !=
      'large' and
      $breakpoint-name !=
      'xlarge' and
      $breakpoint-name !=
      'xxlarge'
  ) {
    .c-bolt-list--display-inline\@#{$breakpoint-name} {
      @include core.bolt-mq($breakpoint-name) {
        width: 100%; // [3]
      }
    }
  }
}

// Spacing Prop
@each $size in core.$bolt-spacing-multiplier-system {
  $size-name: list.nth($size, 1);
  @if ($size-name != 'xxlarge') {
    .c-bolt-list--spacing-#{$size-name}:not(.c-bolt-list--inset) {
      margin-bottom: calc(var(--bolt-spacing-y-#{$size-name}) * -1);
      margin-inline-start: calc(var(--bolt-spacing-x-#{$size-name}) * -1);
    }

    .c-bolt-list--spacing-#{$size-name}.c-bolt-list--inset {
      margin-bottom: 0;
      margin-inline-start: 0;
    }

    .c-bolt-list--spacing-#{$size-name}:not(.c-bolt-list--inset) {
      &.c-bolt-list--display-inline,
      &.c-bolt-list--display-flex {
        width: calc(100% + 0.01px + var(--bolt-spacing-x-#{$size-name})); // [5]
      }

      @each $breakpoint in core.$bolt-breakpoints {
        $breakpoint-name: list.nth($breakpoint, 1);

        @if (
          $breakpoint-name !=
            'large' and
            $breakpoint-name !=
            'xlarge' and
            $breakpoint-name !=
            'xxlarge'
        ) {
          &.c-bolt-list--display-inline\@#{$breakpoint-name} {
            @include core.bolt-mq($breakpoint-name) {
              width: calc(100% + 0.01px + var(--bolt-spacing-x-#{$size-name})); // [5]
            }
          }
        }
      }
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    .c-bolt-list--spacing-#{$size-name}:not(.c-bolt-list--inset) {
      &.c-bolt-list--display-inline,
      &.c-bolt-list--display-flex {
        width: calc(100% + var(--bolt-spacing-x-#{$size-name}) + 1px); // [4]
      }

      @each $breakpoint in core.$bolt-breakpoints {
        $breakpoint-name: list.nth($breakpoint, 1);

        @if (
          $breakpoint-name !=
            'large' and
            $breakpoint-name !=
            'xlarge' and
            $breakpoint-name !=
            'xxlarge'
        ) {
          &.c-bolt-list--display-inline\@#{$breakpoint-name} {
            @include core.bolt-mq($breakpoint-name) {
              width: calc(
                100% + var(--bolt-spacing-x-#{$size-name}) + 1px
              ); // [4]
            }
          }
        }
      }
    }
  }
}

// Align options
$bolt-list-alignments: start, center, end, justify;

@each $alignment in $bolt-list-alignments {
  .c-bolt-list--align-#{$alignment} {
    &.c-bolt-list--display-inline {
      @if $alignment != center and $alignment != justify {
        justify-content: flex-#{$alignment};
      } @else if $alignment == justify {
        justify-content: space-between;
      } @else {
        justify-content: #{$alignment};
      }
    }

    @each $breakpoint in core.$bolt-breakpoints {
      $breakpoint-name: list.nth($breakpoint, 1);

      @if (
        $breakpoint-name !=
          'large' and
          $breakpoint-name !=
          'xlarge' and
          $breakpoint-name !=
          'xxlarge'
      ) {
        &.c-bolt-list--display-inline\@#{$breakpoint-name} {
          @include core.bolt-mq($breakpoint-name) {
            @if $alignment != center and $alignment != justify {
              justify-content: flex-#{$alignment};
            } @else if $alignment == justify {
              justify-content: space-between;
            } @else {
              justify-content: #{$alignment};
            }
          }
        }
      }
    }
  }
}

// Valign options
$bolt-list-valignments: start, center, end;

@each $alignment in $bolt-list-valignments {
  .c-bolt-list--valign-#{$alignment} {
    &.c-bolt-list--display-inline,
    &.c-bolt-list--display-flex {
      @if $alignment != center {
        align-items: flex-#{$alignment};
      } @else {
        align-items: #{$alignment};
      }
    }

    @each $breakpoint in core.$bolt-breakpoints {
      $breakpoint-name: list.nth($breakpoint, 1);

      @if (
        $breakpoint-name !=
          'large' and
          $breakpoint-name !=
          'xlarge' and
          $breakpoint-name !=
          'xxlarge'
      ) {
        &.c-bolt-list--display-inline\@#{$breakpoint-name} {
          @include core.bolt-mq($breakpoint-name) {
            @if $alignment != center {
              align-items: flex-#{$alignment};
            } @else {
              align-items: #{$alignment};
            }
          }
        }
      }
    }
  }
}

// Nowrap
.c-bolt-list--nowrap {
  &.c-bolt-list--display-inline,
  &.c-bolt-list--display-flex {
    flex-flow: row nowrap;
  }

  @each $breakpoint in core.$bolt-breakpoints {
    $breakpoint-name: list.nth($breakpoint, 1);

    @if (
      $breakpoint-name !=
        'large' and
        $breakpoint-name !=
        'xlarge' and
        $breakpoint-name !=
        'xxlarge'
    ) {
      &.c-bolt-list--display-inline\@#{$breakpoint-name} {
        @include core.bolt-mq($breakpoint-name) {
          flex-flow: row nowrap;
        }
      }
    }
  }
}
