$bolt-namespace: bolt;
@import '@bolt/core';

@import '00-vars/_vars-animation';
@import '00-vars/_vars-color';
@import '00-vars/_vars-spacing';
@import '00-vars/_vars-typography';
@import '00-vars/_vars-interactive-states';
@import '00-vars/_vars-mode';
@import '00-vars/_vars-page-width';
@import '00-vars/_vars-transition';
@import '00-vars/_vars-info-density'; /* Info density config must come after all other global vars */
@import '00-vars/_vars-japanese'; /* Japanese config must come last to override everything else */

@import '03-generic/_generic-font-custom';
@import '03-generic/_generic-font-stacks';
@import '03-generic/_generic-global';
@import '03-generic/_generic-normalize';
@import '03-generic/_generic-reset';

@import '04-elements/_elements-misc';
@import '04-elements/_elements-anchor-and-button';
@import '04-elements/_elements-code';
@import '04-elements/_elements-headings';
@import '04-elements/_elements-table';
@import '04-elements/_elements-page';

@import '05-objects/objects-bare-list/_objects-bare-list';
@import '05-objects/objects-flag/_objects-flag';
@import '05-objects/objects-grid/_objects-grid';
@import '05-objects/objects-inline-list/_objects-inline-list';
@import '05-objects/objects-ui-list/_objects-ui-list';
@import '05-objects/objects-wrapper/_objects-wrapper';

@import '06-animations/_animations';
@import '06-animations/_animations-fade-in';
@import '06-animations/_animations-fade-out';
@import '06-animations/_animations-zoom-in';
@import '06-animations/_animations-zoom-out';
@import '06-animations/_animations-bounce-in';
@import '06-animations/_animations-bounce-out';

@import '07-utilities/_utilities-bare-list';
@import '07-utilities/_utilities-border-radius';
@import '07-utilities/_utilities-clearfix';
@import '07-utilities/_utilities-colors';
@import '07-utilities/_utilities-display';
@import '07-utilities/_utilities-flex';
@import '07-utilities/_utilities-float';
@import '07-utilities/_utilities-gradient';
@import '07-utilities/_utilities-height';
@import '07-utilities/_utilities-line-length';
@import '07-utilities/_utilities-opacity';
@import '07-utilities/_utilities-overflow';
@import '07-utilities/_utilities-pointer-events';
@import '07-utilities/_utilities-position';
@import '07-utilities/_utilities-shadow';
@import '07-utilities/_utilities-spacing';
@import '07-utilities/_utilities-text-align';
@import '07-utilities/_utilities-text-decoration';
@import '07-utilities/_utilities-vertical-alignment';
@import '07-utilities/_utilities-visuallyhidden';
@import '07-utilities/_utilities-widths';
@import '07-utilities/_utilities-z-index';

@import '08-vendor/_vendor-tippy';
