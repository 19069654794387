@use '@bolt/core' as core;
@use '../bolt-select/bolt-select' as bolt-select;

.#{bolt-select.$choices-selector}__inner {
  min-height: 0; // overrides min-height 44px
  font-size: var(--bolt-type-font-size-small);
  font-weight: var(--bolt-type-font-weight-semibold);
  line-height: var(--bolt-type-line-height-small);
  vertical-align: middle;
}

.#{bolt-select.$choices-selector}__heading {
  padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small) 0
    var(--bolt-spacing-x-xsmall);
  line-height: 1;
}

.#{bolt-select.$choices-selector}__group ~ .#{bolt-select.$choices-selector}__item {
  padding-left: calc(var(--bolt-spacing-y-small) / 2)
    var(--bolt-spacing-x-small);
}

.#{bolt-select.$choices-selector}__list--dropdown .#{bolt-select.$choices-selector}__item--selectable {
  padding-right: var(--bolt-spacing-x-small);
  padding-left: var(--bolt-spacing-x-small);
}

.#{bolt-select.$choices-selector}__input {
  margin-bottom: 0;
  font-size: 16px; // prevent input zoom on mobile
}

.#{bolt-select.$choices-selector}[data-type*='select-one'].is-open:after {
  border-bottom-color: currentColor;
}

.#{bolt-select.$choices-selector}[data-type*='select-one']:after {
  border-top-color: currentColor;
}

.#{bolt-select.$choices-selector}__list--single {
  padding-right: calc(
    var(--bolt-spacing-x-small) + var(--bolt-spacing-x-xsmall)
  );
}

.#{bolt-select.$choices-selector}__inner,
.#{bolt-select.$choices-selector}[data-type*='select-one'] .#{bolt-select.$choices-selector}__inner {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: var(--bolt-spacing-x-xsmall);
}

.#{bolt-select.$choices-selector}__list--dropdown {
  width: auto;
  color: var(--bolt-color-black);

  @include core.bolt-mq(small) {
    width: 100%;
    min-width: 140px;
  }
}

.#{bolt-select.$choices-selector} {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  transition: var(--bolt-transition);
}

.#{bolt-select.$choices-selector}.is-focused {
  border-color: var(--bolt-color-navy-light);
  @include core.bolt-shadow(
    $key: 'level-20',
    $base-color: var(--bolt-color-navy-light)
  );
}

.c-bds-version-selector {
  font-size: var(--bolt-type-font-size-small);
  color: inherit;
  line-height: 1;
  border-color: transparent;
  background-color: transparent;

  & ~ .c-bolt-input-icons {
    padding: 0.05rem 0.1rem 0 0; // This is a one-off override for this site only. It would stop working if the bolt-form becomes a web component.
    color: inherit;
    background-color: transparent;

    &:before {
      display: none;
    }
  }
}
