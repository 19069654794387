/* ------------------------------------ *\
   Menu
\* ------------------------------------ */

/**
 * Dev notes:
 * 1. [Mai] This must change if the default in the schema changes. Small is the current default.
 * 2. [Mai] Reset the outline coming from the copy-to-clipboard component. This is needed for when the copy-to-clipboard component is used as a menu-item.
 * 3. [Mai] Reset font styles here so it doesn't inherit from parent container.
 * 4. [Mai] Reset the stacking order to be the lowest within the item container.
 * 5. Vertically centering issue fix.
 * 6. Style text-link when it's inside e-bolt-menu__item
*/

@import '@bolt/core';

$bolt-menu-border-width: $bolt-border-width;
$bolt-menu-border-style: $bolt-border-style;
$bolt-menu-border-color: var(--m-bolt-border);
$bolt-menu-spacing-values: xsmall, small, medium;

.e-bolt-menu {
  display: block;
  font-family: var(--bolt-type-font-family-body); /* [3] */
  font-size: var(--bolt-type-font-size-xsmall); /* [3] */
  font-weight: var(--bolt-type-font-weight-regular); /* [3] */
  line-height: var(--bolt-type-line-height-xsmall); /* [3] */
  text-align: start; /* [3] */

  @at-root menu#{&},
    menu {
    margin: 0;
    padding: 0;
  }
}

@each $value in $bolt-menu-spacing-values {
  .e-bolt-menu--spacing-#{$value} {
    .e-bolt-menu__title,
    .e-bolt-menu__item .e-bolt-text-link {
      padding: calc(var(--bolt-spacing-y-#{$value}) / 2)
        var(--bolt-spacing-x-#{$value});
    }
  }
}

.e-bolt-menu__title {
  display: block;
  margin-bottom: 0;
  padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-small);
  font-size: var(--bolt-type-font-size-small);
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--m-bolt-headline);
  letter-spacing: 1px;
  border-bottom-color: $bolt-menu-border-color;
  border-bottom-style: $bolt-menu-border-style;
  border-bottom-width: $bolt-menu-border-width;
}

.e-bolt-menu__item {
  display: block;
  position: relative;
  transform: translate3d(0, 0, 0);
  font-family: var(--bolt-type-font-family-body); /* [2] */
  font-size: var(--bolt-type-font-size-small); /* [2] */
  font-weight: var(--bolt-type-font-weight-regular); /* [2] */
  color: var(--m-bolt-text);
  line-height: var(--bolt-type-line-height-small); /* [2] */
  transition: background-color var(--bolt-transition);

  & + * {
    border-top-color: $bolt-menu-border-color;
    border-top-style: $bolt-menu-border-style;
    border-top-width: $bolt-menu-border-width;
  }

  bolt-icon[size],
  .e-bolt-icon {
    display: block;
    place-items: center;
    position: relative;
    top: -0.18em; /* [5] */
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 0; /* [4] */
    user-select: none;
  }

  &:before {
    opacity: 0.05;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    width: 0;
    height: 100%;
    background-color: var(--m-bolt-link);
    transition: width var(--bolt-transition),
      border-radius var(--bolt-transition);
  }

  &:after {
    top: 50%;
    bottom: 0;
    left: 0;
    transform: translate3d(0, -50%, 0);
    width: 3px;
    height: 0;
    border-radius: bolt-border-radius(small);
    background-color: var(--bolt-color-teal);
    transition: height var(--bolt-transition);
  }

  &:hover {
    &:has(.e-bolt-text-link) {
      color: var(--m-bolt-link);
    }

    &:has(.e-bolt-text-link) {
      &:before {
        width: 100%;
        pointer-events: none;
        border-radius: 0;
      }
    }
  }

  &:has(.e-bolt-text-link) {
    &:active {
      background-color: var(--m-bolt-tertiary);
    }
  }

  &:has(.e-bolt-text-link) {
    &:focus-within {
      font-weight: var(--bolt-type-font-weight-semibold);
      color: var(--m-bolt-headline);

      &:before {
        width: 100%;
        border-radius: 0;
      }

      &:after {
        height: calc(100% - var(--bolt-spacing-y-xsmall) * 2);
      }
    }
  }

  &:has(.e-bolt-text-link) {
    padding: 0;
  }

  &:not(:has(.e-bolt-text-link)) {
    padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-small);
  }

  [class*='__icon-'] {
    display: inline-flex;
    place-items: center;
  }

  [class*='__icon-after'] {
    margin-left: auto;
  }

  /* [6] */
  .e-bolt-text-link {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-small);
    color: var(--m-bolt-text);
    text-decoration: none;

    &:hover {
      color: var(--m-bolt-link);
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }
  }
}
