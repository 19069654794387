@use '@bolt/core' as core;

.c-portal-quick-links {
  margin-bottom: var(--bolt-spacing-y-medium);
  padding-top: var(--bolt-spacing-y-medium);
  padding-bottom: var(--bolt-spacing-y-xlarge);
  background-color: var(--m-bolt-bg);

  @include core.bolt-mq(medium) {
    display: grid;
    margin-bottom: 0;
    padding-bottom: 0;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--bolt-spacing-x-medium);
  }
}

.c-portal-quick-links__col {
  border: 1px solid var(--m-bolt-border);
  border-radius: core.bolt-border-radius(small);

  &:nth-of-type(2) {
    display: none;

    @include core.bolt-mq(medium) {
      display: block;
    }
  }
}

.c-portal-quick-links__col-title {
  padding: var(--bolt-spacing-y-medium) var(--bolt-spacing-x-medium);
  border-bottom: 1px solid var(--m-bolt-border);
}

.c-portal-quick-links__skip-button {
  position: absolute;
  top: var(--bolt-spacing-y-medium);
  right: var(--bolt-spacing-x-medium);
  transform: translateY(calc(-100% - var(--bolt-spacing-y-large)));

  &:focus {
    position: absolute;
    transform: translateY(0);
    z-index: 999;
  }
}

.c-portal-quick-links__skip-button--open {
  display: none;

  @include core.bolt-mq(medium) {
    display: inline-block;
  }
}

.c-portal-quick-links__skip-button--mobile {
  display: block;

  @include core.bolt-mq(medium) {
    display: none;
  }
}

.c-portal-quick-links__col-inner {
  position: relative;
  height: 45vh;
  overflow-y: scroll;
  overflow-x: auto;
  padding: var(--bolt-spacing-y-medium) var(--bolt-spacing-x-medium);

  .e-bolt-text-link {
    display: inline-flex; // If the link is very long prevent from wrapping around icon
  }

  .e-bolt-button--icon-only {
    margin-left: calc(
      var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xsmall) * 1em
    );

    &:hover {
      color: var(--m-bolt-headline);
    }
  }
}

// List base styles
//
// 1. Reset typography so it doesn't inherit from a higher level container.
// 2. Reset text-align so it doesn't conflict with the align prop which handles the horizontal alignment of inline items in a list, not the text-align within.

.c-portal-quick-links__list {
  margin: 0 0 var(--bolt-spacing-y-medium) 0;
  padding: 0;
  font-family: var(--bolt-type-font-family-body); // [1]
  font-size: var(--bolt-type-font-size-regular); // [1]
  font-weight: var(--bolt-type-font-weight-regular); // [1]
  list-style: none;
  line-height: var(--bolt-type-line-height-regular);
  text-align: start; // [2]

  &:last-child {
    margin-bottom: 0;
  }
}

.c-portal-quick-links__list-item {
  display: flex;
  margin-bottom: var(--bolt-spacing-y-small);
}

.c-portal-quick-links__trigger {
  margin-bottom: var(--bolt-spacing-y-medium);

  @include core.bolt-mq(medium) {
    display: none;
  }
}

.c-portal-quick-links__modal {
  display: block;

  @include core.bolt-mq(medium) {
    display: none;
  }

  .c-portal-quick-links__col-inner {
    height: 65vh;

    @include core.bolt-mq(small) {
      height: 38vh;
    }
  }
}
