.c-bds-callout {
  margin-top: var(--bolt-spacing-y-small);
  margin-bottom: var(--bolt-spacing-y-small);
  padding: calc(var(--bolt-spacing-y-medium) / 2) var(--bolt-spacing-x-medium);
  font-size: var(--bolt-type-font-size-small);
  line-height: var(--bolt-type-line-height-small);
  border: 1px solid var(--bolt-color-gray-light);
  border-left-width: 0.25rem;
  border-radius: 0.25rem;

  > p:not(:last-child) {
    margin-bottom: var(--bolt-spacing-y-small);
  }
}

.c-bds-callout--notice {
  border-left-color: var(--bolt-color-info);
}

// .c-bds-callout--warning {
//   border-left-color: var(--bolt-color-info);
// }
