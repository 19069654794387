.c-bds-docs-color-demo {
  &__type {
    margin-bottom: 1rem;
    padding: 0 1rem 1rem;
    border-bottom: 1px dashed var(--bolt-color-navy-light);

    h5 {
      margin-bottom: 1.5rem;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  &__color {
    margin-bottom: 2rem;

    .e-bolt-wrapper {
      padding: 0;
    }

    .u-bolt-color-white {
      background: #000;
    }
  }
}
