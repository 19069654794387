@import '@bolt/core';

// Image Component Styling (if any)

bolt-image {
  display: block;
}

.c-bolt-image__image {
  display: block;
  // temp fix, prevents huge image when in shadow dom but not in bolt-ratio
  max-width: 100%;
}

.c-bolt-image__lazyload {
  transition: opacity 0.2s ease, filter 0.2s ease;
}

.c-bolt-image__lazyload--blur {
  filter: blur(20px);

  &.is-lazyloaded {
    filter: none;
  }
}

.c-bolt-image__lazyload--fade {
  opacity: bolt-opacity(0);

  &.is-lazyloaded {
    opacity: bolt-opacity(100);
  }
}

.c-bolt-image__image-placeholder {
  pointer-events: none;
  filter: blur(20px); // low quality image placeholders should always be blurred
  transition: opacity 0.2s ease;
}

.c-bolt-image--cover {
  position: absolute;
  top: -9999px;
  right: -9999px;
  bottom: -9999px;
  left: -9999px;
  min-width: 100%;
  max-width: none; // Required to handle instances where a max-width is previously set.
  min-height: 100%;
  margin: auto;

  @supports (object-fit: cover) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .is-expanded & {
    opacity: bolt-opacity(100);
    filter: blur(10px);
  }
}
