@use '@bolt/core' as core;

/**
 * Site
 */

.l-bolt-site {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
  min-height: 100vh;

  > *:last-child:not(main) {
    grid-area: footer;
  }

  > *:only-child {
    grid-area: main !important;
    min-width: 0;
  }

  > main {
    grid-area: main;
  }
}
