/* ------------------------------------ *\
   HTML and body resets
\* ------------------------------------ */

html {
  font-size: var(--bolt-type-font-size);
}

body {
  width: 100%;
  margin: 0;
  font-family: var(--bolt-type-font-family-body);
  font-size: var(--bolt-type-font-size-regular);
  line-height: var(--bolt-type-line-height-regular);
  overflow-x: hidden; // Helps prevent horizontal scrolling in Chrome on Windows machines. Seems to be working fine w/ native position sticky at first glance.
}
