$bolt-namespace: bolt;
@import '@bolt/core';

.c-bolt-background-shapes {
  width: 300px;

  @include bolt-mq(small) {
    width: 500px;
  }

  @include bolt-mq(large) {
    width: 900px;
  }

  &__svg {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__shape {
    // To support IE, use the "bottom padding" hack to position svgs within the grid.
    // See https://css-tricks.com/scale-svg/#article-header-id-10
    position: relative;
    height: 0;
    padding-bottom: 100%;

    &--orange {
      .c-bolt-background-shapes__svg-primary {
        fill: var(--bolt-color-orange);
      }

      .c-bolt-background-shapes__svg-outline {
        stroke: var(--bolt-color-orange);
      }
    }

    &--yellow {
      .c-bolt-background-shapes__svg-primary {
        fill: var(--bolt-color-yellow);
      }

      .c-bolt-background-shapes__svg-outline {
        stroke: var(--bolt-color-yellow);
      }
    }

    &--teal {
      .c-bolt-background-shapes__svg-primary {
        fill: var(--bolt-color-teal);
      }

      .c-bolt-background-shapes__svg-outline {
        stroke: var(--bolt-color-teal);
      }
    }

    &--navy {
      .c-bolt-background-shapes__svg-primary {
        fill: var(--bolt-color-navy);
      }

      .c-bolt-background-shapes__svg-outline {
        stroke: var(--bolt-color-navy);
      }
    }

    &--interior-yellow {
      .c-bolt-background-shapes__svg-interior {
        fill: var(--bolt-color-yellow);
      }
    }

    &--interior-navy {
      .c-bolt-background-shapes__svg-interior {
        fill: var(--bolt-color-navy);
      }
    }

    &--interior-teal {
      .c-bolt-background-shapes__svg-interior {
        fill: var(--bolt-color-teal);
      }
    }

    &--interior-orange {
      .c-bolt-background-shapes__svg-interior {
        fill: var(--bolt-color-orange);
      }
    }
  }
}
