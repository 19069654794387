/* ------------------------------------ *\
   Article Styles
\* ------------------------------------ */

$_bolt-article-element-spacing: var(--bolt-spacing-y-medium);

.e-bolt-article {
  font-size: var(--bolt-type-font-size-regular);
  color: var(--m-bolt-text);
  line-height: var(--bolt-type-line-height-regular);

  a {
    overflow-wrap: break-word;
  }

  p,
  blockquote,
  figure,
  pre,
  ul,
  ol {
    margin: 0 0 #{$_bolt-article-element-spacing} 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 var(--bolt-spacing-y-xsmall) 0;
    color: var(--m-bolt-headline);
  }

  h1,
  h2,
  h3,
  h4 {
    letter-spacing: var(--bolt-type-letter-spacing-narrow);
    hyphens: auto;
    word-break: break-word;
  }

  h1 {
    font-size: var(--bolt-type-font-size-xlarge);
    line-height: var(--bolt-type-line-height-xlarge);
  }

  h2 {
    font-size: var(--bolt-type-font-size-large);
    line-height: var(--bolt-type-line-height-large);
  }

  h3 {
    font-size: var(--bolt-type-font-size-medium);
    line-height: var(--bolt-type-line-height-medium);
  }

  h4 {
    font-size: var(--bolt-type-font-size-regular);
    line-height: var(--bolt-type-line-height-regular);
  }

  h5 {
    font-size: var(--bolt-type-font-size-small);
    line-height: var(--bolt-type-line-height-small);
  }

  h6 {
    font-size: var(--bolt-type-font-size-xsmall);
    font-weight: var(--bolt-type-font-weight-semibold);
    line-height: var(--bolt-type-line-height-xsmall);
    text-transform: uppercase;
    letter-spacing: var(--bolt-type-letter-spacing-wide);
  }

  blockquote {
    margin-right: 0;
    margin-left: 0;
    padding-inline-start: var(--bolt-spacing-x-small);
    font-size: var(--bolt-type-font-size-medium);
    font-weight: var(--bolt-type-font-weight-semibold);
    line-height: var(--bolt-type-line-height-medium);
    border-inline-start: 3px solid var(--bolt-color-teal);

    p {
      font-size: inherit;

      & + p {
        margin-top: calc(var(--bolt-spacing-y-small) * -1);
      }
    }
  }

  blockquote footer,
  figcaption {
    opacity: 0.8;
  }

  figcaption {
    font-size: 0.8em;
  }

  ol,
  ul {
    padding-inline: 2.25em 0;

    li {
      margin-bottom: var(--bolt-spacing-y-xsmall);

      &:last-of-type {
        margin-bottom: 0;
      }

      ol,
      ul {
        margin-top: var(--bolt-spacing-y-xsmall);
        padding-inline-start: 1.25em;
      }
    }
  }

  ol,
  ol > li > ol > li > ol > li > ol {
    list-style-type: decimal;
  }

  ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol {
    list-style-type: lower-alpha;
  }

  ol > li > ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol > li > ol {
    list-style-type: upper-roman;
  }

  header,
  section {
    margin: 0 0 calc(#{$_bolt-article-element-spacing} * 1.5) 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
