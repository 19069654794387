@import '@bolt/core';

/* ------------------------------------ *\
   Navbar Menu
\* ------------------------------------ */

.c-bolt-navbar-menu {
  display: flex;
  flex-shrink: 0;
  position: relative;
  white-space: nowrap;
  @include bolt-mq($until: xsmall) {
    display: contents;
  }
}

.c-bolt-navbar-menu__popover {
  @include bolt-mq($until: xsmall) {
    grid-row: 1 / span 1;
    grid-column: 5 / span 1;
  }
}

.c-bolt-navbar-menu__button {
  @include bolt-button-reset;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: calc(var(--bolt-spacing-y-xsmall) / 2) var(--bolt-spacing-x-xsmall);
  color: var(--m-bolt-headline);
  cursor: pointer;
  border-radius: bolt-border-radius(small);
  // Added border so that &:after,&:focus doesn't change the size of the band
  // We should consider making this thin dotted style a mixin to share with navbar
  border-width: thin;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;

  @include bolt-mq($until: xsmall) {
    justify-content: center;
    width: 44px;
    height: 100%; // fix for height: 100% causing issues on larger screen sizes
    min-height: 44px;
    border-left-color: var(--m-bolt-border);
    border-radius: 0;
    border-width: 0 0 0 1px;
    grid-row: 1 / span 1;
    grid-column: 5 / span 1;
  }

  @include bolt-mq(xsmall) {
    padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-small);
  }

  &:before {
    content: '';
    opacity: 0.05;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    background-color: currentColor;
    transition: opacity 0.3s ease;

    @include bolt-mq(xsmall) {
      border-radius: bolt-border-radius(small);
    }
  }

  &:hover:before {
    opacity: 0.1;
  }

  &:focus {
    border-style: dotted;
    border-color: var(--m-bolt-neutral);
    outline: none;
  }

  @at-root [aria-expanded='true'] & {
    &:before {
      opacity: 0.1;
    }
  }
}

.c-bolt-navbar-menu__button-text {
  position: relative;
  // z-index: 10; // @todo
  margin-right: var(--bolt-spacing-x-small);
  font-family: var(--bolt-type-font-family-body);
  font-size: var(--bolt-type-font-size-small);
  font-weight: var(--bolt-type-font-weight-semibold);
  line-height: var(--bolt-type-line-height-small);

  @include bolt-mq($until: xsmall) {
    @include bolt-visuallyhidden;
  }
}

.c-bolt-navbar-menu__button-icon {
  display: inline-block;
  position: relative;
  // z-index: 10; // @todo
  transform: scale(1) rotate(90deg);
  font-size: 1em;
  line-height: 0;
  will-change: transform;
  transition: transform ease-out 250ms;

  @media (prefers-reduced-motion) {
    transition: none !important;
  }

  @include bolt-mq($until: xsmall) {
    display: flex;
    justify-content: center;
    transform: scale(0.7) rotate(90deg);
    font-size: 24px;
  }

  @at-root [aria-expanded='true'] & {
    transform: scale(0) rotate(90deg);
  }

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 0;
  }
}

.c-bolt-navbar-menu__button-icon--close {
  transform: scale(0) rotate(360deg);
  @at-root [aria-expanded='true'] & {
    transform: scale(0.7);
  }
}

.c-bolt-navbar-menu__content.c-bolt-popover__content.c-bolt-popover__content--text-wrap {
  @include bolt-mq($until: xsmall) {
    width: calc(100vw - 30px);
  }
}

.c-bolt-navbar-menu__dropdown {
  margin-left: 0;
  background-color: var(--m-bolt-tertiary);

  .c-bolt-navbar-item {
    white-space: normal;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
    }

    &:before {
      opacity: 0.05;
      top: 0;
      left: 0;
      transform: translate3d(0, 0, 0);
      width: 0;
      height: 100%;
      background-color: var(--m-bolt-link);
      transition: width var(--bolt-transition),
        border-radius var(--bolt-transition);
    }

    &:after {
      top: 50%;
      bottom: 0;
      left: 0;
      transform: translate3d(0, -50%, 0);
      width: 3px;
      height: 0;
      border-radius: bolt-border-radius(small);
      background-color: var(--m-bolt-primary);
      transition: height var(--bolt-transition);
    }

    &:hover {
      color: var(--m-bolt-link);
      cursor: pointer;

      &:before {
        width: 100%;
        border-radius: 0;
      }
    }

    &:focus,
    &:active,
    &:focus-within {
      color: var(--m-bolt-link);

      &:before {
        width: 100%;
        border-radius: 0;
      }

      &:after {
        opacity: 1;
        height: calc(100% - var(--bolt-spacing-y-xsmall) * 2);
      }
    }

    &[data-bolt-current] {
      &:after {
        height: calc(100% - var(--bolt-spacing-y-xsmall) * 2);
      }
    }

    &:not(:last-child) {
      .c-bolt-navbar-menu__dropdown-item {
        border-bottom-color: var(--m-bolt-border);
        border-bottom-style: solid;
        border-bottom-width: 1px;
      }
    }
  }

  .c-bolt-navbar-menu__dropdown-item {
    @include bolt-button-native-styles-reset;

    display: block;
    position: relative;
    transform: translate3d(0, 0, 0);
    width: 100%;
    padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-small);
    font-family: var(--bolt-type-font-family-body);
    font-size: var(--bolt-type-font-size-small);
    font-weight: var(--bolt-type-font-weight-regular);
    color: var(--m-bolt-text);
    line-height: var(--bolt-type-line-height-small);
    text-decoration: initial;
    outline: none;
    transition: background-color var(--bolt-transition);
  }
}
