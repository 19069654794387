@import '@bolt/core';

.pl-c-main.pl-c-main {
  min-height: 100vh; // so demos with overlays, etc aren't immediately chopped off
  // overrides the defaults in PL for now
  padding-right: 0;
  padding-left: 0;

  .pl-c-pattern__header {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  // fix rendering bug when rendering pages on view all sections
  .c-bolt-site {
    display: block;
    height: auto;
    overflow: hidden;
  }

  .c-bolt-toolbar {
    width: 100%;
  }
}

.sg-main {
  padding: var(--bolt-spacing-y-medium) var(--bolt-spacing-x-medium);
}

// Remove double padding in PL when top level wrapper exists
.sg-pattern-example > .e-bolt-wrapper > .e-bolt-wrapper {
  padding-right: 0;
  padding-left: 0;
}

.sg-pattern-head.sg-pattern-head {
  position: sticky;
  top: 0;
  z-index: bolt-z-index('nav');
  margin-right: calc(-1 * var(--bolt-spacing-x-medium));
  margin-bottom: 0;
  margin-left: calc(-1 * var(--bolt-spacing-x-medium));
  padding: calc(var(--bolt-spacing-y-medium) / 2) var(--bolt-spacing-x-medium);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12);
  background-color: var(--bolt-color-white);
}

.sg-pattern-extra.sg-pattern-extra.sg-pattern-extra {
  max-width: 1400px;
  margin-top: var(--bolt-spacing-y-medium);
  margin-right: auto;
  margin-left: auto;
  border-top: 0;
}

.sg-pattern-extra.sg-pattern-extra {
  max-height: 0px; // prevent background from showing up
}

.pl-c-pattern {
  position: static;
}

.pl-c-pattern[id*='-docs'] {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.pl-placeholder-fixed-element {
  position: fixed;

  @at-root .pl-c-pattern & {
    position: static; // Set to 'static' on the View All page
  }
}

.pl-c-category,
.pl-c-category__title,
.pl-c-pattern[id*='-docs'] > .pl-c-pattern__header,
.pl-c-pattern[id*='-docs'] > .pl-c-pattern__extra,
.pl-c-pattern[id*='-docs'] .pl-c-pattern__extra-wrapper,
.pl-c-tabs__link[data-panelid='pl-panel-html'] {
  display: none !important;
}

.pl-c-category:first-of-type {
  margin: 0;
}
