/* ------------------------------------ *\
  Slideshow
\* ------------------------------------ */

@import '@bolt/core';

@import 'swiper/swiper';
@import 'swiper/modules/pagination/pagination';
@import 'swiper/modules/scrollbar/scrollbar';
@import 'swiper/modules/autoplay/autoplay';

// Register Custom Block Element
@include bolt-custom-element('.c-bolt-slideshow', block, medium);

.c-bolt-slideshow {
  --swiper-theme-color: var(--m-bolt-primary);
  --swiper-pagination-bullet-inactive-color: var(--m-bolt-text);
  --swiper-pagination-bullet-horizontal-gap: 6px;

  position: relative;

  .c-bolt-slideshow__slide {
    height: auto;
  }

  .c-bolt-slideshow__navigation {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 1;
    height: calc(100% - 24px);
    margin-top: 0;
    pointer-events: none;

    // this allows us to still us the buttons while being able to click through the container
    * {
      pointer-events: all;
    }

    .c-bolt-slideshow__navigation--hidden {
      visibility: hidden;
    }
  }

  .c-bolt-slideshow__navigation-prev {
    left: var(--bolt-spacing-x-xsmall);
  }

  .c-bolt-slideshow__navigation-next {
    right: var(--bolt-spacing-x-xsmall);
  }

  .c-bolt-slideshow__pagination {
    position: relative;
    bottom: auto !important;
  }

  .c-bolt-slideshow__scrollbar {
    position: relative;
    bottom: auto !important;
    margin: var(--bolt-spacing-y-small) 0;
  }

  &.c-bolt-slideshow--outside {
    .swiper {
      margin: 0 var(--bolt-spacing-x-large);
    }

    .c-bolt-slideshow__navigation-prev {
      left: 0;
    }

    .c-bolt-slideshow__navigation-next {
      right: 0;
    }
  }

  @include bolt-mq(large) {
    &.c-bolt-slideshow--overflow {
      .swiper {
        overflow: visible;
      }
    }
  }
}
