/* ------------------------------------ *\
   Banner
\* ------------------------------------ */

@import '@bolt/core';

.c-bolt-banner {
  --m-bolt-text: var(--bolt-color-info-dark);
  --m-bolt-headline: var(--bolt-color-info-dark);
  --m-bolt-link: var(--bolt-color-info-dark);

  display: block;
  position: relative;
  padding: calc(var(--bolt-spacing-y-medium) / 2) var(--bolt-spacing-x-medium);
  color: var(--bolt-color-info-dark);
  text-align: center;
  border-left-color: var(--bolt-color-info);
  border-left-style: $bolt-border-style;
  border-left-width: $bolt-border-width * 3;
  box-shadow: 0 0 var(--bolt-spacing-y-xsmall) var(--m-bolt-border);
  background-color: var(--bolt-color-info-light);

  &:not(:last-child) {
    margin-bottom: var(--bolt-spacing-y-medium);
  }
}

.c-bolt-banner--dismissible {
  padding-right: var(--bolt-spacing-x-large);

  .c-bolt-banner__dismiss {
    display: flex;
    position: absolute;
    top: calc(var(--bolt-spacing-y-medium) / 2);
    right: calc(var(--bolt-spacing-x-medium) / 2);
  }
}

.c-bolt-banner--align-start {
  text-align: start;
}

.c-bolt-banner--align-end {
  text-align: right;
  text-align: end;
}

$_bolt-banner-status: error, warning, success, information;

@each $status-name in $_bolt-banner-status {
  @if $status-name == error or $status-name == success {
    .c-bolt-banner--status-#{$status-name} {
      @include bolt-base-dark-mode;

      color: var(--bolt-color-white);
      border-left: 0;
      box-shadow: none;
      background-color: var(--bolt-color-#{$status-name});
    }
  } @else if $status-name == warning {
    .c-bolt-banner--status-#{$status-name} {
      @include bolt-base-light-mode;
      --m-bolt-headline: var(--bolt-color-black);
      --m-bolt-link: var(--bolt-color-black);

      color: var(--bolt-color-black);
      border-left: 0;
      box-shadow: none;
      background-color: var(--bolt-color-#{$status-name});
    }
  }
}
