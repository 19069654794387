/* ------------------------------------ *\
   Truncate Items
\* ------------------------------------ */

@import '@bolt/core';

// Register Custom Block Element
@include bolt-custom-element('bolt-chip-list', block, medium);

.c-bolt-chip-list {
  display: block;
}

.c-bolt-chip-list__input {
  @include bolt-visuallyhidden;
}
