/* ------------------------------------ *\
  #HEIGHT UTILITIES
\* ------------------------------------ */

@import '@bolt/core';

$bolt-height-props: (
  'none': 0,
  'auto': auto,
  'full': 100%,
  'fullscreen': 100vh,
);

// Height
@mixin bolt-height-utils($breakpoint: null) {
  @each $name, $value in $bolt-height-props {
    .u-bolt-height-#{$name}#{$breakpoint} {
      height: $value !important;
    }
  }
}

@include bolt-height-utils;

// Loop over our breakpoints
@each $breakpoint in $bolt-breakpoints {
  $breakpointName: nth($breakpoint, 1);
  @include bolt-mq($breakpointName) {
    @include bolt-height-utils(\@#{$breakpointName});
  }
}

// Min height
@mixin bolt-min-height-utils($breakpoint: null) {
  @each $name, $value in $bolt-height-props {
    .u-bolt-min-height-#{$name}#{$breakpoint} {
      min-height: $value !important;
    }
  }
}

@include bolt-min-height-utils;

// Loop over our breakpoints
@each $breakpoint in $bolt-breakpoints {
  $breakpointName: nth($breakpoint, 1);
  @include bolt-mq($breakpointName) {
    @include bolt-min-height-utils(\@#{$breakpointName});
  }
}

@include export-data('height.bolt.json', $bolt-height-props);
