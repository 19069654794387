@use '@bolt/core' as core;

$gen-ai-overflow-shadow-width: var(--bolt-spacing-x-small);
$gen-ai-button-shadow-offset: var(--bolt-spacing-y-medium);

.c-gen-ai__wrapper-outer {
  margin: calc(#{$gen-ai-button-shadow-offset} * -1) 0
    calc(#{$gen-ai-button-shadow-offset} * -1)
    calc(#{$gen-ai-button-shadow-offset} * -0.5);
}

.c-gen-ai__wrapper-inner {
  position: relative;
  overflow: hidden;
  transition: margin-left var(--bolt-transition);

  &:before,
  &:after {
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc(#{$gen-ai-overflow-shadow-width} * 2);
    pointer-events: none;
    background: radial-gradient(rgba(black, 0.2), rgba(black, 0) 50%);
  }

  &:before {
    left: calc(#{$gen-ai-overflow-shadow-width} * -1);
    z-index: 1;
  }

  &:after {
    right: calc(#{$gen-ai-overflow-shadow-width} * -1);
  }

  &.is-overflowing {
    &.is-not-start {
      margin-left: calc(#{$gen-ai-button-shadow-offset} / 2);
    }

    &.is-not-start:before,
    &.is-not-end:after {
      opacity: 1;
    }
  }
}

.c-gen-ai__wrapper-inner bolt-grid {
  padding: #{$gen-ai-button-shadow-offset} 0;
  grid-template-columns: repeat(5, minmax(200px, 1fr));
  --c-bolt-grid-column-gap: clamp(
    var(--bolt-spacing-x-xsmall),
    2.5vw,
    var(--bolt-spacing-x-xsmall)
  );
}

.c-gen-ai__wrapper-inner bolt-grid {
  @include core.bolt-horizontal-scroll;
  position: relative;
}

.c-gen-ai__wrapper-inner bolt-grid-item {
  &:first-child {
    padding-left: #{$gen-ai-overflow-shadow-width};
  }

  &:last-child {
    padding-right: #{$gen-ai-overflow-shadow-width};
  }
}

.c-gen-ai__loading {
  .e-bolt-list {
    flex-flow: column;

    .c-gen-ai__icon-animation {
      display: flex;
      align-items: center;
      gap: var(--bolt-spacing-x-small);
      opacity: 0.2;
      transition: opacity var(--bolt-transition);

      &.c-gen-ai__icon-animation--start {
        opacity: 1;
      }
    }
  }

  svg {
    min-width: 47px;
  }
}

.c-gen-ai__card {
  @include core.bolt-mq(medium) {
    @include core.bolt-shadow('level-20');
    position: relative;
    padding: var(--bolt-spacing-x-large) var(--bolt-spacing-y-large);
    border-radius: core.bolt-border-radius(small);
  }

  bolt-grid {
    text-align: left;

    @include core.bolt-mq($until: xlarge) {
      text-align: center;

      .e-bolt-list--display-horizontal {
        justify-content: center;
      }
    }
  }

  button.e-bolt-button:focus,
  button.e-bolt-button:active:not(:disabled) {
    outline-color: #681fc3;
  }

  .c-bolt-input:focus {
    border-color: #681fc3 !important;
  }

  .c-gen-ai__icon {
    position: absolute;
    top: -37px;
    right: -39px;
    @include core.bolt-mq($until: medium) {
      display: none;
    }
  }
}

.c-gen-ai__ribbon-title {
  position: relative;
  width: 105%;
  margin-bottom: var(--bolt-spacing-y-xsmall);
  padding: var(--bolt-spacing-x-small) var(--bolt-spacing-y-medium);
  color: var(--bolt-color-white);
  background-color: var(--bolt-color-navy-light);
  clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
}

.c-gen-ai__ribbon-title ~ .e-bolt-list {
  padding: var(--bolt-spacing-x-xsmall) var(--bolt-spacing-y-xsmall);
  border: 1px solid var(--bolt-color-gray-light);
  border-radius: var(--bolt-spacing-x-xxsmall);
}

.c-gen-ai-section {
  .c-bolt-info-section,
  .c-bolt-info-section__content {
    border: none;
    box-shadow: none;
  }

  .c-bolt-info-section {
    border-top: 1px solid var(--bolt-color-gray-light);
  }

  .c-bolt-info-section__headline,
  .c-bolt-info-section__content {
    padding-right: 0;
    padding-left: 0;
  }

  .c-bolt-info-section__headline {
    margin-top: calc(var(--bolt-spacing-y-small) * 2);
  }

  .c-bolt-info-section__content {
    padding-top: 0;
  }

  .c-bolt-info-section__headline-icon {
    margin-right: var(--bolt-spacing-x-small);
  }

  .e-bolt-list {
    margin: 0 0 var(--bolt-spacing-y-xsmall) 0;

    .e-bolt-type {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: var(--bolt-spacing-x-xsmall);
      align-items: center;
    }
  }

  bolt-grid bolt-grid-item:first-of-type .c-gen-ai__ribbon-title {
    color: var(--bolt-color-white);
    background-color: var(--bolt-color-navy);
    clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 0% 100%, 0% 50%, 0% 0%);
  }

  bolt-grid bolt-grid-item:last-of-type .c-gen-ai__ribbon-title {
    width: 100%;
    clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);
    color: var(--bolt-color-white);
    background-color: var(--bolt-color-success);
  }
}

.c-gen-ai__icon-animation {
  @keyframes glow-and-blink {
    0% {
      opacity: 1;
      filter: brightness(100%);
    }

    50% {
      opacity: 0;
      filter: brightness(200%);
    }

    100% {
      opacity: 1;
      filter: brightness(100%);
    }
  }

  #star-right-top {
    animation: none 1.75s ease-in-out infinite;
  }

  #star-left-bottom {
    animation: none 1.75s ease-in-out 1s infinite;
  }
}

.c-gen-ai__icon-animation--start {
  #star-right-top {
    animation-name: glow-and-blink;
  }

  #star-left-bottom {
    animation-name: glow-and-blink;
  }
}

// Should we stop blinking after the list item becomes visible (opacity: 1;)?
.c-gen-ai__icon-animation--stop {
  #star-right-top {
    opacity: 1;
    filter: brightness(100%);
    animation: none;
  }

  #star-left-bottom {
    opacity: 1;
    filter: brightness(100%);
    animation: none;
  }
}
