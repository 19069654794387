/* ------------------------------------ *\
   Font-size System (CSS Custom Props)
\* ------------------------------------ */

@use 'sass:list';
@use '@bolt/core' as core;

/**
 * Generate global typography variables
 *
 * 1. Maps are kept in core/settings.
 * 2. In order for the following to work, font-size must be defined in the <html>:
 * html { font-size: var(--bolt-font-size); }
 */
:root {
  // Base tokens are for public use and user overrides
  // Note: user can customize these to generate a different typography system, such as Japanese typography.
  --bolt-type-font-size: 87.5%; // 14px

  @include core.bolt-mq(xsmall) {
    --bolt-type-font-size: 93.75%; // 15px
  }

  @include core.bolt-mq(xlarge) {
    --bolt-type-font-size: 100%; // 16px
  }

  @each $family in core.$bolt-type-font-family-system {
    $family-name: list.nth($family, 1);
    $family-value: list.nth($family, 2);

    @if $family-name == code or $family-name == fallback {
      --bolt-type-font-family-#{$family-name}: #{$family-value};
    } @else {
      --bolt-type-font-family-#{$family-name}: #{$family-value},
        var(--bolt-type-font-family-fallback);
    }
  }

  @each $size in core.$bolt-type-font-size-system {
    $size-name: list.nth($size, 1);
    $size-value: list.nth($size, 2);

    --bolt-type-font-size-#{$size-name}: #{$size-value};
  }

  @each $weight in core.$bolt-type-font-weight-system {
    $weight-name: list.nth($weight, 1);
    $weight-value: list.nth($weight, 2);

    --bolt-type-font-weight-#{$weight-name}: #{$weight-value};
  }

  @each $height in core.$bolt-type-line-height-system {
    $height-name: list.nth($height, 1);
    $height-value: list.nth($height, 2);

    --bolt-type-line-height-#{$height-name}: #{$height-value};
  }

  @each $height in core.$bolt-type-line-height-multiplier-system {
    $height-name: list.nth($height, 1);
    $height-value: list.nth($height, 2);

    --bolt-type-line-height-multiplier-#{$height-name}: #{$height-value};
  }

  @each $spacing in core.$bolt-type-letter-spacing-system {
    $spacing-name: list.nth($spacing, 1);
    $spacing-value: list.nth($spacing, 2);

    --bolt-type-letter-spacing-#{$spacing-name}: #{$spacing-value};
  }

  // Variables for Lora font
  @each $family in core.$bolt-type-font-family-system-lora {
    $family-name: list.nth($family, 1);
    $family-value: list.nth($family, 2);

    --bolt-type-font-family-#{$family-name}: #{$family-value},
      var(--bolt-type-font-family-fallback);
  }

  @each $size in core.$bolt-type-font-size-system-lora {
    $size-name: list.nth($size, 1);
    $size-value: list.nth($size, 2);

    --bolt-type-font-size-#{$size-name}--lora: #{$size-value};
  }

  @each $weight in core.$bolt-type-font-weight-system-lora {
    $weight-name: list.nth($weight, 1);
    $weight-value: list.nth($weight, 2);

    --bolt-type-font-weight-#{$weight-name}--lora: #{$weight-value};
  }

  // Variables for Chakra Petch font
  @each $family in core.$bolt-type-font-family-system-chakrapetch {
    $family-name: nth($family, 1);
    $family-value: nth($family, 2);

    --bolt-type-font-family-#{$family-name}: #{$family-value},
      var(--bolt-type-font-family-fallback);
  }
}
