// Light Syntax

.c-bolt-code-snippet[data-mode='light'] {
  // Redefine vars that text link styles are dependent on.
  --m-bolt-text: var(--bolt-color-black);
  --m-bolt-headline: var(--bolt-color-navy);
  --m-bolt-link: var(--bolt-color-navy-light);

  border: 1px solid var(--bolt-color-white);
  box-shadow: 0 0 6px rgba(bolt-color(gray, light), 0.8); // Sass function is used due to transparency needed.

  &,
  code,
  pre {
    color: var(--bolt-color-black);
    background: var(--bolt-color-gray-xlight);
  }

  pre::-moz-selection,
  pre ::-moz-selection,
  code::-moz-selection,
  code ::-moz-selection {
    background: var(--bolt-color-navy-xlight);
  }

  pre::selection,
  pre ::selection,
  code::selection,
  code ::selection {
    background: var(--bolt-color-navy-xlight);
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: slategray;
  }

  .token.punctuation {
    color: #999;
  }

  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #905;
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: green;
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: #9a6e3a;
    background: hsla(0, 0%, 100%, 0.5);
  }

  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: #07a;
  }

  .token.function,
  .token.class-name {
    color: #dd4a68;
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: #e90;
  }

  .line-numbers-rows > span:before {
    color: slategray;
  }
}
