/* ------------------------------------ *\
   Tooltip
\* ------------------------------------ */

@import '@bolt/core';
// For base Tippy.js styles, @see packages/global/styles/08-vendor/_vendor-tippy.scss

/**
 * Register custom element
 */
//  Note: this does not actually render to the Shadow DOM so :host rule not required
bolt-tooltip {
  // @TODO: we're using modal here to resolve a bug, this will need to be refactored. [Ticket: DS-525]
  --c-bolt-tooltip-z-index: #{bolt-z-index('modal')};
  display: inline;

  // This element is copied by Tippy and appended to the document.body
  [slot='content'] {
    display: none;
  }

  // Plain text trigger gets underlined
  &[dotted] {
    cursor: help;
    border-bottom: 1px dotted var(--m-bolt-neutral);
  }
}

/**
 * Content container
 */
.c-bolt-tooltip__content {
  display: block;
  text-align: start;
  white-space: nowrap;
}

.c-bolt-tooltip__content--text-wrap {
  width: 150px;
  white-space: normal;
  hyphens: auto;
}
