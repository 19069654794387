/* ------------------------------------ *\
  Floating Action Buttons
\* ------------------------------------ */

@use '@bolt/core' as core;

$_bolt-fab-list-gap: var(--bolt-spacing-y-xsmall);

.c-bolt-floating-action-buttons {
  --c-bolt-floating-action-buttons-offset-x: var(--bolt-spacing-x-small);
  --c-bolt-floating-action-buttons-offset-y: var(--bolt-spacing-y-small);

  visibility: visible;
  position: fixed;
  inset-inline-end: var(--c-bolt-floating-action-buttons-offset-x);
  bottom: var(--c-bolt-floating-action-buttons-offset-y);
  z-index: core.bolt-z-index('fab');
  transition: opacity var(--bolt-transition), visibility var(--bolt-transition);
  transition-delay: var(
    --bolt-transition-timing
  ); // Allow children elements to transition first, and then fade in the entire container.

  &.c-bolt-floating-action-buttons--hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.c-bolt-floating-action-buttons__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $_bolt-fab-list-gap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-bolt-floating-action-buttons__list-item {
  visibility: visible;
  opacity: 1;
  margin: 0;
  padding: 0;
  transition: opacity var(--bolt-transition), visibility var(--bolt-transition),
    transform var(--bolt-transition);

  &.c-bolt-floating-action-buttons__list-item--hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.c-bolt-floating-action-buttons__list-item--has-children {
  display: flex;
  flex-direction: column-reverse;

  // Animate "more icon" and "close icon" in toggle button
  .e-bolt-button[aria-expanded] {
    z-index: 1; // Raises the toggle button's stacking context higher than children list.
    > * {
      transition: opacity var(--bolt-transition),
        transform var(--bolt-transition);
    }
  }

  .e-bolt-button[aria-expanded='true'] {
    > *:nth-child(1) {
      visibility: hidden;
      opacity: 0;
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }

    > *:nth-child(2) {
      visibility: visible;
      opacity: 1;
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
  }

  .e-bolt-button[aria-expanded='false'] {
    > *:nth-child(1) {
      visibility: visible;
      opacity: 1;
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }

    > *:nth-child(2) {
      visibility: hidden;
      opacity: 0;
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }

  // Animate children list
  .c-bolt-floating-action-buttons__list {
    opacity: 1;
    max-height: 50vh;
    transition: max-height calc(var(--bolt-transition-timing) * 2)
        var(--bolt-transition-ease),
      opacity calc(var(--bolt-transition-timing) * 2)
        var(--bolt-transition-ease);
  }

  .c-bolt-floating-action-buttons__list--hidden {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    margin: 0;
    transition: max-height var(--bolt-transition);
  }

  .c-bolt-floating-action-buttons__list-item {
    transform: translate3d(0, 0, 0);

    &:first-of-type {
      margin-top: $_bolt-fab-list-gap;
    }

    &:last-of-type {
      margin-bottom: calc(#{$_bolt-fab-list-gap} * 2);
    }
  }

  .c-bolt-floating-action-buttons__list-item--hidden {
    transform: translate3d(0, 100%, 0);
  }
}

// Remove transitions when @media (prefers-reduced-motion) {} is true.
@media (prefers-reduced-motion) {
  .c-bolt-floating-action-buttons,
  .c-bolt-floating-action-buttons * {
    transition: none !important;
  }
}
