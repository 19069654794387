/* ------------------------------------ *\
  #HEIGHT UTILITIES
\* ------------------------------------ */

@use 'sass:map';
@use 'sass:list';
@use '@bolt/core' as core;
@use '@bolt/tokens' as tokens;

$bolt-height-props: map.get(map.get(tokens.$tokens, height), bolt);

// Height
@mixin bolt-height-utils($breakpoint: null) {
  @each $name, $value in $bolt-height-props {
    .u-bolt-height-#{$name}#{$breakpoint} {
      height: $value !important;
    }
  }
}

@include bolt-height-utils;

// Loop over our breakpoints
@each $breakpoint in core.$bolt-breakpoints {
  $breakpointName: list.nth($breakpoint, 1);
  @include core.bolt-mq($breakpointName) {
    @include bolt-height-utils(\@#{$breakpointName});
  }
}

// Min height
@mixin bolt-min-height-utils($breakpoint: null) {
  @each $name, $value in $bolt-height-props {
    .u-bolt-min-height-#{$name}#{$breakpoint} {
      min-height: $value !important;
    }
  }
}

@include bolt-min-height-utils;

// Loop over our breakpoints
@each $breakpoint in core.$bolt-breakpoints {
  $breakpointName: list.nth($breakpoint, 1);
  @include core.bolt-mq($breakpointName) {
    @include bolt-min-height-utils(\@#{$breakpointName});
  }
}
