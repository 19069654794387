/* ------------------------------------ *\
   Wrapper
\* ------------------------------------ */

@use '@bolt/core' as core;

$bolt-wrapper--max-width: core.bolt-breakpoint(xxlarge);

.e-bolt-wrapper {
  display: block;
  max-width: $bolt-wrapper--max-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: core.$bolt-wrapper-padding;
  padding-left: core.$bolt-wrapper-padding;

  @include core.bolt-mq(medium) {
    padding-right: core.$bolt-wrapper-padding-at-medium-bp;
    padding-left: core.$bolt-wrapper-padding-at-medium-bp;
  }
}
