/* ------------------------------------ *\
   Accordion
\* ------------------------------------ */

// Dev Notes
// 1. 'js-enabled' class added by accordion-js library

@import '@bolt/core';

$bolt-accordion-border-width: $bolt-border-width;
$bolt-accordion-border-style: $bolt-border-style;
$bolt-accordion-border-color: var(--m-bolt-border);
$bolt-accordion-border-radius: bolt-border-radius(small);
$bolt-accordion-icon-size: 1.4rem;

.c-bolt-accordion {
  display: block;

  &:not(:last-child) {
    margin-bottom: var(--bolt-spacing-y-medium);
  }

  // box-shadow
  &.c-bolt-accordion--box-shadow {
    @include bolt-shadow('level-20', false, var(--bolt-color-gray-dark));
    border-radius: $bolt-accordion-border-radius;
    border-width: $bolt-accordion-border-width;
    border-style: $bolt-accordion-border-style;
    border-color: $bolt-accordion-border-color;
    background-color: var(--m-bolt-bg);
  }

  &.c-bolt-accordion--icon-valign-top {
    .c-bolt-accordion-item__trigger-icon {
      align-self: start;
    }
  }

  // separator
  &.c-bolt-accordion--separator {
    .c-bolt-accordion-item {
      border-top-color: $bolt-accordion-border-color;
      border-top-style: $bolt-accordion-border-style;
      border-top-width: $bolt-accordion-border-width;

      &:last-of-type {
        border-bottom-color: $bolt-accordion-border-color;
        border-bottom-style: $bolt-accordion-border-style;
        border-bottom-width: $bolt-accordion-border-width;
      }
    }

    &.c-bolt-accordion--box-shadow {
      .c-bolt-accordion-item {
        &:first-of-type {
          border-top: none;
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  // Spacing
  @each $size in $bolt-spacing-multiplier-system {
    $size-name: nth($size, 1);

    @if (
      $size-name !=
        'xxsmall' and
        $size-name !=
        'xlarge' and
        $size-name !=
        'xxlarge'
    ) {
      &.c-bolt-accordion--spacing-#{$size-name} {
        .c-bolt-accordion-item__trigger {
          padding: calc(var(--bolt-spacing-y-#{$size-name}) / 2)
            var(--bolt-spacing-x-#{$size-name});
        }

        .c-bolt-accordion-item__content-inner {
          padding: calc(var(--bolt-spacing-y-#{$size-name}) / 2)
            var(--bolt-spacing-x-#{$size-name})
            var(--bolt-spacing-y-#{$size-name})
            var(--bolt-spacing-x-#{$size-name});
        }
      }
    }
  }
}

// active
.c-bolt-accordion-item {
  @keyframes revealContent {
    100% {
      visibility: visible;
      height: auto;
    }
  }

  // avoid content flash on page load
  &.js-enabled .c-bolt-accordion-item__content {
    visibility: hidden;
  } // [1]

  // allow content to be revealed on browsers that do not allow js
  &:not(.js-enabled) .c-bolt-accordion-item__content {
    visibility: hidden;
    height: 0;
    animation: revealContent 2s;
    animation-fill-mode: forwards;
  } // [1]

  // Important: is-active must come after above js-enabled rulesets
  &.is-active {
    .c-bolt-accordion-item__content {
      visibility: visible;
    }

    > .c-bolt-accordion-item__trigger .c-bolt-accordion-item__trigger-icon {
      transform: rotate(180deg);
    }
  }
}

// item styles
.c-bolt-accordion-item__content {
  overflow: hidden;
  transition: height var(--bolt-transition), visibility var(--bolt-transition);

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

// label
.c-bolt-accordion-item__trigger {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  width: 100%;
  font-family: var(--bolt-type-font-family-body);
  color: var(--m-bolt-text);
  line-height: var(--bolt-type-line-height-medium);
  text-align: left;
  cursor: pointer;
  border: 0;
  background: transparent;

  &:before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-color: var(--m-bolt-primary);
    transition: opacity calc(var(--bolt-transition-timing) / 2)
      var(--bolt-transition-ease);
  }

  &:hover:before {
    opacity: 0.05;
  }
}

.c-bolt-accordion-item__trigger-content {
  flex: 1;
  padding-right: var(--bolt-spacing-x-xsmall);
}

// icon
.c-bolt-accordion-item__trigger-icon {
  display: inline-block;
  align-self: center; // align center by default
  min-width: 1.5rem;
  color: var(--m-bolt-link);
  transition: transform var(--bolt-transition);

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

// inactive
.c-bolt-accordion-item[data-bolt-inactive] {
  .c-bolt-accordion-item__trigger {
    cursor: auto;

    &:hover:before {
      opacity: 0;
    }
  }

  > .c-bolt-accordion-item__content {
    visibility: hidden;
    height: 0;
    animation: none;
  }
}
