@use '@bolt/core' as core;
@forward 'device-viewer--iphone8';
@forward 'device-viewer--ipad';
@forward 'device-viewer--macbook';

bolt-device-viewer {
  display: block;
}

.c-bolt-device-viewer {
  box-sizing: content-box; // Required for now till device viewer updated to be fluid in size.
  position: relative;
  transform: translate3d(0, 0, 0); // Ensures correct z-index positioning
  height: auto; // Based on inner contents + padding;
  margin: 0 auto;
}

.c-bolt-device-viewer__inner {
  display: block;
  position: relative;
  z-index: core.bolt-z-index('content');

  &:before,
  &:after {
    position: absolute;
    pointer-events: none; // So scrolling works / mouse doesn't get blocked.
  }

  &:before {
    content: '';
  }
}

.c-bolt-device-viewer__screen {
  @include core.bolt-shadow('level-10');
  display: block;
  position: relative;
  z-index: core.bolt-z-index(
    'contentTop'
  ); // Must be higher than the z-index of the :before layer on the base component
  width: 100%;
  height: 0;
  overflow: hidden; // Unfortunately zooming + scrolling is very, very tricky. Forcing this for now.
  border-radius: 1px;
  background-color: var(--bolt-color-black);
  perspective: 1000px;
}

.c-bolt-device-viewer__home {
  position: absolute;
  z-index: (core.bolt-z-index('contentTop') + 5);
  border-radius: 50%;
}

.c-bolt-device-viewer__camera {
  position: absolute;
  z-index: (core.bolt-z-index('contentTop') + 10);
  border-radius: 100%;
}

.c-bolt-device-viewer__top-bar,
.c-bolt-device-viewer__bottom-bar {
  display: block;
  position: absolute;
  z-index: core.bolt-z-index('background') - 5; // Since we want this layer pushed back behind most, but not all, layers
  width: 100%;
}
