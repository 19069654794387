/* --------------------------------------------- *\
  GENERIC - SAMPLE FONTS THAT MAY HAVE BEEN ADDED
\* --------------------------------------------- */

@font-face {
  font-family: CustomFont;
  src: url('~@bolt/global/fonts/custom-fonts/custom-font-example-300.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: CustomFont;
  src: url('~@bolt/global/fonts/custom-fonts/custom-font-example-300.ttf')
    format('ttf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: CustomFont;
  src: url('~@bolt/global/fonts/custom-fonts/custom-font-example-400.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: CustomFont;
  src: url('~@bolt/global/fonts/custom-fonts/custom-font-example-400.ttf')
    format('ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: CustomFont;
  src: url('~@bolt/global/fonts/custom-fonts/custom-font-example-700.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: CustomFont;
  src: url('~@bolt/global/fonts/custom-fonts/custom-font-example-700.ttf')
    format('ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
