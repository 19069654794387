@use 'sass:list';
@use '@bolt/core' as core;

/* ------------------------------------ *\
   List Item
\* ------------------------------------ */

// Variables
$bolt-list-item-border-width: core.$bolt-border-width;
$bolt-list-item-border-color: var(--m-bolt-border);

// Item base styles
.c-bolt-list-item {
  display: block;
  margin: 0;
  padding: 0;
}

// Spacing Prop
@each $size in core.$bolt-spacing-multiplier-system {
  $size-name: list.nth($size, 1);

  @if ($size-name != 'xxlarge') {
    .c-bolt-list-item--spacing-#{$size-name} {
      margin-bottom: var(--bolt-spacing-y-#{$size-name});
      margin-inline-start: var(--bolt-spacing-x-#{$size-name});

      &.c-bolt-list-item--inset {
        margin-bottom: 0;
        margin-inline-start: 0;
        padding: var(--bolt-spacing-y-#{$size-name})
          var(--bolt-spacing-x-#{$size-name});
      }
    }
  }
}

// Separator Prop
$bolt-list-separator-styles: solid, dashed;

@each $separator-style in $bolt-list-separator-styles {
  .c-bolt-list-item--separator-#{$separator-style} {
    border-width: 0;
    border-style: #{$separator-style};
    border-color: $bolt-list-item-border-color; // @todo: remove once we drop IE11 support
    border-color: var(
      --bolt-list-item-border-color,
      $bolt-list-item-border-color
    );
  }
}

[class*='c-bolt-list-item--separator'] {
  &.c-bolt-list-item--display-block {
    &:not(.c-bolt-list-item--last-item) {
      border-bottom-width: $bolt-list-item-border-width;
    }
  }

  &.c-bolt-list-item--display-inline,
  &.c-bolt-list-item--display-flex {
    &:not(.c-bolt-list-item--last-item) {
      border-inline-end-width: $bolt-list-item-border-width;
    }
  }

  @each $breakpoint in core.$bolt-breakpoints {
    $breakpoint-name: list.nth($breakpoint, 1);

    @if (
      $breakpoint-name !=
        'large' and
        $breakpoint-name !=
        'xlarge' and
        $breakpoint-name !=
        'xxlarge'
    ) {
      &.c-bolt-list-item--display-inline\@#{$breakpoint-name} {
        &:not(.c-bolt-list-item--last-item) {
          border-bottom-width: $bolt-list-item-border-width;

          @include core.bolt-mq($breakpoint-name) {
            border-inline-end-width: $bolt-list-item-border-width;
            border-bottom-width: 0;
          }
        }
      }
    }
  }
}

// Specific settings for when the separator prop interacts with other props. This is where things get really crazy.
[class*='c-bolt-list-item--separator'] {
  @each $size in core.$bolt-spacing-multiplier-system {
    $size-name: list.nth($size, 1);

    @if ($size-name != 'xxlarge') {
      &.c-bolt-list-item--spacing-#{$size-name} {
        &:not(.c-bolt-list-item--last-item) {
          &.c-bolt-list-item--display-block {
            padding-bottom: var(--bolt-spacing-y-#{$size-name});
          }

          &.c-bolt-list-item--display-inline,
          &.c-bolt-list-item--display-flex {
            padding-inline-end: var(--bolt-spacing-x-#{$size-name});
          }
        }
      }

      &:not(.c-bolt-list-item--inset) {
        @each $breakpoint in core.$bolt-breakpoints {
          $breakpoint-name: list.nth($breakpoint, 1);

          @if (
            $breakpoint-name !=
              'large' and
              $breakpoint-name !=
              'xlarge' and
              $breakpoint-name !=
              'xxlarge'
          ) {
            &.c-bolt-list-item--spacing-#{$size-name} {
              &.c-bolt-list-item--display-inline\@#{$breakpoint-name} {
                padding-bottom: var(--bolt-spacing-y-#{$size-name});

                @include core.bolt-mq($breakpoint-name) {
                  padding-inline-end: var(--bolt-spacing-x-#{$size-name});
                  padding-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
