/* ------------------------------------ *\
   #BOLT TYPEAHEAD COMPONENT
\* ------------------------------------ */

@use '@bolt/core' as core;

$bolt-clear-button-size: 1.7rem;
$bolt-clear-button-size-at-med: 1.4rem;
$bolt-typeahead-button-width: calc(var(--bolt-spacing-x-medium) + 0.75rem);
$bolt-typeahead-border-width: core.$bolt-border-width;
$bolt-typeahead-border-style: core.$bolt-border-style;
$bolt-typeahead-border-radius: 6px;
$bolt-typeahead-placeholder-color: var(--bolt-color-gray);
$bolt-typeahead-result-highlight-color: var(--bolt-color-gray-xlight);

bolt-typeahead {
  display: flex;
  align-self: stretch;
  position: relative;
  text-align: start;
}

:host {
  position: relative;
}

bolt-autosuggest {
  width: 100%;
}

.c-bolt-typeahead {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: $bolt-typeahead-border-radius;
}

.c-bolt-typeahead__label {
  position: relative;
}

.c-bolt-typeahead__input {
  box-sizing: border-box; // needed when rendered to the Shadow DOM
  -webkit-appearance: none; // removes default styling (ex. heavy box shadow) in Safari
  width: 100%;
  max-width: 100%;

  // see button styles for more details
  min-height: 44px;
  padding: calc(var(--bolt-spacing-y-medium) / 2 - 1px)
    calc(var(--bolt-spacing-x-medium) + 1.4rem); // Adjust the padding left to leave enough room for the search button
  font-size: 16px; // prevent zooming in on mobile
  color: var(--bolt-color-black);
  border-radius: $bolt-typeahead-border-radius;
  border-width: $bolt-typeahead-border-width;
  border-style: $bolt-typeahead-border-style;
  border-color: var(--bolt-color-navy-xlight);
  background-color: var(--bolt-color-white);
  transition: all 0.1s ease;
  text-overflow: ellipsis;
  outline-offset: -3px;
  outline-width: 2px;

  @media all and (min-width: core.bolt-bp(medium)) {
    padding-inline-end: $bolt-clear-button-size-at-med;
  }

  @include core.bolt-mq(medium) {
    font-size: inherit;
  }

  &:focus {
    border-color: var(--bolt-color-navy-light);
    box-shadow: 0 0 0 2px var(--bolt-color-navy-xlight),
      inset 0 0 0 1px var(--bolt-color-navy-light);
    outline: 0;
  }

  &--open {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:disabled {
    color: var(--bolt-color-gray);
    cursor: not-allowed;
    background-color: var(--bolt-color-gray-light);
  }

  // Remove the native <input> clear button in IE 11 in lieu of JS-controlled clear button
  &::-ms-clear {
    display: none;
  }

  &::-webkit-input-placeholder,
  &::-moz-input-placeholder {
    transition: all 0.1s ease;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $bolt-typeahead-placeholder-color;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $bolt-typeahead-placeholder-color;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $bolt-typeahead-placeholder-color;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $bolt-typeahead-placeholder-color;
  }
}

.c-bolt-typeahead__menu {
  display: block;
  opacity: 0;
  position: absolute;
  top: calc(100% + 2px);
  inset-inline-end: 0;
  // Raise stacking context above the input. `contentTop` places it above elements later that may have z-index of 1.
  @include core.bolt-z-index(contentTop);
  width: 100%;
  min-width: 100%;
  max-height: 0;
  overflow: hidden;
  color: var(--bolt-color-black);
  text-transform: capitalize;
  border-bottom-right-radius: $bolt-typeahead-border-radius;
  border-bottom-left-radius: $bolt-typeahead-border-radius;
  box-shadow: var(--bolt-color-gray-light) 0px 15px 46px -10px;
  background-color: var(--bolt-color-white);
  transition: max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &.is-open {
    opacity: 1;
    overflow-y: auto;
    max-height: calc(100vh - #{$bolt-typeahead-button-width});
    max-height: calc(100vh - var(--typeahead-height));
  }
}

.c-bolt-typeahead__results {
  overflow: hidden;
  margin: 0;
  padding: var(--bolt-spacing-y-xsmall) 0;
  list-style: none;
  border-bottom-right-radius: $bolt-typeahead-border-radius;
  border-bottom-left-radius: $bolt-typeahead-border-radius;

  &:empty {
    max-height: 0;
    border-width: 0;
  }
}

.c-bolt-typeahead__result {
  overflow: hidden;
  color: inherit;
  transition: background-color 0.1s ease;

  &.has-cursor {
    background-color: $bolt-typeahead-result-highlight-color;
  }

  &:active,
  &:focus {
    background-color: var(--bolt-color-navy-xlight);
  }
}

.c-bolt-typeahead__result-highlight {
  font-weight: var(--bolt-type-font-weight-bold);
}

.c-bolt-typeahead__result-text {
  display: block;
  padding: calc(var(--bolt-spacing-y-medium) / 2 - 1px)
    var(--bolt-spacing-x-medium);
  padding-inline-start: $bolt-typeahead-button-width;
  font-family: var(--bolt-type-font-family-body);
  font-size: var(--bolt-type-font-size-xsmall);
  color: inherit;
  line-height: var(--bolt-type-line-height-xsmall);
  text-transform: initial; // fix for weird capitalization
  cursor: pointer;
}

.c-bolt-typeahead__wrapper {
  width: 100%;
}

.c-bolt-typeahead__input-wrapper {
  flex-shrink: 0;
  position: relative; // used for positioning search clear button in relation to the <input>
}

.c-bolt-typeahead__button {
  position: absolute;
  top: 0;
}

.c-bolt-typeahead__button--clear {
  display: none;
  inset-inline-end: 0;
}

.c-bolt-typeahead__button--submit {
  inset-inline-start: 0;
}

.c-bolt-typeahead__button.e-bolt-button--transparent {
  --e-bolt-button-text-color: var(
    --bolt-color-navy-light
  ); // Typeahead is not compatible with color themes. This overrides the Button element to not change with parent's color theme.
}

.c-bolt-typeahead__input[required]:valid ~ .c-bolt-typeahead__button--clear,
.c-bolt-typeahead__button--clear.is-visible {
  display: block;
}
