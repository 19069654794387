/* Scrollbar */
.c-bolt-carousel__scrollbar {
  position: relative;
  margin-top: var(--bolt-spacing-y-small);
  border-radius: bolt-border-radius(large);
  box-shadow: inset 0 0 6px var(--m-bolt-border);
  transition: opacity 0.2s ease;
  -ms-touch-action: none;

  .c-bolt-carousel--horizontal > & {
    @include bolt-z-index(contentTop);

    position: relative;
    bottom: 0;
    width: 100%;
    height: var(--bolt-spacing-y-xsmall);
  }
}

.c-bolt-carousel__scrollbar-drag {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: bolt-border-radius(large);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(63, 63, 68, 0.15) 100%
  );
}

.c-bolt-carousel__scrollbar-cursor-drag {
  cursor: move;
}

.c-bolt-carousel__scrollbar-lock {
  display: none;
}

// auto-hide the scrollbar  when both buttons are hidden
.c-bolt-carousel__btn--hidden
  + .c-bolt-carousel__btn--hidden
  ~ .c-bolt-carousel__scrollbar {
  opacity: 0;
}
