/* ------------------------------------ *\
   Light and Dark Mode System

   @todo: `.t-bolt-theme` classes are for backward compatibility and the `bolt-card-replacement` selectors are for the poorly built card component. They can be removed once we completely deprecate themes.
\* ------------------------------------ */
@use '@bolt/core' as core;

:root,
// Legacy themes
.t-bolt-light,
.t-bolt-xlight,
// New themes
.t-bolt-white,
.t-bolt-gray-xlight,
.t-bolt-yellow {
  @include core.bolt-base-light-mode;
}

[data-bolt-mode='dark'],
// Legacy themes
.t-bolt-dark,
.t-bolt-xdark,
.t-bolt-xxdark,
// New themes
.t-bolt-black,
.t-bolt-navy-dark,
.t-bolt-navy,
.t-bolt-navy-light,
.t-bolt-teal,
.t-bolt-orange,
.t-bolt-pink,
.t-bolt-wine,
.t-bolt-berry,
.t-bolt-violet {
  @include core.bolt-base-dark-mode;
}

.t-bolt-light,
bolt-card-replacement[theme='light'] {
  --m-bolt-bg: var(--bolt-color-gray-xlight);
  --m-bolt-bg-highlight: var(--bolt-color-gray-xlight);
  --m-bolt-bg-lowlight: var(--bolt-color-gray-light);
}

.t-bolt-dark,
bolt-card-replacement[theme='dark'] {
  --m-bolt-bg: var(--bolt-color-navy);
  --m-bolt-bg-highlight: var(--bolt-color-navy-light);
  --m-bolt-bg-lowlight: var(--bolt-color-navy);
}

.t-bolt-xdark,
bolt-card-replacement[theme='xdark'] {
  --m-bolt-bg: var(--bolt-color-navy-dark);
  --m-bolt-bg-highlight: var(--bolt-color-navy);
  --m-bolt-bg-lowlight: var(--bolt-color-navy-xdark);
}

.t-bolt-xxdark {
  --m-bolt-bg: black;
  --m-bolt-bg-highlight: var(--bolt-color-gray-dark);
  --m-bolt-bg-lowlight: var(--bolt-color-black);
}

// New themes
.t-bolt-white {
  --m-bolt-bg: var(--bolt-color-white);
}

.t-bolt-gray-xlight {
  --m-bolt-bg: var(--bolt-color-gray-xlight);
  --m-bolt-bg-highlight: var(--bolt-color-gray-xlight);
  --m-bolt-bg-lowlight: var(--bolt-color-gray-light);
}

.t-bolt-orange {
  --m-bolt-bg: var(--bolt-color-orange);
  --m-bolt-bg-highlight: var(--bolt-color-orange);
  --m-bolt-bg-lowlight: var(--bolt-color-orange-dark);
}

.t-bolt-yellow {
  --m-bolt-bg: var(--bolt-color-yellow);
  --m-bolt-bg-highlight: var(--bolt-color-yellow-light);
  --m-bolt-bg-lowlight: var(--bolt-color-yellow);
  --m-bolt-link: var(--bolt-color-black);
  --m-bolt-primary: var(--bolt-color-navy-dark);
  --m-bolt-tertiary: hsla(220.4, 80%, 15%, 0.08);
}

.t-bolt-pink {
  --m-bolt-bg: var(--bolt-color-pink);
  --m-bolt-bg-highlight: var(--bolt-color-pink);
  --m-bolt-bg-lowlight: var(--bolt-color-berry);
}

.t-bolt-black {
  --m-bolt-bg: var(--bolt-color-black);
  --m-bolt-bg-highlight: var(--bolt-color-gray-dark);
  --m-bolt-bg-lowlight: var(--bolt-color-black);
}

.t-bolt-navy-dark {
  --m-bolt-bg: var(--bolt-color-navy-dark);
  --m-bolt-bg-highlight: var(--bolt-color-navy);
  --m-bolt-bg-lowlight: var(--bolt-color-navy-xdark);
}

.t-bolt-navy {
  --m-bolt-bg: var(--bolt-color-navy);
  --m-bolt-bg-highlight: var(--bolt-color-navy-light);
  --m-bolt-bg-lowlight: var(--bolt-color-navy);
}

.t-bolt-navy-light {
  --m-bolt-bg: var(--bolt-color-navy-light);
  --m-bolt-bg-highlight: var(--bolt-color-navy-xlight);
  --m-bolt-bg-lowlight: var(--bolt-color-navy-light);
}

.t-bolt-teal {
  --m-bolt-bg: var(--bolt-color-teal);
  --m-bolt-bg-highlight: var(--bolt-color-teal);
  --m-bolt-bg-lowlight: var(--bolt-color-teal-xdark);
}

.t-bolt-wine {
  --m-bolt-bg: var(--bolt-color-wine);
  --m-bolt-bg-highlight: var(--bolt-color-wine);
  --m-bolt-bg-lowlight: var(--bolt-color-black);
}

.t-bolt-berry {
  --m-bolt-bg: var(--bolt-color-berry);
  --m-bolt-bg-highlight: var(--bolt-color-berry);
  --m-bolt-bg-lowlight: var(--bolt-color-wine);
}

.t-bolt-violet {
  --m-bolt-bg: var(--bolt-color-violet);
  --m-bolt-bg-highlight: var(--bolt-color-violet);
  --m-bolt-bg-lowlight: var(--bolt-color-navy);
}

// Redefine light themes within dark mode
[data-bolt-mode='dark'] {
  // Legacy themes
  .t-bolt-xlight {
    @include core.bolt-base-dark-mode;
    --m-bolt-bg: var(--bolt-color-navy-xdark);
    --m-bolt-bg-highlight: var(--bolt-color-navy-xdark);
    --m-bolt-bg-lowlight: var(--bolt-color-black);
  }

  .t-bolt-light {
    @include core.bolt-base-dark-mode;
    --m-bolt-bg: var(--bolt-color-gray-xdark);
    --m-bolt-bg-highlight: var(--bolt-color-gray-dark);
    --m-bolt-bg-lowlight: var(--bolt-color-gray-xdark);
  }

  // New themes
  .t-bolt-white {
    @include core.bolt-base-dark-mode;
    --m-bolt-bg: var(--bolt-color-navy-xdark);
    --m-bolt-bg-highlight: var(--bolt-color-navy-xdark);
    --m-bolt-bg-lowlight: var(--bolt-color-black);
  }

  .t-bolt-gray-xlight {
    @include core.bolt-base-dark-mode;
    --m-bolt-bg: var(--bolt-color-gray-xdark);
    --m-bolt-bg-highlight: var(--bolt-color-gray-dark);
    --m-bolt-bg-lowlight: var(--bolt-color-gray-xdark);
  }

  .t-bolt-orange {
    @include core.bolt-base-dark-mode;
    --m-bolt-bg: var(--bolt-color-orange-dark);
    --m-bolt-bg-highlight: var(--bolt-color-orange);
    --m-bolt-bg-lowlight: var(--bolt-color-orange-dark);
  }

  .t-bolt-yellow {
    @include core.bolt-base-dark-mode;
    --m-bolt-bg: var(--bolt-color-gray-dark);
    --m-bolt-bg-highlight: var(--bolt-color-gray-dark);
    --m-bolt-bg-lowlight: var(--bolt-color-gray-xdark);
  }

  .t-bolt-pink {
    @include core.bolt-base-dark-mode;
    --m-bolt-bg: var(--bolt-color-pink);
    --m-bolt-bg-highlight: var(--bolt-color-pink);
    --m-bolt-bg-lowlight: var(--bolt-color-berry);
  }
}

// Declare text color and bg color
// Dev note: add :root after bolt-theme() function usage is completely removed.
[data-bolt-mode],
[class^='t-bolt-'],
[class*=' t-bolt-'] {
  @include core.bolt-base-color-defaults;
}
