/* ------------------------------------ *\
  GENERIC - FONT STACKS
\* ------------------------------------ */

$bolt-font-stacks: (
  openSansLight: (
    fontFamily: 'Open Sans',
    style: normal,
    weight: 300,
    src: (
      woff2: '~@bolt/global/fonts/opensans/opensans-light.woff2',
      woff: '~@bolt/global/fonts/opensans/opensans-light.woff',
    ),
  ),
  openSansRegular: (
    fontFamily: 'Open Sans',
    style: normal,
    weight: 400,
    src: (
      woff2: '~@bolt/global/fonts/opensans/opensans-regular.woff2',
      woff: '~@bolt/global/fonts/opensans/opensans-regular.woff',
    ),
  ),
  openSansItalic: (
    fontFamily: 'Open Sans',
    style: italic,
    weight: 400,
    src: (
      woff2: '~@bolt/global/fonts/opensans/opensans-italic.woff2',
      woff: '~@bolt/global/fonts/opensans/opensans-italic.woff',
    ),
  ),
  openSansSemiBold: (
    fontFamily: 'Open Sans',
    style: normal,
    weight: 600,
    src: (
      woff2: '~@bolt/global/fonts/opensans/opensans-semibold.woff2',
      woff: '~@bolt/global/fonts/opensans/opensans-semibold.woff',
    ),
  ),
  openSansSemiBoldItalic: (
    fontFamily: 'Open Sans',
    style: italic,
    weight: 600,
    src: (
      woff2: '~@bolt/global/fonts/opensans/opensans-semibolditalic.woff2',
      woff: '~@bolt/global/fonts/opensans/opensans-semibolditalic.woff',
    ),
  ),
  openSansBold: (
    fontFamily: 'Open Sans',
    style: normal,
    weight: 700,
    src: (
      woff2: '~@bolt/global/fonts/opensans/opensans-bold.woff2',
      woff: '~@bolt/global/fonts/opensans/opensans-bold.woff',
    ),
  ),
  openSansBoldItalic: (
    fontFamily: 'Open Sans',
    style: italic,
    weight: 700,
    src: (
      woff2: '~@bolt/global/fonts/opensans/opensans-bolditalic.woff2',
      woff: '~@bolt/global/fonts/opensans/opensans-bolditalic.woff',
    ),
  ),
  openSansExtraBold: (
    fontFamily: 'Open Sans',
    style: normal,
    weight: 800,
    src: (
      woff2: '~@bolt/global/fonts/opensans/opensans-extrabold.woff2',
      woff: '~@bolt/global/fonts/opensans/opensans-extrabold.woff',
    ),
  ),
  openSansExtraBoldItalic: (
    fontFamily: 'Open Sans',
    style: italic,
    weight: 800,
    src: (
      woff2: '~@bolt/global/fonts/opensans/opensans-extrabolditalic.woff2',
      woff: '~@bolt/global/fonts/opensans/opensans-extrabolditalic.woff',
    ),
  ),
  loraBold: (
    fontFamily: 'Lora',
    style: normal,
    weight: 700,
    src: (
      woff2: '~@bolt/global/fonts/lora/lora-bold.woff2',
      woff: '~@bolt/global/fonts/lora/lora-bold.woff',
    ),
  ),
  loraBoldItalic: (
    fontFamily: 'Lora',
    style: italic,
    weight: 700,
    src: (
      woff2: '~@bolt/global/fonts/lora/lora-bolditalic.woff2',
      woff: '~@bolt/global/fonts/lora/lora-bolditalic.woff',
    ),
  ),
  loraMedium: (
    fontFamily: 'Lora',
    style: normal,
    weight: 500,
    src: (
      woff2: '~@bolt/global/fonts/lora/lora-medium.woff2',
      woff: '~@bolt/global/fonts/lora/lora-medium.woff',
    ),
  ),
  loraMediumItalic: (
    fontFamily: 'Lora',
    style: italic,
    weight: 500,
    src: (
      woff2: '~@bolt/global/fonts/lora/lora-mediumitalic.woff2',
      woff: '~@bolt/global/fonts/lora/lora-mediumitalic.woff',
    ),
  ),
  loraSemiBold: (
    fontFamily: 'Lora',
    style: normal,
    weight: 600,
    src: (
      woff2: '~@bolt/global/fonts/lora/lora-semibold.woff2',
      woff: '~@bolt/global/fonts/lora/lora-semibold.woff',
    ),
  ),
  loraSemiBoldItalic: (
    fontFamily: 'Lora',
    style: italic,
    weight: 600,
    src: (
      woff2: '~@bolt/global/fonts/lora/lora-semibolditalic.woff2',
      woff: '~@bolt/global/fonts/lora/lora-semibolditalic.woff',
    ),
  ),
  loraItalic: (
    fontFamily: 'Lora',
    style: italic,
    weight: 400,
    src: (
      woff2: '~@bolt/global/fonts/lora/lora-italic.woff2',
      woff: '~@bolt/global/fonts/lora/lora-italic.woff',
    ),
  ),
  loraRegular: (
    fontFamily: 'Lora',
    style: normal,
    weight: 400,
    src: (
      woff2: '~@bolt/global/fonts/lora/lora-regular.woff2',
      woff: '~@bolt/global/fonts/lora/lora-regular.woff',
    ),
  ),
  chakraPetchBold: (
    fontFamily: 'Chakra Petch',
    style: normal,
    weight: 700,
    src: (
      woff2: '~@bolt/global/fonts/chakrapetch/ChakraPetch-Bold.woff2',
      woff: '~@bolt/global/fonts/chakrapetch/ChakraPetch-Bold.woff',
    ),
  ),
  chakraPetchBoldItalic: (
    fontFamily: 'Chakra Petch',
    style: italic,
    weight: 700,
    src: (
      woff2: '~@bolt/global/fonts/chakrapetch/ChakraPetch-BoldItalic.woff2',
      woff: '~@bolt/global/fonts/chakrapetch/ChakraPetch-BoldItalic.woff',
    ),
  ),
  chakraPetchItalic: (
    fontFamily: 'Chakra Petch',
    style: italic,
    weight: 400,
    src: (
      woff2: '~@bolt/global/fonts/chakrapetch/ChakraPetch-Italic.woff2',
      woff: '~@bolt/global/fonts/chakrapetch/ChakraPetch-Italic.woff',
    ),
  ),
  chakraPetchLight: (
    fontFamily: 'Chakra Petch',
    style: normal,
    weight: 300,
    src: (
      woff2: '~@bolt/global/fonts/chakrapetch/ChakraPetch-Light.woff2',
      woff: '~@bolt/global/fonts/chakrapetch/ChakraPetch-Light.woff',
    ),
  ),
  chakraPetchLightItalic: (
    fontFamily: 'Chakra Petch',
    style: italic,
    weight: 300,
    src: (
      woff2: '~@bolt/global/fonts/chakrapetch/ChakraPetch-LightItalic.woff2',
      woff: '~@bolt/global/fonts/chakrapetch/ChakraPetch-LightItalic.woff',
    ),
  ),
  chakraPetchRegular: (
    fontFamily: 'Chakra Petch',
    style: normal,
    weight: 400,
    src: (
      woff2: '~@bolt/global/fonts/chakrapetch/ChakraPetch-Regular.woff2',
      woff: '~@bolt/global/fonts/chakrapetch/ChakraPetch-Regular.woff',
    ),
  ),
  chakraPetchSemiBold: (
    fontFamily: 'Chakra Petch',
    style: normal,
    weight: 600,
    src: (
      woff2: '~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBold.woff2',
      woff: '~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBold.woff',
    ),
  ),
  chakraPetchSemiBoldItalic: (
    fontFamily: 'Chakra Petch',
    style: italic,
    weight: 600,
    src: (
      woff2: '~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBoldItalic.woff2',
      woff: '~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBoldItalic.woff',
    ),
  ),
);

@each $fontName, $fontValue in $bolt-font-stacks {
  $fontSrc: null;
  $fontSources: map-get($fontValue, 'src');

  @each $ext, $path in $fontSources {
    $fontSrc: append($fontSrc, url('#{$path}') format(quote($ext)), comma);
  }

  @font-face {
    font-family: map-get($fontValue, 'fontFamily');
    font-style: map-get($fontValue, 'style');
    font-weight: map-get($fontValue, 'weight');
    src: $fontSrc;
    font-display: swap;
  }
}

@include export-data('typography/font-stacks.bolt.json', $bolt-font-stacks);
