/* ------------------------------------ *\
  Z INDEX MIXIN
  see `_settings-z-index.scss`
\* ------------------------------------ */

@use 'sass:map';
@use '@bolt/core' as core;

$indexes: map.get(core.$bolt-z-indexes, 'sets');

@each $key, $value in $indexes {
  .u-bolt-z-index--#{$key}, // DEPRECATED.  Utility classes with double dashes will be removed in the next major release.
  .u-bolt-z-index-#{$key} {
    @include core.bolt-z-index($key, $utility: true);
  }
}
