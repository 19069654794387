/**
  * @define o-bolt-wrapper
  */

@use '@bolt/core' as core;

$bolt-wrapper--max-width: core.bolt-breakpoint(xxlarge) !default;

.o-bolt-wrapper {
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-right: core.$bolt-wrapper-padding;
  padding-left: core.$bolt-wrapper-padding;

  @include core.bolt-mq(medium) {
    padding-right: core.$bolt-wrapper-padding-at-medium-bp;
    padding-left: core.$bolt-wrapper-padding-at-medium-bp;
  }
}

// Default wrapper component used virtually everywhere
.o-bolt-wrapper,
.o-bolt-wrapper--xxlarge {
  max-width: $bolt-wrapper--max-width;
}

// Used for handling ideal content line length
.o-bolt-wrapper--medium {
  max-width: core.bolt-breakpoint(medium);
  padding: 0;
}

// @TODO: make mixin for bands + wrappers
// .o-bolt-wrapper--full {
//   padding-left: 0;
//   padding-right: 0;
// }

// Temp hack if wrapper inside a wrapper
// .o-bolt-wrapper .o-bolt-wrapper {
//   padding: 0;
// }
