/* ------------------------------------ *\
   Button settings and tools
\* ------------------------------------ */

/**
 * Settings
 */

$bolt-tabs-bg-border-radius: bolt-border-radius(small);
$bolt-tabs-bg-transition-timing: calc(var(--bolt-transition-timing) * 4);
$bolt-tabs-text-transition: var(--bolt-transition);
$bolt-tabs-selected-border-size: 4px;
$bolt-tabs-selected-border-transition-ease: var(--bolt-transition-ease);
$bolt-tabs-selected-border-transition-timing: var(--bolt-transition-timing);
$bolt-tabs-xsmall-bp: 420px; // Slightly increase the default 400px xsmall breakpoint to be better optimized for larger plus-sized mobile devices -- value shared by the bolt-navbar, bolt-nav-menu, and bolt-navlink components

@keyframes a-bolt-tabs-bg-hover {
  0% {
    transform: scale3d(0, 0, 0);
  }

  20% {
    transform: scale3d(1, 1, 1);
  }

  40% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  60% {
    transform: scale3d(1, 1, 1);
  }

  80% {
    transform: scale3d(0.99, 0.99, 0.99);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}
