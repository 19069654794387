/* ------------------------------------ *\
   Color System (CSS Custom Props)
\* ------------------------------------ */

@use 'sass:list';
@use '@bolt/core' as core;

/**
 * Generate global color variables
 *
 * 1. Maps are kept in core/settings.
 */
:root,
::backdrop {
  @each $color in core.$bolt-colors {
    $color-name: list.nth($color, 1);

    @each $color-shade, $color-value in list.nth($color, 2) {
      @if ($color-shade == core.$bolt-color-default) {
        --bolt-color-#{$color-name}: #{core.bolt-color($color-name)};
      } @else {
        --bolt-color-#{$color-name}-#{$color-shade}: #{core.bolt-color(
            $color-name,
            $color-shade
          )};
      }
    }
  }
}
