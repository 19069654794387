.c-bds-hamburger {
  position: fixed;
  right: var(--bolt-spacing-x-xsmall);
  bottom: var(--bolt-spacing-y-xsmall);
  z-index: bolt-z-index('fab');
}

.c-bds-hamburger__inner {
  position: relative;
}

.c-bds-hamburger__button,
.c-bds-hamburger__button bolt-icon {
  transition: all 0.4s ease;
}

.c-bds-hamburger__button--open {
  opacity: 1;
  z-index: 11;
}

.c-bds-hamburger__button--close {
  opacity: 0;
  // visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  pointer-events: none;
}

.c-bds-layout__sidebar:target
  ~ .c-bds-hamburger
  > .c-bds-hamburger__inner
  .c-bds-hamburger__button--close {
  opacity: 1;
  z-index: 11;
  // visibility: visible;
  pointer-events: auto;

  bolt-icon {
    transform: rotate(1turn) scale(1);
  }
}

.c-bds-layout__sidebar:target
  ~ .c-bds-hamburger
  > .c-bds-hamburger__inner
  .c-bds-hamburger__button--open {
  opacity: 0;
  // visibility: hidden;
  pointer-events: none;

  bolt-icon {
    transform: rotate(1turn) scale(0);
  }
}
