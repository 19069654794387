/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
code[class*='language-'],
pre[class*='language-'] {
  tab-size: 4;
  hyphens: none;
}

pre[class*='language-']::-moz-selection,
pre[class*='language-'] ::-moz-selection,
code[class*='language-']::-moz-selection,
code[class*='language-'] ::-moz-selection {
  text-shadow: none;
}

pre[class*='language-']::selection,
pre[class*='language-'] ::selection,
code[class*='language-']::selection,
code[class*='language-'] ::selection {
  text-shadow: none;
}

@media print {
  code[class*='language-'],
  pre[class*='language-'] {
    text-shadow: none;
  }
}

.token.important,
.token.bold {
  font-weight: var(--bolt-type-font-weight-semibold);
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

pre.line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber;
}

pre.line-numbers > code {
  position: relative;
}

.line-numbers .line-numbers-rows {
  position: absolute;
  top: 0;
  left: -3.8em;
  width: 3em; /* works for line-numbers below 1000 lines */
  font-size: 100%;
  letter-spacing: -1px;
  pointer-events: none;
  user-select: none;
  border-right: 0;
}

.line-numbers-rows > span {
  display: block;
  counter-increment: linenumber;
  pointer-events: none;
}

.line-numbers-rows > span:before {
  content: counter(linenumber);
  display: block;
  padding-right: 0.8em;
  text-align: right;
}
