@import '@bolt/core';

/**
 * Button
 *
 * Dev notes:
 * 1. Special tricks are used here to make inline icon work, reference the mixin scss to understand it.
 * 2. `em` unit is used to make sure the spacing between icon and text is relative to the text's font-size.
 * 3. Offsets the white space around chevron icons' SVG files.
 * 4. Icon only button should be a square, the width and height must be of equal values.
 * 5. https://stackoverflow.com/questions/57455245/how-to-i-solve-this-sass-interpolation-issue
 * 6. Set black color (#000000) of box-shadow on the buttons which are placed on dark themes like black, navy-dark and navy. It's for seeing the shadow on dark themes.
 * 7. This fixes a button shadow bug when a light theme is nested inside a dark theme. https://pegadigitalit.atlassian.net/browse/DS-1150
 */
.e-bolt-button {
  @include bolt-button-native-styles-reset;
  --e-bolt-button-padding-y: calc(var(--bolt-spacing-y-medium) / 2 - 1px);
  --e-bolt-button-padding-x: var(--bolt-spacing-x-medium);
  --e-bolt-button-font-size: var(--bolt-type-font-size-small);
  --e-bolt-button-text-color: var(--m-bolt-text-on-primary);
  --e-bolt-button-line-height: var(--bolt-type-line-height-small);
  --e-bolt-button-border-radius: #{bolt-border-radius(small)};
  --e-bolt-button-bg-color: var(--m-bolt-primary);
  --e-bolt-button-icon-only-size: 1.4em;
  --e-bolt-button-shadow-color: var(--bolt-color-black);
  --e-bolt-button-spinner-size: calc(var(--e-bolt-button-icon-only-size) / 6);

  display: inline-block;
  align-content: center;
  position: relative;
  transform: translate3d(0, 0, 0);
  padding: var(--e-bolt-button-padding-y) var(--e-bolt-button-padding-x);
  font-family: var(--bolt-type-font-family-headline);
  font-size: var(--e-bolt-button-font-size);
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--e-bolt-button-text-color);
  line-height: var(--e-bolt-button-line-height);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: default;
  border-radius: var(--e-bolt-button-border-radius);
  background-color: var(--e-bolt-button-bg-color);
  transition: transform var(--bolt-transition), color 0.5s ease-in;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    border-radius: inherit;
    transition: opacity var(--bolt-transition),
      box-shadow var(--bolt-transition), background-color var(--bolt-transition);
  }

  &:before {
    opacity: 0.6;
    box-shadow: 0 0.2em 0.75em var(--e-bolt-button-shadow-color);

    .t-bolt-black &,
    .t-bolt-navy-dark &,
    .t-bolt-navy & {
      opacity: 0.8;
      box-shadow: 0 0.2em 0.75em #000; // [6]
    }
  }

  &:after {
    opacity: 0;
  }

  &:disabled {
    --e-bolt-button-text-color: var(--m-bolt-text-on-disabled);
    --e-bolt-button-bg-color: var(--m-bolt-disabled);
    transform: translate3d(0, 0, 0);
    cursor: not-allowed;

    &:before,
    &:after {
      display: none;
    }
  }
}

button.e-bolt-button,
a.e-bolt-button {
  &:hover {
    transform: translate3d(0, -2px, 0);

    &:after {
      opacity: 0.3;
      box-shadow: 0 0.4em 1.5em var(--e-bolt-button-shadow-color);
    }

    .t-bolt-black &,
    .t-bolt-navy-dark &,
    .t-bolt-navy & {
      &:after {
        opacity: 1;
      }
    }
  }
}

// [7]
.t-bolt-black,
.t-bolt-navy-dark,
.t-bolt-navy {
  .t-bolt-white,
  .t-bolt-gray-xlight,
  .t-bolt-navy-light,
  .t-bolt-teal,
  .t-bolt-yellow,
  .t-bolt-orange,
  .t-bolt-pink,
  .t-bolt-wine,
  .t-bolt-berry,
  .t-bolt-violet {
    button.e-bolt-button,
    a.e-bolt-button {
      &:hover {
        &:after {
          opacity: 0;
        }
      }
    }
  }
}

button.e-bolt-button,
a.e-bolt-button,
input[type='file'].e-bolt-button {
  &:focus,
  &:active:not(:disabled) {
    transform: translate3d(0, 0, 0);
    outline: var(--bolt-focus-ring);
    outline-offset: 2px;
  }
}

button.e-bolt-button,
a.e-bolt-button,
input[type='file'].e-bolt-button {
  cursor: pointer;
}

$_bolt-button-hierarchy: primary, secondary, tertiary, quaternary, transparent;

@each $hierarchy-name in $_bolt-button-hierarchy {
  @if $hierarchy-name != transparent {
    .e-bolt-button--#{$hierarchy-name} {
      --e-bolt-button-text-color: var(--m-bolt-text-on-#{$hierarchy-name});
      --e-bolt-button-bg-color: var(--m-bolt-#{$hierarchy-name});

      @if $hierarchy-name == secondary {
        &:before {
          opacity: 0.4;
        }
      }

      @if $hierarchy-name == tertiary or $hierarchy-name == quaternary {
        backdrop-filter: blur(0.1em);

        &:before {
          opacity: 0;
        }

        [class*='t-bolt-'] & {
          &:before {
            opacity: 0;
          }
        }

        &:after {
          box-shadow: 0 0 0 2px var(--e-bolt-button-shadow-color);
        }
      }
    }
  } @else {
    // [5]
    .e-bolt-button--#{'' + $hierarchy-name} {
      --e-bolt-button-text-color: var(--m-bolt-link);
      --e-bolt-button-bg-color: transparent;

      &:before,
      &:after {
        display: none;
      }
    }
  }

  @if $hierarchy-name == tertiary or $hierarchy-name == transparent {
    // [5]
    button.e-bolt-button--#{'' + $hierarchy-name},
    a.e-bolt-button--#{'' + $hierarchy-name},
    input[type='file'].e-bolt-button--#{'' + $hierarchy-name} {
      &:hover {
        transform: translate3d(0, 0, 0);

        &:after {
          opacity: 0.2;
          box-shadow: none;
          background-color: var(--bolt-color-gray);
        }

        [class*='t-bolt-'] & {
          &:after {
            opacity: 0.2;
            box-shadow: none;
            background-color: var(--bolt-color-gray);
          }
        }
      }
    }
  }

  @if $hierarchy-name == transparent {
    // [5]
    button.e-bolt-button--#{'' + $hierarchy-name},
    a.e-bolt-button--#{'' + $hierarchy-name} {
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 3px;
      }
    }
  }

  @if $hierarchy-name == transparent {
    button.e-bolt-button--#{'' + $hierarchy-name},
    a.e-bolt-button--#{'' + $hierarchy-name} {
      &.e-bolt-button--icon-only {
        transition: background-color var(--bolt-transition);

        .e-bolt-button__icon-center {
          will-change: transform;
          transition: transform var(--bolt-transition);
        }

        &:hover {
          background-color: var(--m-bolt-tertiary);

          .e-bolt-button__icon-center {
            transform: translate3d(-50%, -50%, 0) scale(1.2);
          }
        }
      }
    }
  }

  @if $hierarchy-name == quaternary {
    button.e-bolt-button--#{'' + $hierarchy-name},
    a.e-bolt-button--#{'' + $hierarchy-name} {
      --e-bolt-button-text-color: var(--m-bolt-link);
      --e-bolt-button-bg-color: transparent;
      border: 1px solid var(--e-bolt-button-text-color);
    }
  }
}

@each $size in $bolt-spacing-multiplier-system {
  $size-name: nth($size, 1);

  @if $size-name ==
    xsmall or
    $size-name ==
    small or
    $size-name ==
    medium or
    $size-name ==
    large
  {
    .e-bolt-button--#{$size-name} {
      @if $size-name == large {
        --e-bolt-button-padding-y: calc(var(--bolt-spacing-y-medium) * 0.75);
        --e-bolt-button-padding-x: var(--bolt-spacing-x-medium);
        --e-bolt-button-font-size: var(--bolt-type-font-size-regular);
        --e-bolt-button-line-height: var(--bolt-type-line-height-regular);
        --e-bolt-button-icon-only-size: 1.75em;
      } @else {
        --e-bolt-button-padding-y: calc(
          var(--bolt-spacing-y-#{$size-name}) / 2 - 1px
        );
        --e-bolt-button-padding-x: var(--bolt-spacing-x-#{$size-name});
      }

      @if $size-name == small {
        --e-bolt-button-font-size: var(--bolt-type-font-size-small);
        --e-bolt-button-line-height: var(--bolt-type-line-height-small);
        --e-bolt-button-icon-only-size: 1.1em;
      }

      @if $size-name == xsmall {
        --e-bolt-button-font-size: var(--bolt-type-font-size-xsmall);
        --e-bolt-button-line-height: var(--bolt-type-line-height-xsmall);
        --e-bolt-button-icon-only-size: 1.1em;
      }
    }
  }
}

// minimum size of 44px required for accessiblity
// we do not want to force it on  smaller buttons
// and it is only required on smaller devices
.e-bolt-button:not(.e-bolt-button--small, .e-bolt-button--xsmall) {
  min-width: 44px;
  min-height: 44px;
}

@each $radius-name, $radius-value in $bolt-border-radius-values {
  .e-bolt-button--border-radius-#{$radius-name} {
    --e-bolt-button-border-radius: #{$radius-value};
  }
}

.e-bolt-button--block {
  display: block;
  width: 100%;
}

.e-bolt-button--inline\@from-small {
  display: block;
  width: 100%;

  @include bolt-mq(small) {
    display: inline-block;
    width: auto;
  }
}

.e-bolt-button--icon-only {
  width: calc(
    var(--e-bolt-button-padding-y) * 2 + var(--e-bolt-button-line-height) * 1em
  ); /* [4] */
  height: calc(
    var(--e-bolt-button-padding-y) * 2 + var(--e-bolt-button-line-height) * 1em
  );
  padding: 0;
}

[class*='e-bolt-button__icon'] {
  @include bolt-inline-icon-wrapper; /* [1] */
}

.e-bolt-button__icon-before {
  margin-right: calc(
    var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xsmall) * 1em
  ); /* [2] */

  > bolt-icon[size] {
    margin-right: var(--bolt-spacing-x-xxsmall);
  }

  > bolt-icon[name*='chevron-'] {
    margin-left: calc(
      var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xxsmall) * -2em
    ); /* [3] */
  }
}

.e-bolt-button__icon-after {
  margin-left: calc(
    var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xsmall) * 1em
  ); /* [2] */

  > bolt-icon[size] {
    margin-left: var(--bolt-spacing-x-xxsmall);
  }

  > bolt-icon[name*='chevron-'] {
    margin-right: calc(
      var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xxsmall) * -2em
    ); /* [3] */
  }
}

.e-bolt-button__icon-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 1em;
  height: 1em;
  font-size: var(--e-bolt-button-icon-only-size);

  > * {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate3d(-50%, -50%, 0) !important;
  }
}

.e-bolt-button__loading-spinner {
  display: inline-block;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(var(--e-bolt-button-icon-only-size) * 1.2);
  height: calc(var(--e-bolt-button-icon-only-size) * 1.2);
  pointer-events: none;
  border: var(--e-bolt-button-spinner-size) solid transparent;
  border-top-color: var(--e-bolt-button-text-color);
  border-radius: 50%;
  animation: 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) infinite
    button-loading-spinner;
  transform-origin: center;
  animation-play-state: paused;
}

.e-bolt-button--is-loading {
  &[aria-busy] {
    color: transparent;
    pointer-events: none;
    transition: color var(--bolt-transition);

    .e-bolt-button__loading-spinner {
      opacity: 1;
      transition: opacity 0.5s ease-in;
      animation-play-state: running;
    }

    &[class*=' t-bolt-'] {
      .e-bolt-button__loading-spinner {
        border-top-color: var(--m-bolt-text);
      }
    }
  }
}

@keyframes button-loading-spinner {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
