/* ------------------------------------ *\
   card-replacement Actions
\* ------------------------------------ */

@use '../card-replacement-settings-and-tools' as card-replacement-settings-and-tools;

bolt-card-replacement-actions {
  position: relative;
  overflow: hidden;

  &:last-child {
    margin-top: auto;
  }

  &:only-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  &:not(:last-child) {
    border-bottom-color: card-replacement-settings-and-tools.$bolt-card-replacement-border-color;
    border-bottom-style: card-replacement-settings-and-tools.$bolt-card-replacement-border-style;
    border-bottom-width: card-replacement-settings-and-tools.$bolt-card-replacement-border-width;
  }

  @at-root * + #{&} {
    border-top-color: card-replacement-settings-and-tools.$bolt-card-replacement-border-color;
    border-top-style: card-replacement-settings-and-tools.$bolt-card-replacement-border-style;
    border-top-width: card-replacement-settings-and-tools.$bolt-card-replacement-border-width;
  }
}

.c-bolt-card_replacement__actions {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: card-replacement-settings-and-tools.$bolt-card-replacement-z-index-inner-link;
  width: 100%;
  margin-top: auto;
}
