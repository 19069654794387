.c-bds-docs__width-demo {
  @extend %bolt-demo-wrapper;
  margin-bottom: 2rem;
}

.c-bds-docs__width-item {
  @extend %bolt-demo-item-border;
  @extend %bolt-demo-item-background;

  h4 + & {
    margin-top: 0.75rem;
  }
}
