/* ------------------------------------ *\
   <a> and <button> resets
\* ------------------------------------ */

@use '@bolt/core' as core;

a {
  color: var(--m-bolt-link);
}

a:not([class]) {
  @include core.bolt-text-link;
}
