/* ------------------------------------ *\
   Menu settings and tools
\* ------------------------------------ */

@use '@bolt/core' as core;

$bolt-menu-border-width: core.$bolt-border-width;
$bolt-menu-border-style: core.$bolt-border-style;
$bolt-menu-border-color: var(--m-bolt-border);
$bolt-menu-spacing-values: xsmall, small, medium;
