/* ------------------------------------ *\
   Profile
\* ------------------------------------ */

@import '@bolt/core';

.c-bolt-profile {
  --c-bolt-profile-header-avatar-size: clamp(7rem, 10vw, 9rem);

  position: relative;
  padding-bottom: var(--bolt-spacing-y-medium);
  border-radius: bolt-border-radius(small);
}

.c-bolt-profile--full-bleed {
  @include bolt-full-bleed;

  padding-bottom: var(--bolt-spacing-y-large);

  @include bolt-mq(medium) {
    --c-bolt-profile-header-avatar-size: clamp(10rem, 20vw, 12rem);
  }
}

.c-bolt-profile__user-visuals {
  position: relative;
}

.c-bolt-profile__cover,
.c-bolt-profile__avatar {
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-bolt-profile__cover {
  position: relative;
  height: clamp(6rem, 10vw, 9rem);
  overflow: hidden;
  border-top-right-radius: bolt-border-radius(small);
  border-top-left-radius: bolt-border-radius(small);

  @at-root .c-bolt-profile--full-bleed #{&} {
    height: clamp(6rem, 15vw, 10rem);
    border-radius: 0;
  }

  svg {
    transform: translate3d(-6%, 0, 0);
    width: 130%;

    @at-root .c-bolt-profile--full-bleed #{&} {
      transform: translate3d(-3.5%, -18%, 0);
      width: 115%;
    }
  }
}

.c-bolt-profile__avatar {
  position: absolute;
  bottom: calc(var(--c-bolt-profile-header-avatar-size) / 2 * -1);
  left: calc(50% - var(--c-bolt-profile-header-avatar-size) / 2);
  width: var(--c-bolt-profile-header-avatar-size);
  height: var(--c-bolt-profile-header-avatar-size);
  overflow: hidden;
  border: 0.25rem solid var(--m-bolt-bg);
  border-radius: bolt-border-radius(full);
  background-color: var(--m-bolt-bg);

  @at-root .c-bolt-profile--full-bleed #{&} {
    @include bolt-mq(medium) {
      left: var(--bolt-page-padding-x);
    }
  }

  svg {
    width: 100%;
    height: auto;
  }
}

.c-bolt-profile__avatar-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
}

.c-bolt-profile__avatar-edit-button {
  opacity: 0;
  position: static; // allows pseudo-element to fill container
  transform: none; // prevents default .e-bolt-button animation
  transition: opacity var(--bolt-transition);

  &:hover,
  &:focus {
    opacity: 1;
    transform: none !important; // prevents default .e-bolt-button animation

    &:before {
      box-shadow: none !important; // prevents default .e-bolt-button style
    }

    & ~ .c-bolt-profile__avatar-edit-background {
      opacity: 0.8;
    }
  }

  &:before {
    pointer-events: all;
    box-shadow: none !important;
  }

  &:after {
    content: none; // turns off default :after pseudo-element which adds box-shadow on hover
  }
}

.c-bolt-profile__avatar-edit-background {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: var(--bolt-color-white);
  transition: opacity var(--bolt-transition);
}

.c-bolt-profile__actions {
  position: absolute;
  top: calc(100% + var(--bolt-spacing-y-xsmall));
  right: var(--bolt-spacing-x-small);

  @at-root .c-bolt-profile--full-bleed #{&} {
    @include bolt-mq(medium) {
      right: var(--bolt-page-padding-x);
    }
  }
}

.c-bolt-profile__user-data {
  display: flex;
  flex-flow: column;

  @at-root .c-bolt-profile--full-bleed #{&} {
    @include bolt-mq(large) {
      flex-flow: row;
      justify-content: space-between;
    }
  }
}

.c-bolt-profile__user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--bolt-spacing-y-xlarge) var(--bolt-spacing-x-small) var(--bolt-spacing-y-small);
  text-align: center;
  gap: var(--bolt-spacing-x-small);
  row-gap: var(--bolt-spacing-y-small);

  @at-root .c-bolt-profile--full-bleed #{&} {
    @include bolt-mq(medium) {
      flex-grow: 2;
      align-items: flex-start;
      padding:  var(--bolt-spacing-y-large) 0 0 calc(var(--bolt-page-padding-x) + var(--c-bolt-profile-header-avatar-size) + 20px);
      text-align: start;
    }
  }
}

.c-bolt-profile__user-headline {
  margin-bottom: var(--bolt-spacing-y-xxsmall);

  > * {
    font-family: var(--bolt-type-font-family-headline);
    font-size: clamp(
      var(--bolt-type-font-size-medium),
      2vw,
      var(--bolt-type-font-size-large)
    );
    font-weight: var(--bolt-type-font-weight-regular);
    color: var(--m-bolt-headline);
    line-height: 1;

    @at-root .c-bolt-profile--full-bleed #{&} {
      font-size: clamp(
        var(--bolt-type-font-size-large),
        2.3vw,
        var(--bolt-type-font-size-xlarge)
      );
    }
  }
}

.c-bolt-profile__user-chips {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--bolt-spacing-x-xsmall);
  row-gap: var(--bolt-spacing-y-xsmall);
  white-space: nowrap;

  @at-root .c-bolt-profile--full-bleed #{&} {
    @include bolt-mq(large) {
      justify-content: flex-start;
    }
  }
}

.c-bolt-profile__user-meta {
  font-size: clamp(
    var(--bolt-type-font-size-small),
    2vw,
    var(--bolt-type-font-size-regular)
  );
  line-height: var(--bolt-type-line-height-small);
}

.c-bolt-profile__stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-xsmall) 0
    var(--bolt-spacing-x-xsmall);
  list-style: none;
  gap: var(--bolt-spacing-x-xsmall);

  @at-root .c-bolt-profile--full-bleed #{&} {
    @include bolt-mq(medium) {
      flex-wrap: nowrap;
      align-items: flex-end;
      margin-right: var(--bolt-page-padding-x);
    }
  }
}

.c-bolt-profile__stats-item {
  @include bolt-button-native-styles-reset;

  flex-basis: 6.25rem;
  padding: calc(var(--bolt-spacing-y-small) / 2) 0;
  font-family: var(--bolt-type-font-family-headline);
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--m-bolt-neutral);
  text-align: center;
  text-decoration: none;
  cursor: auto;
  border-radius: bolt-border-radius(small);
  transition: color var(--bolt-transition),
    background-color var(--bolt-transition);

  @at-root .c-bolt-profile--full-bleed #{&} {
    @include bolt-mq(medium) {
      flex-basis: unset;
      padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
    }

    @include bolt-mq(medium) {
      margin-bottom: calc(var(--bolt-spacing-y-small) * -1);
    }
  }
}

button.c-bolt-profile__stats-item,
a.c-bolt-profile__stats-item {
  cursor: pointer;

  &:hover {
    color: var(--m-bolt-headline);
    background-color: var(--m-bolt-tertiary);

    > * {
      color: inherit;
    }
  }

  &:focus {
    outline: var(--bolt-focus-ring);
  }

  .c-bolt-profile__stats-signifier {
    color: var(--m-bolt-link);
  }
}

.c-bolt-profile__stats-signifier {
  display: grid;
  max-height: 1em;
  margin-bottom: var(--bolt-spacing-y-xsmall);
  font-size: var(--bolt-type-font-size-large);
  line-height: 1;
  place-items: center;
}

.c-bolt-profile__stats-label {
  display: block;
  font-size: var(--bolt-type-font-size-xsmall);
  line-height: 1;
}
