@use 'sass:list';
@use '@bolt/core' as core;

@use './action-blocks-settings-and-tools' as action-blocks-settings-and-tools;

/* ------------------------------------ *\
   Action Blocks Item
\* ------------------------------------ */

// Individual Action Block Styles
.c-bolt-action-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
  padding: calc(var(--bolt-spacing-y-medium) * 1.5) var(--bolt-spacing-x-medium);
  outline: none;
  transition: color var(--bolt-transition);
}

.c-bolt-action-block--link {
  color: var(--m-bolt-headline);
  text-decoration: none;

  &:before {
    content: '';
    display: block;
    opacity: core.bolt-opacity(0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: action-blocks-settings-and-tools.$bolt-action-block-background;
    transition: opacity var(--bolt-transition);
  }

  &:hover,
  &:focus {
    &:before {
      opacity: core.bolt-opacity(100);
    }
  }

  &:focus {
    .c-bolt-action-block__item {
      text-decoration: underline;
    }
  }

  &:active {
    &:before {
      opacity: core.bolt-opacity(80);
    }
  }
}

.c-bolt-action-block__text {
  color: var(--m-bolt-link);
}

.c-bolt-action-block__item {
  display: block;
  position: relative;
  width: 100%;
  max-width: action-blocks-settings-and-tools.$bolt-action-block-item-max-width;
  margin: 0 auto var(--bolt-spacing-y-small) auto;
  font-size: var(--bolt-type-font-size-xsmall);
  font-weight: var(--bolt-type-font-weight-bold);
  line-height: var(--bolt-type-line-height-xsmall);
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
}

// Spacing prop
$bolt-action-blocks-spacing: xsmall, small, medium;

@each $size in core.$bolt-spacing-multiplier-system {
  $size-name: list.nth($size, 1);

  @if (
    $size-name !=
      'none' and
      $size-name !=
      'xxsmall' and
      $size-name !=
      'large'
      $size-name !=
      'xlarge'
      $size-name !=
      'xxlarge'
  ) {
    @if list.index($bolt-action-blocks-spacing, $size-name) {
      .c-bolt-action-blocks--spacing-#{$size-name} {
        .c-bolt-action-block {
          padding: calc(var(--bolt-spacing-y-#{$size-name}) * 1.5)
            var(--bolt-spacing-x-#{$size-name});
        }
      }
    }
  }
}
