/* ------------------------------------ *\
  OPACITY UTILITIES
  see `_settings-opacity.scss`
\* ------------------------------------ */

@import '@bolt/core';

@each $opacity-name, $opacity-value in $bolt-opacities {
  .u-bolt-opacity-#{$opacity-name} {
    @include bolt-opacity($opacity-name);
  }

  @each $breakpoint-name, $breakpoint-value in $bolt-breakpoints {
    .u-bolt-opacity-#{$opacity-name}\@#{$breakpoint-name} {
      @include bolt-mq($breakpoint-name) {
        @include bolt-opacity($opacity-name);
      }
    }
  }
}

// DEPRECATED. These opacity values will be removed in Bolt 3.0.  See $bolt-opacities for valid opacity values.
// To remove support, delete all the code below this line.
$bolt-deprecated-opacities-map: (
  25: 20,
  50: 60,
  75: 80,
);

@each $old-opacity, $new-opacity in $bolt-deprecated-opacities-map {
  .u-bolt-opacity-#{$old-opacity} {
    @include bolt-opacity($new-opacity);
  }

  @each $breakpoint-name, $breakpoint-value in $bolt-breakpoints {
    .u-bolt-opacity-#{$old-opacity}\@#{$breakpoint-name} {
      @include bolt-mq($breakpoint-name) {
        @include bolt-opacity($new-opacity);
      }
    }
  }
}
