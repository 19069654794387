$bolt-macbook-viewer-height: 720px;
$bolt-macbook-viewer-width: 960px + (44px * 2);

// $bolt-macbook-shadow: 0 1px 2px 1px rgba(6,10,36,0.10),
//   0 0.25rem 0.5rem 1px rgba(6,10,36,0.15),
//   0 1rem 2rem 0 rgba(6,10,36,0.25);

$bolt-macbook-shadow: none;

.c-bolt-macbook-viewer {
  width: 100%;
  max-width: $bolt-macbook-viewer-width * 0.6;
  border-top-right-radius: (34px / $bolt-macbook-viewer-width * 100%)
    (34px / $bolt-macbook-viewer-height * 100%);
  border-top-left-radius: (34px / $bolt-macbook-viewer-width * 100%)
    (34px / $bolt-macbook-viewer-height * 100%);
  border-bottom-right-radius: (34px / $bolt-macbook-viewer-width * 100%)
    (34px / $bolt-macbook-viewer-height * 100%);
  border-bottom-left-radius: (34px / $bolt-macbook-viewer-width * 100%)
    (34px / $bolt-macbook-viewer-height * 100%);
  box-shadow: $bolt-macbook-shadow;
  background-color: #bebebe;
}

.c-bolt-macbook-viewer__inner {
  padding: (44px / $bolt-macbook-viewer-width * 100%)
    (44px / $bolt-macbook-viewer-width * 100%)
    (76px / $bolt-macbook-viewer-width * 100%);

  &:before {
    top: 4px / $bolt-macbook-viewer-height * 100%;
    right: 4px / $bolt-macbook-viewer-width * 100%;
    bottom: 4px / $bolt-macbook-viewer-height * 100%;
    left: 4px / $bolt-macbook-viewer-width * 100%;
    width: auto;
    height: auto;
    border-top-right-radius: (30px / $bolt-macbook-viewer-width * 100%)
      (30px / $bolt-macbook-viewer-height * 100%);
    border-top-left-radius: (30px / $bolt-macbook-viewer-width * 100%)
      (30px / $bolt-macbook-viewer-height * 100%);
    border-bottom-right-radius: (30px / $bolt-macbook-viewer-width * 100%)
      (30px / $bolt-macbook-viewer-height * 100%);
    border-bottom-left-radius: (30px / $bolt-macbook-viewer-width * 100%)
      (30px / $bolt-macbook-viewer-height * 100%);
    background-color: #1e1e1e;
  }
}

.c-bolt-macbook-viewer__screen {
  padding-bottom: 62.5%;
  background: var(--bolt-color-white);
}

.c-bolt-macbook-viewer__top-bar,
.c-bolt-macbook-viewer__bottom-bar {
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--bolt-color-black);
}

.c-bolt-macbook-viewer__top-bar {
  $offset: 70px / $bolt-macbook-viewer-width * 100%;
  content: '';

  display: block;
  position: absolute;
  bottom: 0; // originally top: 680px
  left: $offset * -1; // originally -70px
  width: calc(100% + 2 * #{$offset});
  height: 40px / $bolt-macbook-viewer-height * 100%;
  border-bottom-right-radius: (90px / $bolt-macbook-viewer-width * 100%)
    (18px / 40px * 100%); // originally 90px 18px
  border-bottom-left-radius: (90px / $bolt-macbook-viewer-width * 100%)
    (18px / 40px * 100%); // originally 90px 18px
  box-shadow: inset 0 -4px 13px 3px rgba(34, 34, 34, 0.6);
  background-color: #bebebe;

  &:before {
    content: '';
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px / 40px * 100%;
    border-bottom: 2px solid #aaa;
    border-radius: 5px;
    background-color: #f0f0f0;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 16%;
    height: 14px / 40px * 100%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 0 0 20px 20px;
    box-shadow: inset 0 -3px 10px #999;
    background-color: #ddd;
  }
}

.c-bolt-macbook-viewer__bottom-bar {
  $offset: (70px / $bolt-macbook-viewer-width * 100%);

  display: block;
  position: absolute;
  bottom: 14px / $bolt-macbook-viewer-height * 100%;
  left: $offset * -1;
  width: calc(100% + 2 * #{$offset});
  height: 26px / $bolt-macbook-viewer-height * 100%;
  background-color: transparent;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 80px / $bolt-macbook-viewer-width * 100%; // 80px
    height: calc(100% - 2px);
  }

  &:before {
    left: 0;
    background-color: #f0f0f0;
    background-image: linear-gradient(
      to right,
      #747474 0%,
      #c3c3c3 5%,
      #ebebeb 14%,
      #979797 41%,
      #f0f0f0 80%,
      #f0f0f0 100%,
      #f0f0f0 100%
    );
  }

  &:after {
    right: 0;
    background-color: #f0f0f0;
    background-image: linear-gradient(
      to right,
      #f0f0f0 0%,
      #f0f0f0 0%,
      #f0f0f0 20%,
      #979797 59%,
      #ebebeb 86%,
      #c3c3c3 95%,
      #747474 100%
    );
  }
}

.c-bolt-macbook-viewer__camera {
  top: 20px / $bolt-macbook-viewer-height * 100%;
  left: 50%;
  transform: translateX(-50%); // Center align without knowing size of element
  width: 10px / $bolt-macbook-viewer-width * 100%;
  height: 10px / $bolt-macbook-viewer-height * 100%;
  background-color: #3c3d3d;
}

.c-bolt-macbook-viewer__home {
  display: none;
}
