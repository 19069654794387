/* ------------------------------------ *\
   Text
\* ------------------------------------ */

@use 'sass:list';
@use '@bolt/core' as core;

// Attribute selectors for parent bolt-text
@include core.bolt-repeat-rule(('bolt-text', ':host')) {
  display: block;
  margin-bottom: var(--c-bolt-text-margin-bottom, var(--bolt-spacing-y-medium));

  // Block display has built-in spacing.
  &[eyebrow] {
    --c-bolt-text-margin-bottom: var(--bolt-spacing-y-xxsmall);
  }

  &[headline],
  &[subheadline] {
    --c-bolt-text-margin-bottom: var(--bolt-spacing-y-xsmall);
  }

  &:last-child {
    --c-bolt-text-margin-bottom: 0;
  }

  // Inline display does not have built-in spacing.
  &[display='inline'] {
    --c-bolt-text-margin-bottom: 0;
    display: inline;
  }
}

.c-bolt-text-v2 {
  @include core.bolt-deprecated-type-vars;

  margin: 0;
  padding: 0;
  font-family: var(--c-bolt-text-font-family, unset);
  font-size: var(--c-bolt-text-font-size, unset);
  font-weight: var(--c-bolt-text-font-weight, unset);
  line-height: var(--c-bolt-text-line-height, unset);
  letter-spacing: var(--c-bolt-text-letter-spacing, unset);
  font-display: swap;

  // Font family
  @each $family in core.$bolt-type-font-family-system {
    $family-name: list.nth($family, 1);

    &--#{$family-name} {
      --c-bolt-text-font-family: var(--bolt-type-font-family-#{$family-name});
    }
  }

  // Font Weight Options
  @each $weight in core.$bolt-type-font-weight-system {
    $weight-name: list.nth($weight, 1);

    &--font-weight-#{$weight-name} {
      --c-bolt-text-font-weight: var(--bolt-type-font-weight-#{$weight-name});
    }
  }

  // Font size
  @each $size in core.$bolt-type-font-size-system--deprecated {
    $size-name: list.nth($size, 1);
    @if ($size-name != 'xxsmall') {
      &--font-size-#{$size-name} {
        --c-bolt-text-font-size: var(--bolt-type-font-size-#{$size-name});
      }
    }
  }

  // Letter spacing
  @each $spacing in core.$bolt-type-letter-spacing-system {
    $spacing-name: list.nth($spacing, 1);

    &--letter-spacing-#{$spacing-name} {
      --c-bolt-text-letter-spacing: var(
        --bolt-type-letter-spacing-#{$spacing-name}
      );
    }
  }

  // Line height
  @each $size in core.$bolt-type-font-size-system--deprecated {
    $size-name: list.nth($size, 1);
    @if ($size-name != 'xxsmall') {
      &--font-size-#{$size-name} {
        --c-bolt-text-line-height: var(--bolt-type-line-height-#{$size-name});

        @each $height in core.$bolt-type-line-height-multiplier-system {
          $height-name: list.nth($height, 1);

          &.c-bolt-text-v2--line-height-#{$height-name} {
            --c-bolt-text-line-height: calc(
              var(--bolt-type-line-height-#{$size-name}) *
                var(--bolt-type-line-height-multiplier-#{$height-name})
            );
          }
        }
      }
    }
  }

  // Display type
  &--inline {
    display: inline;
  }

  &--block {
    display: block;
  }

  // Color
  &--color-theme-headline {
    color: var(--m-bolt-headline);
  }

  &--color-theme-body {
    color: var(--m-bolt-text);
  }

  // Font style
  &--font-style-regular {
    font-style: normal;
  }

  &--font-style-italic {
    font-style: italic;
  }

  // Text align
  &--align-inherit {
    text-align: inherit;
  }

  &--align-start {
    text-align: start;
  }

  &--align-center {
    text-align: center;
  }

  &--align-end {
    text-align: end;
  }

  // Text transform
  &--text-transform-regular {
    text-transform: none;
  }

  &--text-transform-uppercase {
    text-transform: uppercase;
  }

  &--text-transform-lowercase {
    text-transform: lowercase;
  }

  &--text-transform-capitalize {
    text-transform: capitalize;
  }

  // Quoted style
  &--quoted {
    &:before,
    &:after {
      font-family: Georgia, serif;
    }

    &:before {
      content: '\201C';
      position: absolute;
      transform: translate3d(-110%, 0, 0);
    }

    &:after {
      content: '\201D';
    }
  }

  // Opacity
  @each $key, $value in core.$bolt-opacities {
    @if ($key != '0') {
      &--opacity-#{$key} {
        opacity: core.bolt-opacity($key);
      }
    }
  }

  a {
    opacity: core.bolt-opacity(100);
    transition: all var(--bolt-transition);

    &:link,
    &:visited {
      color: var(--m-bolt-link);
      text-decoration: underline;
    }

    &:hover {
      opacity: core.$bolt-global-link-hover-opacity;
    }

    &:active,
    &:focus:active {
      opacity: core.$bolt-global-link-active-opacity;
    }
  }

  &.c-bolt-text-v2--headline {
    a:link,
    a:visited {
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}
