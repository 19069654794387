$bolt-logo-max-width: var(--bolt-spacing-x-xlarge);

bolt-logo {
  display: block; // to force break to new line by default
  max-width: $bolt-logo-max-width;

  &:not(:last-child) {
    margin-bottom: var(--bolt-spacing-y-small);
  }
}

.c-bolt-logo--invert {
  filter: var(--m-bolt-img-filter);
}
