@use '@bolt/core' as core;

.c-bds-component-status {
  @include core.bolt-shadow('level-10');
  margin-bottom: var(--bolt-spacing-y-medium);
  padding: calc(var(--bolt-spacing-y-small) * 1.5)
    calc(var(--bolt-spacing-x-small) * 1.5);
  font-size: var(--bolt-type-font-size-xsmall);
  line-height: var(--bolt-type-line-height-xsmall);
  border-radius: 12px;
  background: #fff;
}

.c-bds-component-status__title {
  display: block;
  position: relative;
  margin-bottom: var(--bolt-spacing-y-xsmall);
  font-size: var(--bolt-type-font-size-medium);
  line-height: var(--bolt-type-line-height-medium);

  > *,
  > .c-bolt-headline {
    color: inherit;
  }

  .c-bds-component-status--released & {
    color: #099;
  }

  .c-bds-component-status--inprogress & {
    font-weight: var(--bolt-type-font-weight-semibold);
    color: var(--bolt-color-warning-dark);
  }
}

.c-bds-component-status__definition {
  @include core.bolt-clearfix;
  margin-bottom: 0;
  margin-left: calc(var(--bolt-spacing-x-small) * -1);

  dt {
    display: block;
    width: 40%;
    padding-bottom: var(--bolt-spacing-y-xsmall);
    font-weight: var(--bolt-type-font-weight-semibold);

    @include core.bolt-mq($from: medium) {
      width: 30%;
    }
  }

  dd {
    display: block;
    width: 60%;
    margin-left: 0;
    padding-top: var(--bolt-spacing-y-xsmall);
    padding-bottom: var(--bolt-spacing-y-xsmall);
    font-weight: var(--bolt-type-font-weight-bold);
    border-left: none;

    @include core.bolt-mq($from: medium) {
      width: 70%;
    }
  }

  dt,
  dd {
    display: block;
    float: left;
    padding-top: var(--bolt-spacing-y-xsmall);
    padding-left: var(--bolt-spacing-x-small);
    color: #4a4a4a;
  }
}
