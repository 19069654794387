@use '_shared-styles' as shared-styles;

.c-bds-docs__clearfix-demo {
  @extend %bolt-demo-wrapper;

  h3 {
    margin-bottom: 1rem;
  }

  &__left,
  &__right {
    @extend %bolt-demo-item;
  }

  &__left {
    float: left;
    width: 30%;
  }

  &__right {
    float: right;
    width: calc(70% - 1rem);
  }
}

.c-bds-docs__clearfix-separator {
  clear: both;
  height: 2rem;
}
