/* ------------------------------------ *\
   Information Density
   Note: this overrides spacing and
   typography vars.
\* ------------------------------------ */

/**
 * Compact Spacing System
 */
[data-bolt-info-density='compact'] {
  --bolt-spacing-x: 1.25;
  --bolt-spacing-y: 1;

  --bolt-type-font-size-largedisplay: 4.15rem;
  --bolt-type-font-size-display: 3.5rem;
  --bolt-type-font-size-xxxlarge: 2.75rem;
  --bolt-type-font-size-xxlarge: 2.1rem;
  --bolt-type-font-size-xlarge: 1.6rem;
  --bolt-type-font-size-large: 1.35rem;
  --bolt-type-font-size-medium: 1.125rem;
  --bolt-type-font-size-regular: 1rem;
  --bolt-type-font-size-small: 0.85rem;
  --bolt-type-font-size-xsmall: 0.75rem;
  --bolt-type-font-size-tiny: 0.65rem;

  --bolt-type-line-height-largedisplay: 1.27;
  --bolt-type-line-height-display: 1.28;
  --bolt-type-line-height-xxxlarge: 1.27;
  --bolt-type-line-height-xxlarge: 1.25;
  --bolt-type-line-height-xlarge: 1.35;
  --bolt-type-line-height-large: 1.3;
  --bolt-type-line-height-medium: 1.3;
  --bolt-type-line-height-regular: 1.5;
  --bolt-type-line-height-small: 1.4;
  --bolt-type-line-height-xsmall: 1.4;
  --bolt-type-line-height-tiny: 1.35;
}

/**
 * Comfortable Spacing System
 */
[data-bolt-info-density='comfortable'] {
  --bolt-spacing-x: 1.75;
  --bolt-spacing-y: 1.6;

  --bolt-type-font-size-largedisplay: 4.75rem;
  --bolt-type-font-size-display: 4rem;
  --bolt-type-font-size-xxxlarge: 3.5rem;
  --bolt-type-font-size-xxlarge: 2.75rem;
  --bolt-type-font-size-xlarge: 1.9rem;
  --bolt-type-font-size-large: 1.6rem;
  --bolt-type-font-size-medium: 1.2rem;
  --bolt-type-font-size-regular: 1rem;
  --bolt-type-font-size-small: 0.9rem;
  --bolt-type-font-size-xsmall: 0.8rem;
  --bolt-type-font-size-tiny: 0.7rem;

  --bolt-type-line-height-largedisplay: 1.27;
  --bolt-type-line-height-display: 1.25;
  --bolt-type-line-height-xxxlarge: 1.25;
  --bolt-type-line-height-xxlarge: 1.25;
  --bolt-type-line-height-xlarge: 1.3;
  --bolt-type-line-height-large: 1.4;
  --bolt-type-line-height-medium: 1.5;
  --bolt-type-line-height-regular: 1.55;
  --bolt-type-line-height-small: 1.45;
  --bolt-type-line-height-xsmall: 1.4;
  --bolt-type-line-height-tiny: 1.4;
}
