@use '@bolt/core' as core;

.c-sf__results {
  .c-bolt-accordion {
    .c-bolt-accordion-item {
      &:first-of-type {
        border: none;
      }
    }

    .c-bolt-accordion-item__content-inner,
    .c-bolt-accordion-item__trigger {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .e-bolt-type--eyebrow {
    margin-bottom: 0;
    padding-bottom: var(--bolt-spacing-y-medium);
    color: var(--bolt-color-violet);
    border-bottom: 1px solid var(--m-bolt-border);
  }

  &--double-acc-label {
    bolt-layout-item {
      &:first-of-type {
        @include core.bolt-mq(small) {
          padding-right: var(--bolt-spacing-x-small);
          border-right: 1px solid var(--m-bolt-border);
        }
      }
    }
  }

  .e-bolt-type--eyebrow {
    --e-bolt-type-font-family: CustomFontChakraPetch;
    font-size: var(--bolt-type-font-size-medium);
    color: var(--bolt-color-violet);
  }
}

.c-sf {
  background: linear-gradient(to bottom, #fff, #edf2fa);

  @include core.bolt-mq($until: medium) {
    bolt-layout[gutter='large'] {
      --l-bolt-layout-gutter: var(--bolt-spacing-x-small);
    }

    bolt-layout[row-gutter='large'] {
      --l-bolt-layout-row-gutter: var(--bolt-spacing-y-small);
    }
  }

  .e-bolt-type--eyebrow {
    --e-bolt-type-font-family: CustomFontChakraPetch;
    font-size: var(--bolt-type-font-size-medium);
    color: var(--bolt-color-violet);
  }

  &__intro {
    bolt-layout-item[order~='last']:before {
      content: '';
      position: absolute;
      top: 0.5px;
      left: calc(-100vw + 3px);
      z-index: bolt-z-index('contentTop');
      width: 100vw;
      height: 100%;
      background: #3048a0;

      @include core.bolt-mq($until: medium) {
        display: none;
      }
    }

    &-text-content {
      padding: var(--bolt-spacing-y-medium) 0;
    }

    .e-bolt-type--headline span {
      display: block;
    }
  }

  &__titles {
    position: relative;
    z-index: bolt-z-index('contentTop');
    height: auto;
  }

  &__titles-step {
    opacity: 0;
    position: absolute;
    width: 100%;
    filter: blur(20px);
    transition: 0.6s ease;

    &--active-title {
      opacity: 1;
      padding: var(--bolt-spacing-y-medium);
      filter: blur(0);
    }
  }

  &__subtitle {
    @include core.bolt-mq($until: medium) {
      font-size: var(--bolt-type-font-size-medium);
    }
  }

  &__form {
    overflow: hidden;
  }

  &__form-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    transition: transform 0.6s ease-in-out;
  }

  &__step {
    flex-shrink: 0;
    opacity: 0;
    width: 100%;
    height: 0;
    padding: var(--bolt-spacing-y-xxlarge) var(--bolt-spacing-x-large)
      var(--bolt-spacing-x-large);
    transition: opacity 0.2s ease-in-out;

    &-4 {
      @include core.bolt-mq($until: small) {
        padding-top: calc(var(--bolt-spacing-y-xxlarge) + 1rem);
      }
    }

    &-item {
      display: flex;
    }

    &--active {
      opacity: 1;
      height: 100%;
    }

    .e-bolt-button {
      &[aria-disabled='true'] {
        color: var(--bolt-color-gray-xdark);
        pointer-events: none;
        background-color: var(--bolt-color-gray-light);

        &:before {
          box-shadow: none;
        }

        &:hover {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__step {
    &.c-sf__intro,
    &.c-sf__submission {
      padding: 0;
    }
  }

  &-card {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: var(--bolt-spacing-x-small);
    padding: var(--bolt-spacing-y-medium) var(--bolt-spacing-x-medium);
    text-align: center;
    background-color: var(--bolt-color-white);
    transition: background-color var(--bolt-transition);

    @include core.bolt-mq($until: small) {
      padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);

      .e-bolt-type {
        font-size: var(--bolt-type-font-size-small--lora);
      }
    }

    &--horizontal {
      flex-direction: row;
      text-align: left;

      @include core.bolt-mq($until: xlarge) {
        flex-direction: column;
        text-align: center;
      }
    }

    &--no-graphic {
      align-items: unset;
      padding-top: var(--bolt-spacing-y-large);
      text-align: left;
    }

    span {
      display: block;
    }

    &:hover,
    &:has(input:checked) {
      cursor: pointer;
      background-color: #e0e3fd;
    }

    &:has(input:focus) {
      outline: var(--bolt-focus-ring);
      outline-offset: 2px;
    }

    &:hover,
    &:has(input:focus) {
      .e-bolt-shape {
        border-color: #858ecc;
        background-color: transparent;
      }
    }

    input[type='checkbox']:checked {
      ~ .e-bolt-shape {
        border-color: #858ecc;
        background-color: transparent;

        .e-bolt-shape__content {
          opacity: 1;
        }
      }
    }

    // demo purpose for all steps might dont be needed after target lottie.json
    lottie-player {
      max-width: 8rem;
      height: 8rem;

      // demo purpose for step 3 to show taller cards might dont be needed after target lottie.json
      .c-sf__step-2 & {
        max-width: unset;
        height: 24rem;

        @include core.bolt-mq($until: medium) {
          height: unset;
        }
      }
    }

    .e-bolt-shape {
      position: absolute;
      top: var(--bolt-spacing-y-small);
      right: var(--bolt-spacing-x-small);
      border: 2px solid var(--m-bolt-border);
      background-color: var(--bolt-color-white);
      transition: background-color, border-color var(--bolt-transition);
    }

    .e-bolt-shape__content {
      opacity: 0;
      color: #858ecc;
      transition: opacity var(--bolt-transition);
    }
  }

  &__submission {
    &-text-content {
      padding: var(--bolt-spacing-y-medium) 0;
    }

    &--illustration {
      background: linear-gradient(
        180deg,
        #e5ecf6 0%,
        #c8d5ff 79.5%,
        #001d63 79.7%,
        #001d63 100%
      );

      &:before {
        content: '';
        position: absolute;
        bottom: -0.5px;
        left: -1px;
        width: 14.4%;
        height: 100%;
        background: linear-gradient(to bottom, #fff, #edf2fa);

        @include core.bolt-mq($until: medium) {
          bottom: 0;
          background: linear-gradient(to bottom, #fafbfe, #edf2fa);
        }

        @include core.bolt-mq($until: small) {
          background: linear-gradient(to bottom, #f7f9fc, #edf2fa);
        }
      }

      &:after {
        content: '';
        position: absolute;
        right: calc(-100vw + 1px);
        bottom: 0.1px;
        z-index: 0;
        width: 100vw;
        height: 100%;
        background: linear-gradient(
          180deg,
          #e5ecf6 0%,
          #c8d5ff 79.5%,
          #001d63 79.7%,
          #001d63 100%
        );

        @include core.bolt-mq($until: medium) {
          display: none;
        }
      }
    }
  }

  // Disable all transitions if the user prefers reduced motion
  @media (prefers-reduced-motion: reduce) {
    * {
      transition: none !important;
    }
  }
}
