/* ------------------------------------ *\
   card-replacement Action
\* ------------------------------------ */

@use '../card-replacement-settings-and-tools' as
  card-replacement-settings-and-tools;

bolt-card-replacement-action {
  display: block;
  flex: 1;
  position: relative;
  // prettier-ignore
  margin-top: calc(
    #{card-replacement-settings-and-tools.$bolt-card-replacement-border-width} * -1.5
  );
  border-top-color: card-replacement-settings-and-tools.$bolt-card-replacement-border-color;
  border-top-style: card-replacement-settings-and-tools.$bolt-card-replacement-border-style;
  border-top-width: card-replacement-settings-and-tools.$bolt-card-replacement-border-width;

  // [Mai] When there are 2 actions, the text is likely to be short, that's a situation where it would benefit from text not wrapping to another line. Instead, if it runs out of room, the actions stack on top of each other.
  &:not(:only-child) {
    white-space: nowrap;
  }
}

.c-bolt-card_replacement__action {
  display: block;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    // prettier-ignore
    inset-inline-start: card-replacement-settings-and-tools.$bolt-card-replacement-border-width * -1;
    width: card-replacement-settings-and-tools.$bolt-card-replacement-border-width;
    height: calc(
      100% + #{card-replacement-settings-and-tools.$bolt-card-replacement-border-width}
    );
    background-color: card-replacement-settings-and-tools.$bolt-card-replacement-border-color;
  }

  > * {
    height: 100%;
  }
}

bolt-card-replacement-action,
.c-bolt-card_replacement__action {
  .e-bolt-button {
    text-align: start;

    &:focus,
    &:active:not(:disabled) {
      outline-offset: -4px;
    }
  }
}
