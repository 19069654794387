// Dark Syntax

.c-bolt-code-snippet[data-mode='dark'] {
  // Redefine vars that text link styles are dependent on.
  --m-bolt-text: var(--bolt-color-white);
  --m-bolt-headline: var(--bolt-color-white);
  --m-bolt-link: var(--bolt-color-white);

  border: 1px solid var(--bolt-color-gray-dark);
  box-shadow: 0 0 6px rgba(bolt-color(gray, xlight), 0.2); // Sass function is used due to transparency needed.

  &,
  code,
  pre {
    color: var(--bolt-color-gray-xlight);
    background: var(--bolt-color-navy-xdark);
  }

  pre::-moz-selection,
  pre ::-moz-selection,
  code::-moz-selection,
  code ::-moz-selection {
    background: var(--bolt-color-navy-xlight);
  }

  pre::selection,
  pre ::selection,
  code::selection,
  code ::selection {
    background: var(--bolt-color-navy-xlight);
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #5c6370;
  }

  .token.punctuation {
    color: #abb2bf;
  }

  .token.selector,
  .token.tag {
    color: lightsalmon;
  }

  .token.property,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.attr-name,
  .token.deleted {
    color: #d19a66;
  }

  .token.string,
  .token.char,
  .token.attr-value,
  .token.builtin,
  .token.inserted {
    color: #98c379;
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: #56b6c2;
  }

  .token.atrule,
  .token.keyword {
    color: #c678dd;
  }

  .token.function {
    color: #61afef;
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: #c678dd;
  }

  .line-numbers-rows > span:before {
    color: #5c6370;
  }
}
