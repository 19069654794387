/* ==========================================================================
   #UI-LIST OBJECT
   ========================================================================== */

/**
 * The UI list object creates blocky list items with a separator between each
 * item
 */
$bolt-ui-list-border-width: 1px !default;
$bolt-ui-list-border-style: solid !default;

.o-bolt-ui-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.o-bolt-ui-list__item {
  position: relative;

  &:not(:last-child) {
    border-bottom-color: var(--m-bolt-border);
    border-bottom-style: $bolt-ui-list-border-style;
    border-bottom-width: $bolt-ui-list-border-width;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.o-bolt-ui-list > .o-bolt-ui-list__item,
.o-bolt-ui-list--medium > .o-bolt-ui-list__item {
  padding: var(--bolt-spacing-y-medium) 0;
}

.o-bolt-ui-list--xxsmall > .o-bolt-ui-list__item {
  padding: var(--bolt-spacing-y-xxsmall) 0;
}

.o-bolt-ui-list--xsmall > .o-bolt-ui-list__item {
  padding: var(--bolt-spacing-y-xsmall) 0;
}

.o-bolt-ui-list--small > .o-bolt-ui-list__item {
  padding: var(--bolt-spacing-y-small) 0;
}

.o-bolt-ui-list--large > .o-bolt-ui-list__item {
  padding: var(--bolt-spacing-y-large) 0;
}

// Remove all padding
.o-bolt-ui-list--collapsed > .o-bolt-ui-list__item {
  padding: 0;
}

.o-bolt-ui-list--borderless > .o-bolt-ui-list__item {
  border-bottom-width: 0;
}
