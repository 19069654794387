// @todo: refactor this and break apart

@use '@bolt/core' as core;

$bds-sidebar-width: 320px;

.c-bds-layout {
  grid-template-columns: auto 1fr auto;
  display: grid;
  align-content: stretch;
  min-height: 100vh;

  &--parent:not(.c-bds-layout--has-sidebar) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: auto;
    min-height: 100vh;
  }

  @include core.bolt-mq(medium) {
    &.c-bds-layout--has-sidebar {
      grid-template-columns: $bds-sidebar-width auto 1fr auto;
    }
  }
}

.c-bds-layout__wrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-self: stretch;
  grid-column-start: 1;
  grid-column-end: 1;
  max-width: 100vw;
  height: 100%;

  .c-bds-layout__content--has-sidebar & {
    @include core.bolt-mq(medium) {
      max-width: calc(100vw - 320px);
    }
  }
}

.c-bds-layout__main {
  width: 100%;

  .c-bds-layout__content--has-sidebar & {
    max-width: 900px;
    margin: 0 auto;
  }
}

.c-bds-layout__title {
  padding: 15px 20px;

  span {
    vertical-align: middle;
  }

  .c-bds-layout__content--has-sidebar & {
    width: 100%;
  }
}

.c-bds-layout__header {
  position: relative;
  position: sticky;
  grid-column-start: 1;
  grid-column-end: 3;
  z-index: core.bolt-z-index('modalBG') + 20;

  @include core.bolt-mq(medium) {
    .c-bds-layout--has-sidebar & {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
}

.c-bds-layout__header__nav {
  padding: 15px 20px;
  text-align: right;

  @include core.bolt-mq($until: 599px) {
    width: 100%;
    text-align: center;
  }

  a {
    padding: 6px 12px;
    color: #fff;

    &.active,
    &.active-path {
      display: none; // we need more room at mobile and this is the item which makes most sense to exclude since we're already there!
      border: 1px solid #fff;
      border-radius: 3px;
      box-shadow: 0 1px 3px rgba(21, 22, 25, 0.12),
        0 1px 2px rgba(21, 22, 25, 0.24);
      background: rgba(0, 0, 0, 0.1);

      @include core.bolt-mq(small) {
        display: initial;
      }

      &.active-path {
        text-decoration: none;
      }
    }

    &.c-bds-layout__hamburger {
      color: var(--bolt-color-yellow);

      > span {
        display: none;

        @include core.bolt-mq(xsmall) {
          display: initial;
        }
      }
    }
  }
}

.c-bds-layout__header__link {
  text-decoration: none;
}

.c-bds-layout__sidebar__overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: core.bolt-z-index('modalBG');
  pointer-events: none;
  background-color: var(--bolt-color-black);
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    visibility 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.c-bds-layout__sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  transform: translate3d(-$bds-sidebar-width, 0, 0);
  width: $bds-sidebar-width;
  overflow: auto;
  border-right: 1px solid #e0e2eb;
  background-color: var(--bolt-color-gray-xlight);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-overflow-scrolling: touch;

  @include core.bolt-mq($until: medium) {
    z-index: core.bolt-z-index('modal') + 30;
  }

  &:target {
    transform: translate3d(0, 0, 0);

    & ~ .c-bds-layout__sidebar__overlay {
      visibility: visible;
      opacity: 0.2;
      pointer-events: auto;

      @include core.bolt-mq(medium) {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  @include core.bolt-mq(medium) {
    transform: translate3d(0, 0, 0);
    margin-top: calc(
      var(--bolt-spacing-y-xlarge) - var(--bolt-spacing-y-medium)
    );

    @include core.bolt-mq(medium) {
      .c-bds-layout--has-sidebar & {
        grid-column-start: 1;
        grid-column-end: 2;

        grid-row-start: 2;
        grid-row-end: 4;
      }
    }
  }

  .c-bds-offcanvas {
    position: relative;

    > h2 {
      margin: 0;
      padding: 10px 20px;
      font-size: 1.11rem;
      color: #fff;
      border-bottom: 1px solid #fff;
      background-color: var(--bolt-color-navy);
    }

    .c-bds-layout__close-menu {
      position: absolute;
      top: 8px;
      right: 10px;
      color: var(--bolt-color-yellow);
    }
  }
}

.c-bds-layout__docs {
  // Styling for markdown content (classes not available)
  article {
    h2 {
      margin-bottom: 0.9rem;
      font-size: 1.33rem;
    }

    h3 {
      margin-bottom: 0.65rem;
    }
  }
}

.c-bds-layout__content {
  /* autoprefixer: ignore next */
  display: grid;
  flex-grow: 1;
  align-self: start;
  height: 100%;
  // min-height: 100%; // causes extra (unnecessary) scrolling on the homepage. can we remove?
  grid-column-start: 1;
  grid-column-end: 3;
  grid-template-columns: auto;
  justify-self: start;
  grid-template-rows: auto;
  overflow: auto;
  grid-row-start: 2;

  @include core.bolt-mq(medium) {
    .c-bds-layout--has-sidebar & {
      grid-template-columns: auto;
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }

  @include core.bolt-mq(1400px + 320px) {
    .c-bds-layout--has-sidebar & {
      grid-template-columns: auto 320px;
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }
}

.c-bds-layout__footer {
  align-self: end;
  position: relative; // Fallback positioning
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;

  grid-column-start: 1;
  grid-column-end: 1;
}

// 1. Vendor prefixes added due to limitations of Autoprefixer transpiling (esp. the old CSS grid spec used in IE 11)
.c-bds-docs {
  display: -ms-grid; /* [1] */
  display: grid;

  -ms-grid-rows: var(--bolt-spacing-x-small) auto auto auto 1fr
    var(--bolt-spacing-x-small); /* [1] */
  grid-template-rows: var(--bolt-spacing-x-small) auto auto auto 1fr var(
      --bolt-spacing-x-small
    );

  -ms-grid-columns: 1fr 90% 1fr; /* [1] */
  grid-template-columns: 1fr 90% 1fr;
  max-width: 1440px;

  margin: 0 auto;
  padding-right: var(--bolt-spacing-x-small);
  padding-left: var(--bolt-spacing-x-small);

  @include core.bolt-mq(small) {
    -ms-grid-rows: var(--bolt-spacing-x-medium) auto auto auto 1fr
      var(--bolt-spacing-x-medium); /* [1] */
    grid-template-rows: var(--bolt-spacing-x-medium) auto auto auto 1fr var(
        --bolt-spacing-x-medium
      );
  }

  @include core.bolt-mq(medium) {
    -ms-grid-columns: 1fr 75% 25% 1fr; /* [1] */
    grid-template-columns: 1fr 75% 25% 1fr;
  }
}

.c-bds-docs__page-intro {
  width: 100%;
  min-width: 0;
  max-width: 1440px;
  margin-bottom: var(--bolt-spacing-y-medium);
  -ms-grid-row: 2; /* [1] */
  -ms-grid-row-span: 1; /* [1] */
  grid-row: 2 / span 1;

  -ms-grid-column: 1; /* [1] */
  -ms-grid-column-span: 3; /* [1] */
  grid-column: 1 / span 3;

  @include core.bolt-mq(medium) {
    padding-right: var(--bolt-spacing-x-medium);
    padding-left: var(--bolt-spacing-x-medium);
  }
}

.c-bds-docs__page-content {
  width: 100%;

  min-width: 0;
  max-width: 960px;
  -ms-grid-row: 4; /* [1] */
  -ms-grid-row-span: 1; /* [1] */
  grid-row: 4 / span 1;

  -ms-grid-column: 1; /* [1] */
  -ms-grid-column-span: 3; /* [1] */
  grid-column: 1 / span 3;

  @include core.bolt-mq(medium) {
    margin-left: initial; // remove on larger screens
    padding-right: var(--bolt-spacing-x-medium);
    padding-left: var(--bolt-spacing-x-medium);

    -ms-grid-row: 4; /* [1] */
    -ms-grid-row-span: 1; /* [1] */
    grid-row: 4 / span 1;

    -ms-grid-column: 1; /* [1] */
    -ms-grid-column-span: 2; /* [1] */
    grid-column: 1 / span 2;
  }

  @include core.bolt-mq(large) {
    -ms-grid-row: 4; /* [1] */
    -ms-grid-row-span: 1; /* [1] */
    grid-row: 4 / span 1;

    -ms-grid-column: 2; /* [1] */
    -ms-grid-column-span: 1; /* [1] */
    grid-column: 2 / span 1;
  }
}

.c-bds-docs__page-schema {
  width: 100%;

  min-width: 0;
  -ms-grid-row: 5; /* [1] */
  -ms-grid-row-span: 1; /* [1] */
  grid-row: 5 / span 1;

  -ms-grid-column: 1; /* [1] */
  -ms-grid-column-span: 3; /* [1] */
  grid-column: 1 / span 3;

  @include core.bolt-mq(medium) {
    margin-left: initial; // remove on larger screens
    padding-right: var(--bolt-spacing-x-medium);
    padding-left: var(--bolt-spacing-x-medium);

    -ms-grid-row: 5; /* [1] */
    -ms-grid-row-span: 1; /* [1] */
    grid-row: 5 / span 1;

    -ms-grid-column: 1; /* [1] */
    -ms-grid-column-span: 4; /* [1] */
    grid-column: 1 / span 4;
  }

  @include core.bolt-mq(large) {
    -ms-grid-row: 5; /* [1] */
    -ms-grid-row-span: 1; /* [1] */
    grid-row: 5 / span 1;

    -ms-grid-column: 1; /* [1] */
    -ms-grid-column-span: 4; /* [1] */
    grid-column: 1 / span 4;
  }
}

.c-bds-docs__page-nav {
  margin-bottom: var(--bolt-spacing-y-large);
  -ms-grid-row: 3; /* [1] */
  -ms-grid-row-span: 1; /* [1] */
  grid-row: 3 / span 1;

  -ms-grid-column: 1; /* [1] */
  -ms-grid-column-span: 3; /* [1] */
  grid-column: 1 / span 3;

  text-align: right;

  @include core.bolt-mq(medium) {
    padding-right: var(--bolt-spacing-x-medium);

    -ms-grid-row: 4; /* [1] */
    -ms-grid-row-span: 1; /* [1] */
    grid-row: 4 / span 1;

    -ms-grid-column: 3; /* [1] */
    -ms-grid-column-span: 1; /* [1] */
    grid-column: 3 / span 1;

    text-align: left;
  }
}

.c-bds-docs__page-nav__link {
  font-size: var(--bolt-type-font-size-small);
  line-height: var(--bolt-type-line-height-small);
}

.c-bds-docs__title {
  margin-bottom: var(--var-spacing-y-medium);
  font-size: var(--bolt-type-font-size-xlarge);
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--bolt-color-black);
  line-height: var(--bolt-type-line-height-xlarge);
}

.c-bds-docs__lead {
  font-weight: var(--bolt-type-font-weight-regular);
  color: var(--bolt-color-black);
  border: 1px dotted transparent;
  border-radius: 4px;
  transition: border 0.3s ease-in-out;

  &.edit-this-readme {
    border: 1px dotted var(--m-bolt-primary);
  }

  > *:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: var(--bolt-spacing-y-xxsmall);

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2 {
    font-size: var(--bolt-type-font-size-xlarge);
    line-height: var(--bolt-type-line-height-xlarge);
  }

  h3 {
    font-size: var(--bolt-type-font-size-large);
    line-height: var(--bolt-type-line-height-large);
  }

  h4 {
    font-size: var(--bolt-type-font-size-medium);
    line-height: var(--bolt-type-line-height-medium);
  }

  h5 {
    font-size: var(--bolt-type-font-size-small);
    line-height: var(--bolt-type-line-height-small);
  }

  h6 {
    font-size: var(--bolt-type-font-size-xsmall);
    line-height: var(--bolt-type-line-height-xsmall);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  table {
    transform: translate3d(0, 0, 0);
    width: 100%;
    font-family: var(--bolt-type-font-family-code);
    font-size: var(--bolt-type-font-size-small);
    line-height: var(--bolt-type-line-height-small);
    border: 1px solid var(--bolt-color-gray-light);
    table-layout: auto;
  }

  thead {
    border-bottom: 1px solid var(--bolt-color-gray-light);
  }

  tr {
    transition: background-color 0.1s ease;
  }

  tr + tr {
    border-top: 1px solid var(--bolt-color-gray-light);
  }

  tr:hover {
    background-color: rgba(core.bolt-color(gray, xlight), 0.5);
  }

  th {
    padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
    font-size: var(--bolt-type-font-size-xsmall);
    font-weight: var(--bolt-type-font-weight-semibold);
    color: var(--bolt-color-gray-dark);
    line-height: var(--bolt-type-line-height-xsmall);
    text-align: left;
    text-transform: uppercase;
    vertical-align: bottom;
    background-color: var(--bolt-color-gray-xlight);
  }

  td {
    padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
    vertical-align: top;
  }
}

.c-bds-docs-list {
  margin: 0;
  list-style-position: inside;
  list-style-type: none;

  & > ul {
    margin-bottom: 0;
  }
}

.c-bds-docs-list__child {
  margin: 0 0 0 1.25rem;
  list-style-position: outside;
  list-style-type: square;

  &--level-3,
  &--level-4,
  &--level-5 {
    margin-bottom: 0;

    ul {
      margin-bottom: 0;
    }
  }
}

.c-bds-docs__heading-fragment a {
  display: flex;
  position: relative;

  &:before {
    content: '#';
    align-self: center;
    opacity: 0.3;
    position: absolute;
    top: 50%; // Fix IE11 vcentering
    inset-inline-start: -1.5rem;
    transform: translateY(-50%); // Fix IE11 vcentering
    width: 1.5rem;
    font-size: var(--bolt-type-font-size-small);
    color: inherit;
    line-height: var(--bolt-type-line-height-small);
    text-align: center;
    text-decoration: none;
    transition: opacity 0.2s ease;
  }
}

.c-bds-docs__heading-fragment a:hover:before {
  opacity: 0.6;
  text-decoration: none;
  transition: opacity 0.2s ease;
}

// Sticky positioning of side nav links, if supported
.c-bds-docs__page-nav-list {
  position: sticky;
  top: var(--bolt-spacing-y-large);
}

.c-bds-home-page {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.c-bds-home-page__content {
  position: relative;
  padding: var(--bolt-spacing-y-xlarge) 0;
}

.c-bds-home-shape {
  opacity: bolt-opacity(40);
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100vw;
  min-width: 951.5px;
  pointer-events: none;
  animation-duration: 12s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  fill: var(--bolt-color-navy-light);

  @media (prefers-reduced-motion) {
    animation: none !important;
  }
}

.c-bds-home-shape--01 {
  right: 0;
  transform: translate3d(30%, -30%, 0);
  animation-name: a-bds-home-shape-01-fade;
}

.c-bds-home-shape--02 {
  left: 0;
  transform: translate3d(-60%, 55vh, 0);
  animation-name: a-bds-home-shape-02-fade;
}

@keyframes a-bds-home-shape-01-fade {
  0% {
    opacity: bolt-opacity(40);
    transform: translate3d(30%, -30%, 0);
  }

  50% {
    opacity: 1;
    transform: translate3d(30%, calc(-30% + 2vh), 0) scale(1.02);
  }

  100% {
    opacity: bolt-opacity(40);
    transform: translate3d(30%, -30%, 0);
  }
}

@keyframes a-bds-home-shape-02-fade {
  0% {
    opacity: bolt-opacity(40);
    transform: translate3d(-55%, 55vh, 0);
  }

  50% {
    opacity: 1;
    transform: translate3d(-55%, calc(55vh + 2vh), 0) scale(1.02);
  }

  100% {
    opacity: bolt-opacity(40);
    transform: translate3d(-55%, 55vh, 0);
  }
}

.c-bds-home-highlights {
  display: grid;
  grid-gap: var(--bolt-spacing-x-small);
  grid-row-gap: var(--bolt-spacing-y-small);
  grid-template-columns: repeat(1, 1fr);
  margin: 0;
  padding: 0;
  list-style: none;

  @include core.bolt-mq(small) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include core.bolt-mq(large) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.c-bds-home-highlights__item {
  display: flex;
  align-items: center;
  position: relative;
}

.c-bds-home-highlights__item__media {
  position: relative;
  transform: translate3d(-5%, 0, 0);
  width: 40%;
}

.c-bds-home-highlights__item--color {
  .c-bds-home-highlights__item__media {
    fill: var(--bolt-color-orange);
  }
}

.c-bds-home-highlights__item--spacing {
  @include core.bolt-mq($until: small) {
    justify-content: flex-end;
  }

  .c-bds-home-highlights__item__media {
    fill: var(--bolt-color-teal);
  }
}

.c-bds-home-highlights__item--typography {
  .c-bds-home-highlights__item__media {
    fill: var(--bolt-color-navy-light);
  }
}

.c-bds-home-highlights__item__heading {
  position: absolute;

  a {
    display: block;
    padding-top: var(--bolt-spacing-y-large);
    padding-right: var(--bolt-spacing-x-large);
    padding-bottom: var(--bolt-spacing-y-large);
    padding-left: var(--bolt-spacing-x-large);
    font-family: var(--bolt-type-font-family-headline);
    font-size: var(--bolt-type-font-size-large);
    font-weight: var(--bolt-type-font-weight-semibold);
    line-height: var(--bolt-type-line-height-large);
    text-decoration: none;
    text-shadow: 0 0 1em core.bolt-color(black);
    transition: text-shadow var(--bolt-transition);

    &:hover,
    &:focus {
      text-shadow: 0 0 0.5em var(--bolt-color-black);
    }
  }
}

[data-bds-animate] {
  transform: translate3d(0, 0, 0);
  animation-duration: 1s;
  animation-fill-mode: both;

  @media (prefers-reduced-motion) {
    animation: none !important;
  }
}

[data-bds-animate*='delayed'] {
  animation-delay: 1s;
}

[data-bds-animate*='long'] {
  animation-duration: 2s;
}

[data-bds-animate*='infinite'] {
  animation-iteration-count: infinite;
}

[data-bds-animate*='pulse'] {
  animation-name: a-bds-pulse;
}

@keyframes a-bds-pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

[data-bds-animate*='fade-in'] {
  animation-name: a-bds-fade-in;
}

@keyframes a-bds-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

[data-bds-animate*='fade-in-down'] {
  animation-name: a-bds-fade-in-down;
}

@keyframes a-bds-fade-in-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-bds-animate*='fade-in-up'] {
  animation-name: a-bds-fade-in-up;
}

@keyframes a-bds-fade-in-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
