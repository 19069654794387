////
/// @group Tools: Colors
////

/* ------------------------------------ *\
  Color Palette
\* ------------------------------------ */

@use '@bolt/core/styles/01-settings/settings-colors/settings-colors' as settings-colors;
@use '@bolt/core/styles/02-tools/tools-map-deep-get/tools-map-deep-get' as tools-map-deep-get;

/// Helper functions for applying global color swatches stored in Sass Maps
/// @param {string} $color - Bolt color base (e.g. navy)
/// @param {string} $tone [$bolt-color-default] - Bolt color tone (e.g. light)
/// @return {HSL|RGB} The mapped bolt color value
@function bolt-color($color, $tone: settings-colors.$bolt-color-default) {
  @return tools-map-deep-get.map-deep-get(settings-colors.$bolt-colors, '#{$color}', $tone);
}

/// Mixin to return color value
/// @param {string} $color - Bolt color base (e.g. navy)
/// @param {string} $tone [$bolt-color-default] - Bolt color tone (e.g. light)
/// @param {string} $important [null] - If 'important', then !important will be added onto the declaration string value
/// @example scss - bolt-color mixin
/// .element {
///    @include bolt-color(navy, light, important);
/// }
@mixin bolt-color($color, $tone: settings-colors.$bolt-color-default, $important: null) {
  @if $important == important {
    $important: !important;
  }

  /* stylelint-disable-next-line */
  color: tools-map-deep-get.map-deep-get(settings-colors.$bolt-colors, $color, $tone) $important;
}
