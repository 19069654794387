/* ------------------------------------ *\
   card-replacement Body
\* ------------------------------------ */

@use 'sass:list';
@use '@bolt/core' as core;

@forward '../card-replacement-settings-and-tools';

@include core.bolt-repeat-rule(('bolt-card-replacement-body', ':host')) {
  position: relative; // Position is needed to bring body's stacking order above background.
  width: 100%;
}

.c-bolt-card_replacement__body {
  display: block;
  margin: 0;
}

// Spacing prop
$bolt-card-replacement-spacing: small, medium;

@each $size in core.$bolt-spacing-multiplier-system {
  $size-name: list.nth($size, 1);

  @if list.index($bolt-card-replacement-spacing, $size-name) {
    .c-bolt-card_replacement__body--spacing-#{$size-name} {
      padding: var(--bolt-spacing-y-#{$size-name})
        var(--bolt-spacing-x-#{$size-name});
    }
  }
}
