// https://github.com/benfurfie/tailwindcss-line-length was a good source of inspiration

.u-bolt-line-length-optimal {
  max-width: 80ch;
}

.u-bolt-line-length-xsmall {
  max-width: 18.75rem;
}

.u-bolt-line-length-small {
  max-width: 25rem;
}

.u-bolt-line-length-medium {
  max-width: 31.25rem;
}

.u-bolt-line-length-large {
  max-width: 37.5rem;
}

.u-bolt-line-length-large {
  max-width: 43.75rem;
}

.u-bolt-line-length-xlarge {
  max-width: 50rem;
}
