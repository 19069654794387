@import '@bolt/core';

/**
 * Image Element
 *
 * Dev notes:
 * 1. `content-visibility` conflicts with how we track scroll position in TOC and smooth-scroll components, @see https://pegadigitalit.atlassian.net/browse/DS-696
 */

.e-bolt-image {
  max-width: 100%;
  height: auto;
  object-fit: var(--e-bolt-image-fit, cover);
  object-position: var(--e-bolt-image-position, center);
  // content-visibility: auto; /* [1] */
}

.e-bolt-image--fill {
  display: block;
  width: 100%;
}

.e-bolt-image--bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
}
