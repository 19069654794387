$bolt-namespace: bolt;
.c-base-react-paginate {
  display: flex;
  flex-wrap: wrap; // @todo: component needs better responsive design, for now just make sure it doesn't overflow the container
  margin: 0;
  padding: 0;
  gap: var(--bolt-spacing-x-xsmall);
  list-style: none;
}

.c-base-react-paginate__item {
  margin: 0;
  padding: 0;

  &.is-disabled {
    visibility: hidden;
  }
}

.c-base-react-paginate__item-button {
  transition: none;

  &.c-base-react-paginate__item-button--active {
    cursor: default;

    &:active {
      outline: 0;
    }

    &:hover {
      transform: none;

      &:after {
        opacity: 0;
      }
    }
  }

  &:not(.c-base-react-paginate__item-button--active) {
    --e-bolt-button-text-color: var(--m-bolt-link);
    --e-bolt-button-bg-color: transparent;

    &:before,
    &:after {
      display: none;
    }

    &:hover {
      transform: translate3d(0, 0, 0);
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 3px;

      &:after {
        opacity: 0.2;
        box-shadow: none;
        background-color: var(--bolt-color-gray);
      }

      [class*='t-bolt-'] & {
        &:after {
          opacity: 0.2;
          box-shadow: none;
          background-color: var(--bolt-color-gray);
        }
      }
    }
  }

  &.c-base-react-paginate__item-button--disabled {
    --e-bolt-button-text-color: var(--m-bolt-neutral);
    opacity: 50%;
    pointer-events: none;
  }

  &.c-base-react-paginate__item-button--disabled {
    visibility: hidden;
  }
}

.c-base-react-paginate--align-center {
  justify-content: center;
}

.c-base-react-paginate--align-start {
  justify-content: flex-start;
}

.c-base-react-paginate--align-end {
  justify-content: flex-end;
}
