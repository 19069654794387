html {
  scroll-behavior: smooth;

  @media (prefers-reduced-motion) {
    scroll-behavior: auto;
  }
}

[id] {
  scroll-margin-top: var(--bolt-page-offset);
}

:root {
  --bolt-page-sticky-height: 0; // overwritten via js in ./_999-smooth-scroll.js
  --bolt-page-scroll-offset: var(--bolt-spacing-y-medium);
  --bolt-page-offset: calc(
    var(--bolt-page-sticky-height) + calc(var(--bolt-page-scroll-offset))
  );
}
