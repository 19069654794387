/* ------------------------------------ *\
   Table resets
\* ------------------------------------ */

@use '@bolt/core' as core;

table {
  border-spacing: 1px;
  border-collapse: separate;
  color: var(--m-bolt-body);
}

thead,
tfoot {
  white-space: nowrap;
}

th,
td {
  font-size: var(--bolt-type-font-size-small);
  line-height: var(--bolt-type-line-height-small);
  text-align: start;
}

th,
thead td,
tfoot td {
  padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-small);
  font-family: var(--bolt-type-font-family-headline);
  font-weight: var(--bolt-type-font-weight-semibold);
}

th,
thead td {
  color: var(--m-bolt-headline);
  background-color: rgba(core.bolt-color(gray), 0.1);
}

tbody th,
td {
  padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
  vertical-align: top;
}
