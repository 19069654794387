////
/// @group Tools: Utilities
////

/* ------------------------------------ *\
  see `_settings-opacity.scss`
\* ------------------------------------ */

/// Bolt Opacity mixin
/// @param {string} $value
/// @param {boolean} $important [false]
/// @example scss - bolt-opacity mixin
/// .element {
///    @include bolt-opacity(80);
/// }


@use 'sass:map';
@use '@bolt/core/styles/01-settings/settings-opacity/settings-opacity' as settings-opacity;

@mixin bolt-opacity($value, $utility: false) {
  $important: '';

  @if $utility {
    $important: '!important';
  }

  @if map.has-key(settings-opacity.$bolt-opacities, #{$value}) {
    opacity: map.get(settings-opacity.$bolt-opacities, #{$value}) #{$important};
  } @else {
    @error 'A value, #{$value}, was passed into @include bolt-opacity() that is not defined in $bolt-opacities';
  }
}

/// Bolt Opacity function
/// @param {string} $value
/// @example scss - bolt-opacity function
/// .element {
///    opacity: bolt-opacity(80);
/// }
@function bolt-opacity($value) {
  @if map.has-key(settings-opacity.$bolt-opacities, #{$value}) {
    @return map.get(settings-opacity.$bolt-opacities, #{$value})
  } @else {
    @error 'A value, #{$value}, was passed into bolt-opacity() that is not defined in $bolt-opacities';
  }
}
