/* ------------------------------------ *\
   #POSITION UTILITIES
\* ------------------------------------ */

.u-bolt-position-static {
  position: static !important;
}

.u-bolt-position-fixed {
  position: fixed !important;
}

.u-bolt-position-absolute {
  position: absolute !important;
}

.u-bolt-position-relative {
  position: relative !important;
}

.u-bolt-position-sticky {
  position: sticky !important;
}
