@import '@bolt/core';

/* ------------------------------------ *\
   Pagination
\* ------------------------------------ */

$bolt-pagination-transition: var(--bolt-transition);
$bolt-pagination-item-size: calc(var(--bolt-type-line-height-regular) * 1em);

// Register Custom Block Element
@include bolt-custom-element('bolt-pagination', block, medium);

.c-bolt-pagination {
  display: flex;
  flex-wrap: wrap; // @todo: component needs better responsive design, for now just make sure it doesn't overflow the container
  margin: 0;
  padding: 0;
  list-style: none;
}

// [Mai] Alignment options, use center as default.
.c-bolt-pagination--align-center {
  justify-content: center;
}

.c-bolt-pagination--align-start {
  justify-content: flex-start;
}

.c-bolt-pagination--align-end {
  justify-content: flex-end;
}

.c-bolt-pagination__item {
  display: block;
  min-width: $bolt-pagination-item-size;
  height: $bolt-pagination-item-size;
  margin: 0;
  padding: 0 2px; // [Mai] This will give big numbers (over 3 digits) some padding left and right so it's not touching the edge.
  font-family: var(--bolt-type-font-family-headline);
  font-size: var(--bolt-type-font-size-small);
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--m-bolt-link);
  line-height: $bolt-pagination-item-size;
  text-align: center;
  text-decoration: none;
  border-radius: bolt-border-radius(full);
  background-color: transparent;
  -webkit-backface-visibility: hidden; // Removes the 1px jump with opacity transition
  transition: opacity $bolt-pagination-transition;
  white-space: nowrap;

  &:hover {
    opacity: $bolt-global-link-hover-opacity;
  }

  &:active,
  &:focus:active {
    opacity: $bolt-global-link-active-opacity;
  }

  &.is-current {
    opacity: 1; // Current item is not a link so its styling shouldn't change based on state.
    color: var(--m-bolt-bg);
    background-color: var(--m-bolt-link);
  }
}

.c-bolt-pagination__item--prev,
.c-bolt-pagination__item--next {
  min-width: auto;

  @include bolt-mq($until: small) {
    // [Mai] This is the opposite of @include bolt-mq(small).
    .c-bolt-pagination__text {
      @include bolt-visuallyhidden;
    }
  }
}

.c-bolt-pagination__item--prev {
  margin-right: var(--bolt-spacing-x-small);

  @include bolt-mq(small) {
    .c-bolt-pagination__icon {
      margin-right: var(--bolt-spacing-x-xxsmall);
    }
  }
}

.c-bolt-pagination__item--next {
  margin-left: var(--bolt-spacing-x-small);

  @include bolt-mq(small) {
    .c-bolt-pagination__icon {
      margin-left: var(--bolt-spacing-x-xxsmall);
    }
  }
}

.c-bolt-pagination__item--ellipsis {
  &:hover,
  &:active,
  &:focus:active {
    opacity: 1;
  }
}
