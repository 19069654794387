@import '@bolt/core';

/* ------------------------------------ *\
    Bolt Code Snippet
\* ------------------------------------ */

.c-bolt-code-snippet {
  overflow: hidden;
  margin: 0 0 var(--bolt-spacing-y-medium) 0;
  border-radius: bolt-border-radius(small);

  &:last-child {
    margin-bottom: 0;
  }

  pre {
    max-height: 50vh;
    border: 0;
    box-shadow: none;
  }
}

.c-bolt-code-snippet__header,
.c-bolt-code-snippet:before {
  padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-small);
  font-family: var(--bolt-type-font-family-headline);
  font-size: var(--bolt-type-font-size-xsmall);
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--bolt-color-gray-dark);
  line-height: var(--bolt-type-line-height-xsmall);
  border-bottom: 1px solid var(--bolt-color-white);
  background: inherit;
}

.c-bolt-code-snippet__header {
  display: flex;
  justify-content: space-between;
}

.c-bolt-code-snippet[data-mode='dark'] {
  &:before {
    border-bottom-color: var(--bolt-color-gray-dark);
  }

  .c-bolt-code-snippet__header {
    color: var(--bolt-color-gray);
    border-bottom-color: var(--bolt-color-gray-dark);
  }
}

.c-bolt-code-snippet:not([data-hide-lang-label][data-hide-copy], [data-bolt-ready]):before {
  content: '\00a0'; // Non-breaking space provides same height as header (with text) once JS loads, preventing layout shift.
  display: block;
}

.c-bolt-code-snippet__copy {
  margin-left: auto;

  .e-bolt-text-link:focus {
    box-shadow: 0 0 0 1px var(--bolt-color-navy-light);
    outline: none; // Fix a Safari bug which the focus outline does not appear.
  }
}
