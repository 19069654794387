@use '@bolt/core' as core;

// Shared base text styles used across all headline, subheadline, eyebrow and vanilla DIY text components
@mixin bolt-headline() {
  @include core.bolt-deprecated-type-vars;
  @include bolt-headline-font-weight;
  @include bolt-headline-font-style;
  @include bolt-headline-text-alignment;
  @include bolt-headline-text-transform;

  & {
    display: block; // hotfix for http://vjira2:8080/browse/WWWD-4696 when Headlines are non-block elements (ex. <span> tags)
  }

  // display: flex; // @todo: uncomment when fixing headline flex behavior
  // flex-flow: row nowrap; // @todo: uncomment when fixing headline flex behavior

  &--bulleted {
    display: inline-flex;
    align-items: flex-start;
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.c-bolt-headline__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center; // different alignment than if align-items is set to center
  position: relative;

  // adjust vertical alignment of icons, given the unknown line-height / font-size
  // @todo: re-evaluate this workaround once we're outputting the CSS custom prop values of current font size + line height being used
  top: 0.2ex;

  &--position-before,
  &--position-left {
    margin-inline-end:  var(--bolt-spacing-x-xsmall);
  }

  &--position-after,
  &--position-right {
    margin-inline-start: var(--bolt-spacing-x-xsmall);
  }
}

/** Headline Font Weight Options **/
@mixin bolt-headline-font-weight {
  &--light {
    font-weight: var(--bolt-type-font-weight-light);
  }

  &--regular {
    font-weight: var(--bolt-type-font-weight-regular);
  }

  &--semibold {
    font-weight: var(--bolt-type-font-weight-semibold);
  }

  &--bold {
    font-weight: var(--bolt-type-font-weight-bold);
  }
}

/** Font Style Options **/
@mixin bolt-headline-font-style {
  // Font style variations
  &--normal {
    font-style: normal;
  }

  &--italic {
    font-style: italic;
  }
}

/** Left, center and right text alignment options **/
@mixin bolt-headline-text-alignment {
  &--left {
    justify-content: flex-start;
    text-align: start;
  }

  &--center {
    justify-content: center;
    text-align: center;
  }

  &--right {
    justify-content: flex-end;
    text-align: end;
  }
}

@mixin bolt-headline-text-transform {
  &--uppercase {
    text-transform: uppercase;
    letter-spacing: var(--bolt-type-letter-spacing-wide);
  }

  &--lowercase {
    text-transform: lowercase;
  }

  &--capitalize {
    text-transform: capitalize;
  }
}
