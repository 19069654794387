$www-calculator-logo-icon-size: 38px; // Hard px is used to avoid subpixel issues on transition
$www-calculator-logo-spacing-size: 3px; // Hard px is used to avoid subpixel issues on transition

@use '@bolt/core' as core;

.c-www-calculator-logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-around;
  width: $www-calculator-logo-icon-size * 2 + $www-calculator-logo-spacing-size *
    4;
  height: $www-calculator-logo-icon-size * 2 + $www-calculator-logo-spacing-size *
    4;
  margin-bottom: var(--bolt-spacing-y-medium);
  color: var(--bolt-color-navy);

  &:last-child {
    margin-bottom: 0;
  }

  span {
    transform: scale(0) rotate(0deg);
    width: $www-calculator-logo-icon-size + $www-calculator-logo-spacing-size;
    height: $www-calculator-logo-icon-size + $www-calculator-logo-spacing-size;
    font-size: $www-calculator-logo-icon-size;
    line-height: 1;
    text-align: center;
    border-radius: core.bolt-border-radius(full);
    background-color: core.bolt-color(white);
    transition: transform var(--bolt-transition);

    @at-root bolt-band.is-ready #{&} {
      animation: 3s ease-in-out 0.5s forwards a-www-calculator-logo-spin;
      animation-delay: 250ms;
    }

    @media (prefers-reduced-motion) {
      transform: none !important;
      animation: none !important; // Do not animate if the user has expressed their preference for reduced motion.
    }

    &:nth-child(2) {
      background-color: var(--bolt-color-yellow);
    }

    &:nth-child(3) {
      background-color: var(--bolt-color-teal);
    }
  }
}

@keyframes a-www-calculator-logo-spin {
  1% {
    transform: scale(0) rotate(0deg);
  }

  6% {
    transform: scale(1) rotate(90deg);
  }

  100% {
    transform: scale(1) rotate(2160deg);
  }
}

.c-www-calculator-fade-in-content {
  bolt-grid-item {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    transition: transform var(--bolt-transition), opacity var(--bolt-transition);

    @at-root bolt-band.is-ready #{&} {
      animation: 0.5s ease-in-out 0.5s forwards a-www-calculator-fade-in-down;
    }

    @media (prefers-reduced-motion) {
      opacity: 1;
      transform: none !important;
      animation: none !important; // Do not animate if the user has expressed their preference for reduced motion.
    }
  }
}

@keyframes a-www-calculator-fade-in-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Results Graph
.c-www-results-graph {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--bolt-spacing-y-medium);

  &:last-child {
    margin-bottom: 0;
  }

  @include core.bolt-mq(small) {
    flex-direction: row;
  }

  &__icon,
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__box {
    width: 100%;
    padding: var(--bolt-spacing-y-medium) var(--bolt-spacing-x-medium);
    border-radius: core.bolt-border-radius(small);

    &--gray {
      color: var(--bolt-color-white);
      background-color: var(--bolt-color-gray-dark);
    }

    &--navy {
      color: var(--bolt-color-white);
      background-color: var(--bolt-color-navy-light);
    }

    &--teal {
      color: var(--bolt-color-white);
      background-color: var(--bolt-color-teal);
    }

    &--orange {
      color: var(--bolt-color-white);
      background-color: var(--bolt-color-orange);
    }
  }

  &__icon {
    padding: var(--bolt-spacing-y-xsmall) var(--bolt-spacing-x-xsmall);
    color: var(--bolt-color-gray-dark);

    > * {
      transform: rotate(270deg); // Rotating it to be arrow right

      @include core.bolt-mq(small) {
        transform: rotate(180deg); // Rotating it to be arrow down
      }
    }
  }
}

.c-www-results-stat {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  &__headline {
    display: block;
    font-size: var(--bolt-type-font-size-large);
    font-weight: var(--bolt-type-font-weight-bold);
    line-height: var(--bolt-type-line-height-large);

    &--xxlarge {
      font-size: var(--bolt-type-font-size-xlarge);
      line-height: var(--bolt-type-line-height-xlarge);
    }
  }

  &__text {
    display: block;
    font-size: var(--bolt-type-font-size-small);
    font-weight: var(--bolt-type-font-weight-regular);
    line-height: var(--bolt-type-line-height-small);
  }
}

.c-www-calculator-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
}

.c-www-calculator-background__image {
  position: absolute;
  top: 0;
  right: -150vw;
  width: 300vw;
  max-width: 1000px;
  pointer-events: none;

  @include core.bolt-mq(xsmall) {
    right: -80vw;
    width: 300vw;
    max-width: 1020px;
  }

  @include core.bolt-mq(small) {
    right: -50vw;
    width: 200vw;
    max-width: 1040px;
  }

  @include core.bolt-mq(medium) {
    right: 0;
    width: 75vw;
    max-width: 1080px;
  }
}

.c-www-calculator-nojs-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: var(--bolt-spacing-x-medium);
  bottom: 0;
  left: var(--bolt-spacing-x-medium);
  z-index: 1; // Moves the stacking order above the band content.
  padding: var(--bolt-spacing-y-medium) var(--bolt-spacing-x-medium);
  color: var(--bolt-color-black);
  text-align: center;
  border-left: 3px solid var(--bolt-color-warning);
  border-radius: core.bolt-border-radius(small);
  background-color: var(--bolt-color-warning-light);
}
