@use '_shared-styles' as shared-styles;

.c-bds-docs__display-focus {
  @extend %bolt-demo-item-border;
  @extend %bolt-demo-item-background;
  height: 50px;
}

.c-bds-docs__display-demo {
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}
