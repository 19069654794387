@use '@bolt/core' as core;

.c-bolt-toolbar {
  display: block;
  position: relative;
}

.c-bolt-toolbar--sticky {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: core.bolt-z-index(nav);
}

.c-bolt-toolbar__inner {
  @include core.bolt-full-bleed;

  display: block;
  padding-top: var(--bolt-spacing-y-small);
  padding-right: calc(
    var(--bolt-spacing-x-xsmall) + var(--bolt-spacing-x-small)
  ); // Matches the padding on Page Header.
  padding-bottom: var(--bolt-spacing-y-small);
  padding-left: calc(
    var(--bolt-spacing-x-xsmall) + var(--bolt-spacing-x-small)
  ); // Matches the padding on Page Header.
  color: inherit;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);

  @include core.bolt-mq(medium) {
    padding-right: var(--bolt-page-padding-x);
    padding-left: var(--bolt-page-padding-x);
  }
}

.c-bolt-toolbar__items {
  display: grid;

  > * + * {
    margin-top: var(--bolt-spacing-y-small);
  }

  @include core.bolt-mq(xsmall) {
    grid-template-columns: auto 1fr auto;
    align-items: center;

    > * + * {
      margin-top: 0;
      margin-inline-start: var(--bolt-spacing-x-small);
    }
  }
}

.c-bolt-toolbar__item--before {
  display: grid;
  align-items: start;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'signifier .'
    'signifier breadcrumbs'
    'signifier .';
}

.c-bolt-toolbar__title {
  display: flex;
  align-items: center;
  margin-inline-end: calc(var(--bolt-spacing-x-medium) * 0.8);
  grid-area: signifier;
}

.c-bolt-toolbar__title-text {
  font-size: var(--bolt-type-font-size-regular);
  font-weight: var(--bolt-type-font-weight-semibold);
  line-height: var(--bolt-type-line-height-regular);
}

.c-bolt-toolbar__breadcrumbs {
  grid-area: breadcrumbs;
}

.c-bolt-toolbar--purple,
.c-bolt-toolbar--pink {
  background-image: linear-gradient(
    90deg,
    var(--bolt-color-wine) 0%,
    var(--bolt-color-berry) 100%
  );
}

.c-bolt-toolbar--navy {
  background-image: linear-gradient(
    90deg,
    var(--bolt-color-navy) 0%,
    var(--bolt-color-navy-light) 100%
  );
}

.c-bolt-toolbar--teal {
  background-image: linear-gradient(
    90deg,
    var(--bolt-color-navy-dark) 0%,
    var(--bolt-color-teal) 100%
  );
}

.c-bolt-toolbar--orange {
  background-image: linear-gradient(
    270deg,
    var(--bolt-color-orange) 0%,
    var(--bolt-color-orange-dark) 100%
  );
}
