/* ------------------------------------ *\
  #TEXT ALIGN UTILITIES
\* ------------------------------------ */

.u-bolt-text-align-right, // deprecated
.u-bolt-text-align-end {
  text-align: right !important;
  text-align: end !important;
}

.u-bolt-text-align-left, // deprecated
.u-bolt-text-align-start {
  text-align: left !important;
  text-align: start !important;
}

.u-bolt-text-align-center {
  text-align: center !important;
}

.u-bolt-text-align-justify {
  text-align: justify !important;
}

$bolt-alignment-props: ('start', 'center', 'end', 'justify');

@include export-data('alignments.bolt.json', $bolt-alignment-props);
