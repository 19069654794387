/* ==========================================================================
   #BOLT BARE LIST OBJECT
   ========================================================================== */

/**
 * The bare-list object simply remove default bullets and spacing.
 *
 * 1. Allows custom element wrapper to style nested content till shadow dom slot added.
 */

bolt-bare-list > *, /* [1] */
.o-bolt-bare-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
