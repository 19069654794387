$choices-font-size-lg: 14px;
$choices-font-size-md: 14px; // was 14px - increasing size to prevent mobile input zoom
$choices-font-size-sm: 12px;
$choices-guttering: var(--bolt-spacing-x-xsmall);
$choices-border-radius: 4px;
$choices-border-radius-item: 20px;
$choices-bg-color: transparent;
$choices-bg-color-disabled: var(--bolt-color-gray-xlight);
$choices-bg-color-dropdown: var(--bolt-color-white);
$choices-text-color: var(--bolt-color-navy-dark);
$choices-keyline-color: rgba(#fff, 0);
$choices-primary-color: #00bcd4;
$choices-disabled-color: #eaeaea;
$choices-highlight-color: $choices-primary-color;
$choices-button-icon-path: '';
$choices-button-dimension: 8px;
$choices-button-offset: 8px;

@import 'choices.js/src/styles/choices';

bolt-select {
  display: inline-block;
}
