@import '@bolt/core';

.c-bds-offcanvas {
  padding: var(--bolt-spacing-y-large) var(--bolt-spacing-x-medium);
}

.c-bds-offcanvas__list {
  margin-bottom: 0;
  margin-left: 0;
  list-style: none;
}

.c-bds-offcanvas__link {
  padding-top: var(--bolt-spacing-y-xxsmall);
  padding-bottom: var(--bolt-spacing-y-xxsmall);
  font-size: var(--bolt-type-font-size-small);
  line-height: var(--bolt-type-line-height-small);
  text-decoration: none;

  &.is-active {
    font-weight: var(--bolt-type-font-weight-bold);
  }
}

.c-bds-offcanvas {
  &__link {
    .c-bds-offcanvas__list--depth-2 & {
      display: block;
    }
  }

  &__list {
    &--depth-3,
    &--depth-4 {
      margin-left: var(--bolt-spacing-x-small);
    }
  }
}

.c-bds-offcanvas__collapsible {
  overflow: hidden;
  margin-bottom: 0;
  outline: none;
}

.c-bds-offcanvas__collapsible-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 0;
  padding-top: var(--bolt-spacing-y-xxsmall);
  padding-bottom: var(--bolt-spacing-y-xxsmall);
  font-size: var(--bolt-type-font-size-small);
  line-height: var(--bolt-type-line-height-small);
  outline: none;

  &:before {
    content: '';
    opacity: 0.2;
    position: absolute;
    top: 100%;
    bottom: -999px;
    left: 0;
    width: 1px;
    background-color: currentColor;
  }
}
