/* ------------------------------------ *\
   Pre and Code resets
\* ------------------------------------ */

pre,
code {
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: break-word;
  color: var(--bolt-color-black);
  text-align: left;
  border-radius: bolt-border-radius(small);
}

pre {
  display: block;
  overflow: auto;
  padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-small);
  border: 1px solid var(--bolt-color-white);
  box-shadow: 0 0 6px var(--bolt-color-gray-xlight);
  background-color: var(--bolt-color-gray-xlight);
}

code {
  display: inline;
  margin: 0;
  padding: 2px 0.25rem 2px 0.3rem; // [Mai] 0.25rem on the right offsets the 0.05rem letter-spacing.
  font-family: var(--bolt-type-font-family-code);
  font-size: 0.8em;
  font-weight: var(--bolt-type-font-weight-regular);
  line-height: var(--bolt-type-line-height-small);
  letter-spacing: 0.05rem;
  border: 1px solid var(--bolt-color-white);
  box-shadow: 0 0 6px var(--bolt-color-gray-xlight);
  background-color: var(--bolt-color-gray-xlight);
}

pre code {
  display: block;
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
}
