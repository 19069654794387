bolt-carousel:not(.is-ready) {
  > [slot='previous-btn'],
  > [slot='next-btn'] {
    display: none;
  }
}

bolt-carousel.is-ready
  .c-bolt-carousel__button:not(.c-bolt-carousel__button--hidden, .c-bolt-carousel__button--disabled) {
  opacity: 1;
}

bolt-carousel:not(.is-ready) > [slot='previous-btn'],
bolt-carousel:not(.is-ready) > [slot='next-btn'] {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.c-bolt-carousel__button {
  @include bolt-z-index(contentTop);

  display: flex;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  border-radius: bolt-border-radius(full);
  transition: all 0.2s ease;

  .e-bolt-button {
    pointer-events: none;
  }

  &.c-bolt-carousel__button--outside {
    top: calc(
      50% - var(--bolt-spacing-y-medium) + #{$bolt-carousel-bullet-height}
    );

    @at-root bolt-carousel[no-pagination] & {
      top: 50%;
    }

    &:focus:not(:hover) {
      box-shadow: 0 -1px 2px var(--m-bolt-bg), 0 1px 2px var(--m-bolt-bg),
        0 0 5px rgb(59, 153, 252), 0 -1px 0.65rem rgb(59, 153, 252),
        0 1px 0.65rem rgb(59, 153, 252);
    }

    &.c-bolt-carousel__button--previous {
      left: var(--bolt-spacing-x-medium);
      transform: translateX(-50%) translateY(-50%);

      @include bolt-mq(medium) {
        left: var(--bolt-spacing-x-large);
      }

      &.is-pressed {
        transform: translateX(-50%) translateY(-50%) translateY(1px);
      }
    }

    &.c-bolt-carousel__button--next {
      right: var(--bolt-spacing-x-medium);
      transform: translateX(50%) translateY(-50%);

      @include bolt-mq(medium) {
        right: var(--bolt-spacing-x-large);
      }

      &.is-pressed {
        transform: translateX(50%) translateY(-50%) translateY(1px);
      }
    }
  }

  &.c-bolt-carousel__button--inside {
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 0.5rem);
      width: 4px;
      height: 0.9rem; // This is a magic number for fixing the subpixel issue with the CSS chevron. Don't change this unless you have a completely different method for drawing the chevron.
      border-radius: 1px;
      box-shadow: 3px -1px 3px 0px rgba(black, 0.025),
        3px 1px 3px 0px rgba(black, 0.025);
      background-color: var(--bolt-color-white);
      will-change: box-shadow;
      transition: box-shadow var(--bolt-transition);
      transform-origin: 50% 50%;
    }

    &.c-bolt-carousel__button--previous {
      right: auto;
      left: 0;

      &:before {
        left: calc(50% - 0.5rem);
        transform: rotate(135deg) translateX(150%) translateY(0) translateZ(0)
          perspective(1px);
      }

      &:after {
        left: calc(50% - 0.5rem);
        transform: rotate(-135deg) translateX(150%) translateY(0) translateZ(0)
          perspective(1px);
      }
    }

    &.c-bolt-carousel__button--next {
      right: 0;
      left: auto;

      &:before {
        right: calc(50% - 0.5rem);
        transform: rotate(45deg) translateX(150%) translateY(0) translateZ(0)
          perspective(1px);
      }

      &:after {
        right: calc(50% - 0.5rem);
        transform: rotate(-45deg) translateX(150%) translateY(0) translateZ(0)
          perspective(1px);
      }
    }

    &,
    &:hover,
    &:active,
    &:focus,
    &.is-pressed,
    &.is-pressed:focus {
      top: 0;
      transform: none;
      width: 3.5rem;
      height: calc(100% - var(--bolt-spacing-y-medium) - 8px);
      border-radius: 0;

      @at-root bolt-carousel[no-pagination] & {
        height: 100%;
      }
    }

    &:hover,
    &:focus {
      &:before,
      &:after {
        box-shadow: 3px -1px 3px 0px rgba(black, 0.1),
          3px 1px 3px 0px rgba(black, 0.1);
      }
    }

    > * {
      @include bolt-visuallyhidden;
    }
  }
}

.c-bolt-carousel__button--hidden {
  opacity: 0;
  transition-delay: 0s;
}

.c-bolt-carousel__button--disabled:not(.c-bolt-carousel__button--hidden) {
  visibility: hidden;
}

.c-bolt-carousel__button:focus,
.c-bolt-carousel__button:active,
.c-bolt-carousel__button:hover {
  outline: 0;
}

.c-bolt-carousel__button:focus {
  outline: 0;
}

.c-bolt-carousel__pagination__bullet {
  outline-offset: -3px;
}
