// /* ------------------------------------ *\
//    Tabs Items
// \* ------------------------------------ */

@use 'sass:list';
@use '@bolt/core' as core;

bolt-tab-panel {
  display: block;

  [slot='label'] {
    display: none;
  }
}

.c-bolt-tab-panel__content {
  display: none;
  flex-grow: 1; // sits inside flex container, always want full-width

  &[aria-expanded='true'] {
    display: block;
  }

  &:focus {
    // gets focus when you tab after opening a tab panel, user agent adds focus ring by default
    outline: none;
  }
}

bolt-tab-panel,
.c-bolt-tab-panel__content {
  &[id] {
    scroll-margin-top: calc(
      var(--bolt-page-offset) + var(--c-bolt-tabs-nav-offset) +
        var(--c-bolt-tabs-content-offset)
    );
  }
}

// Panel spacing
@each $size in core.$bolt-spacing-multiplier-system {
  $size-name: list.nth($size, 1);

  @if (
    $size-name !=
      'xxsmall' and
      $size-name !=
      'large' and
      $size-name !=
      'xlarge' and
      $size-name !=
      'xxlarge'
  ) {
    .c-bolt-tab-panel--spacing-#{$size-name} {
      > .c-bolt-tab-panel__content {
        padding: var(--bolt-spacing-y-#{$size-name})
          var(--bolt-spacing-x-#{$size-name});
      }

      &:not(.c-bolt-tab-panel--inset) {
        > .c-bolt-tab-panel__content {
          padding-right: 0;
          padding-bottom: 0;
          padding-left: 0;
        }

        @include core.bolt-mq(small) {
          &.c-bolt-tab-panel--left-tabs > .c-bolt-tab-panel__content {
            padding-top: 0;
            padding-inline-start: var(--bolt-spacing-x-#{$size-name});
          }

          &.c-bolt-tab-panel--right-tabs > .c-bolt-tab-panel__content {
            padding-top: 0;
            padding-inline-end: var(--bolt-spacing-x-#{$size-name});
          }
        }
      }
    }
  }
}

// No-js styles
.c-bolt-tab-panel__outer {
  order: 99; // tab panels must *appear* at the end but live next to their respective labels for adjacent sibling selectors to work
  width: 100%; // required for no-JS panel alignment
}
