/* ------------------------------------ *\
   Animation zoom in
\* ------------------------------------ */

@keyframes a-bolt-in-zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 1;
  }
}

.a-bolt-in--zoom {
  animation-name: a-bolt-in-zoom;
}

/* ------------------------------------ *\
   Animation zoom in up
\* ------------------------------------ */

@keyframes a-bolt-in-zoom-up {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 500%, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.4, 0.4, 0.4) translate3d(0, -30%, 0);
  }
}

.a-bolt-in--zoom-up {
  animation-name: a-bolt-in-zoom-up;
}

/* ------------------------------------ *\
   Animation zoom in down
\* ------------------------------------ */

@keyframes a-bolt-in-zoom-down {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -500%, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.4, 0.4, 0.4) translate3d(0, 30%, 0);
  }
}

.a-bolt-in--zoom-down {
  animation-name: a-bolt-in-zoom-down;
}

/* ------------------------------------ *\
   Animation zoom in left
\* ------------------------------------ */

@keyframes a-bolt-in-zoom-left {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-500%, 0, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.4, 0.4, 0.4) translate3d(30%, 0, 0);
  }
}

.a-bolt-in--zoom-left {
  animation-name: a-bolt-in-zoom-left;
}

/* ------------------------------------ *\
   Animation zoom in right
\* ------------------------------------ */

@keyframes a-bolt-in-zoom-right {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(500%, 0, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.4, 0.4, 0.4) translate3d(-30%, 0, 0);
  }
}

.a-bolt-in--zoom-right {
  animation-name: a-bolt-in-zoom-right;
}
