/* ------------------------------------ *\
   Sticky
\* ------------------------------------ */

@use '@bolt/core' as core;

bolt-sticky {
  display: block;
}

.c-bolt-sticky {
  position: sticky;
  top: 0;
  z-index: core.bolt-z-index(nav);
  width: 100%;
}
