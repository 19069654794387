/* ------------------------------------ *\
  #OVERFLOW UTILITIES
\* ------------------------------------ */

.u-bolt-overflow-hidden {
  overflow: hidden !important;
}

.u-bolt-overflow-scroll {
  overflow: scroll !important;
}

.u-bolt-overflow-auto {
  overflow: auto !important;
}
