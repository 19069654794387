$bolt-namespace: bolt;
/* ------------------------------------ *\
   Sticky
\* ------------------------------------ */

@import '@bolt/core';

bolt-sticky {
  display: block;
}

.c-bolt-sticky {
  position: sticky;
  top: 0;
  z-index: bolt-z-index(nav);
  width: 100%;
}
