/* ------------------------------------ *\
   Truncate Items
\* ------------------------------------ */

@use '@bolt/core' as core;

// Register Custom Block Element
@include core.bolt-custom-element('bolt-chip-list', block, medium);

.c-bolt-chip-list {
  display: block;
}

.c-bolt-chip-list__input {
  @include core.bolt-visuallyhidden;
}
