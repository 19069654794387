/* ------------------------------------ *\
   Inline List:

   The inline-list object simply
   displays a list of items in
   one line.
\* ------------------------------------ */

// Predefine the variables below in order to alter and enable specific features.
$bolt-inline-list-delimit-character: '|' !default;

// Base Inline List Styles
bolt-inline-list {
  display: block;

  &:not(:last-child) {
    margin-bottom: var(--bolt-spacing-y-small);
  }
}

.o-bolt-inline-list {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  letter-spacing: -0.35em; // Remove extra space from inline-blocks
}

// Inline List Item
.o-bolt-inline-list__item {
  display: inline-block; // inline-block is needed here so matrix (margin-bottom) options work
  letter-spacing: normal;
}

// Inline List Delimited
.o-bolt-inline-list--delimited
  > .o-bolt-inline-list__item:not(:last-child):after {
  content: '#{$bolt-inline-list-delimit-character}';
  color: currentColor;
}

// Inline List Spacing
$bolt-object-inline-list-sizes: xsmall, small, medium;

@each $size in $bolt-object-inline-list-sizes {
  .o-bolt-inline-list--#{$size} {
    &.o-bolt-inline-list--matrix {
      margin-bottom: calc(var(--bolt-spacing-y-#{$size}) * -1);

      > .o-bolt-inline-list__item {
        margin-bottom: var(--bolt-spacing-y-#{$size});
      }
    }

    > .o-bolt-inline-list__item:not(:last-child) {
      margin-inline-end: var(--bolt-spacing-x-#{$size});

      &:after {
        margin-inline-start: var(--bolt-spacing-x-#{$size});
      }
    }
  }
}
