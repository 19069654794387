@use 'sass:list';
@use '@bolt/core' as core;

.c-bds-spacing-bar {
  background-color: var(--m-bolt-tertiary);
}

@each $size in core.$bolt-spacing-multiplier-system {
  $size-name: list.nth($size, 1);

  .c-bds-spacing-bar--x-#{$size-name} {
    width: var(--bolt-spacing-x-#{$size-name});
    height: var(--bolt-spacing-y-medium);
  }

  .c-bds-spacing-bar--y-#{$size-name} {
    width: var(--bolt-spacing-x-medium);
    height: var(--bolt-spacing-y-#{$size-name});
  }
}
