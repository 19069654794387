/* ------------------------------------ *\
   Table of Contents
\* ------------------------------------ */

@use '@bolt/core' as core;

.c-bolt-toc {
  display: block;
  position: relative;
  overflow: hidden; // For rounded border on header
  @include core.bolt-shadow('level-20');
  border: 1px solid var(--m-bolt-border);
  border-radius: core.bolt-border-radius(large);

  &--variant-transparent {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  &--sticky {
    position: sticky;
    top: 0;
  }
}

.c-bolt-toc__header {
  z-index: 1;
  margin: 0;
  padding: var(--bolt-spacing-y-xsmall) var(--bolt-spacing-x-small);
  font-family: var(--bolt-type-font-family-headline);
  font-size: var(--bolt-type-font-size-medium);
  font-weight: var(--bolt-type-font-weight-bold);
  color: var(--m-bolt-bg);
  line-height: var(--bolt-type-line-height-medium);
  background-color: var(--m-bolt-headline);

  &.c-bolt-toc__header--hidden {
    @include core.bolt-visuallyhidden;
  }

  @at-root .c-bolt-toc--variant-transparent #{&} {
    display: block;
    margin: 0 0 var(--bolt-spacing-y-xsmall) 0;
    padding: 0;
    font-size: var(--bolt-type-font-size-small);
    font-weight: var(--bolt-type-font-weight-bold);
    color: var(--m-bolt-headline);
    line-height: var(--bolt-type-line-height-small);
    background-color: transparent;
  }
}

.c-bolt-toc__list {
  display: block;
  // Approximate height of one item
  min-height: calc(
    var(--bolt-type-line-height-small) * 1rem + var(--bolt-spacing-y-small)
  );
  // Page height minus header height, minus TOC header height, minus 8rem (magic number to cover any other user offset)
  max-height: calc(
    100vh - var(--bolt-page-header-height) -
      (var(--bolt-type-line-height-large) * 1rem) - 8rem
  );
  margin: 0;
  padding: var(--bolt-spacing-y-small) 0;
  list-style: none;
  // Prevents from flickering when scrolling over the TOC (Safari fix)
  will-change: opacity;
  // Prevents from scrolling entire site when scrolling on inner scrollable container (Safari fix)
  overscroll-behavior: none;
  @include core.bolt-vertical-scroll;
  @include core.bolt-mq($until: small) {
    max-height: calc(30vh - var(--bolt-page-header-height));
  }

  @at-root .c-bolt-toc--variant-transparent #{&} {
    padding: 0;
  }
}
