@import '@bolt/core';

/**
 * Signifier
 */

.c-bolt-video-thumbnail {
  display: block;
  position: relative;
  width: 100%;
  min-width: var(--c-bolt-video-thumbnail-min-width, 10ch);
  border-radius: var(--c-bolt-video-thumbnail-border-radius);
  aspect-ratio: var(--c-bolt-video-thumbnail-aspect-ratio);
  // @supports can be removed when aspect-ratio is fully supported by the newest browsers. See caniuse for the reference.
  @supports not (aspect-ratio: var(--c-bolt-video-thumbnail-aspect-ratio)) {
    height: 0;
    padding-bottom: var(--c-bolt-video-thumbnail-aspect-ratio);
  }
}

.c-bolt-video-thumbnail--aspect-ratio-1\:1 {
  --c-bolt-video-thumbnail-aspect-ratio: 1/1;
  // @supports can be removed when aspect-ratio is fully supported by the newest browsers. See caniuse for the reference.
  @supports not (aspect-ratio: var(--c-bolt-video-thumbnail-aspect-ratio)) {
    --c-bolt-video-thumbnail-aspect-ratio: 100%;
  }
}

.c-bolt-video-thumbnail--aspect-ratio-16\:9 {
  --c-bolt-video-thumbnail-aspect-ratio: 16/9;
  // @supports can be removed when aspect-ratio is fully supported by the newest browsers. See caniuse for the reference.
  @supports not (aspect-ratio: var(--c-bolt-video-thumbnail-aspect-ratio)) {
    --c-bolt-video-thumbnail-aspect-ratio: calc(9 / 16 * 100%);
  }
}

.c-bolt-video-thumbnail--aspect-ratio-4\:3 {
  --c-bolt-video-thumbnail-aspect-ratio: 4/3;
  // @supports can be removed when aspect-ratio is fully supported by the newest browsers. See caniuse for the reference.
  @supports not (aspect-ratio: var(--c-bolt-video-thumbnail-aspect-ratio)) {
    --c-bolt-video-thumbnail-aspect-ratio: calc(3 / 4 * 100%);
  }
}

.c-bolt-video-thumbnail--border-radius-small {
  --c-bolt-video-thumbnail-border-radius: #{bolt-border-radius(small)};
}

.c-bolt-video-thumbnail--border-radius-large {
  --c-bolt-video-thumbnail-border-radius: #{bolt-border-radius(large)};
}

.c-bolt-video-thumbnail__content {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  > bolt-image {
    flex-grow: 1;
  }
}

.c-bolt-video-thumbnail__content-video {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-bolt-video-thumbnail__inline-button {
  @include bolt-button-native-styles-reset;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;

  &:focus,
  &:active:not(:disabled) {
    transform: translate3d(0, 0, 0);
    outline: var(--bolt-focus-ring);
    outline-offset: 2px;
  }
}

.c-bolt-video-thumbnail__play-icon {
  display: block;
  position: absolute;
  bottom: var(--bolt-spacing-y-xsmall);
  left: var(--bolt-spacing-x-xsmall);
  width: 10%;
  min-width: 24px;
  max-width: 44px;
  height: 10%;
  min-height: 20px;
  max-height: 32px;
  overflow: hidden;
  pointer-events: none;
  border-radius: var(
    --c-bolt-video-thumbnail-border-radius,
    #{bolt-border-radius(small)}
  );
  box-shadow: 0 0 2em var(--bolt-color-gray);
  background-color: var(--bolt-color-yellow);
  transform-origin: bottom left;

  @include bolt-mq(small) {
    bottom: var(--bolt-spacing-y-small);
    left: var(--bolt-spacing-x-small);
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 25%;
    height: 45%;
    background-color: var(--bolt-color-black);
    clip-path: polygon(0 0, 0 100%, 100% 50%);
  }

  @at-root .c-bolt-video-thumbnail--aspect-ratio-1\:1 #{&} {
    min-width: 24px;
    max-width: 44px;
    min-height: 24px;
    max-height: 44px;

    &:before {
      width: 35%;
    }
  }

  @at-root .c-bolt-video-thumbnail--aspect-ratio-16\:9 #{&} {
    height: 14%;
  }
}

.c-bolt-video-thumbnail__meta {
  display: flex;
  position: absolute;
  right: var(--bolt-spacing-x-xsmall);

  @include bolt-mq(small) {
    right: var(--bolt-spacing-x-small);
  }
}

.c-bolt-video-thumbnail__meta--top {
  top: var(--bolt-spacing-y-xsmall);
  left: var(--bolt-spacing-x-xsmall);

  > * + * {
    margin-left: var(--bolt-spacing-x-xsmall);
  }

  @include bolt-mq(small) {
    top: var(--bolt-spacing-y-small);
    left: var(--bolt-spacing-x-small);
  }
}

.c-bolt-video-thumbnail__meta--bottom {
  flex-direction: row-reverse;
  bottom: var(--bolt-spacing-y-xsmall);

  > * + * {
    margin-right: var(--bolt-spacing-x-xsmall);
  }

  @include bolt-mq(small) {
    bottom: var(--bolt-spacing-y-small);
  }
}

.c-bolt-video-thumbnail__title {
  display: inline-block;
  padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-small);
  font-family: var(--bolt-type-font-family-body);
  font-size: var(--bolt-type-font-size-xsmall);
  font-weight: var(--bolt-type-font-weight-regular);
  color: var(--bolt-color-white);
  line-height: calc(
    var(--bolt-type-line-height-xsmall) *
      var(--bolt-type-line-height-multiplier-tight)
  );
  border-radius: var(
    --c-bolt-video-thumbnail-border-radius,
    #{bolt-border-radius(small)}
  );
  background-color: rgba(black, 0.6);
}

.c-bolt-video-thumbnail__subtitles,
.c-bolt-video-thumbnail__duration {
  display: inline-block;
}

.c-bolt-video-thumbnail__subtitles,
.c-bolt-video-thumbnail__subtitles:after,
.c-bolt-video-thumbnail__duration {
  padding: var(--bolt-spacing-y-xxsmall) var(--bolt-spacing-x-xxsmall);
  font-size: var(--bolt-type-font-size-tiny);
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--bolt-color-white);
  border-radius: var(
    --c-bolt-video-thumbnail-border-radius,
    #{bolt-border-radius(small)}
  );
  background-color: rgba(black, 0.6);
  white-space: nowrap;
}

.c-bolt-video-thumbnail__subtitles:after,
.c-bolt-video-thumbnail__duration {
  line-height: var(--bolt-type-line-height-tiny);
}

.c-bolt-video-thumbnail__duration {
  pointer-events: none;
  text-overflow: ellipsis;
}

.c-bolt-video-thumbnail__subtitles {
  line-height: 0;

  &:after {
    content: attr(aria-label);
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-65%, -120%, 0);
  }

  &:hover {
    &:after {
      display: block;
    }
  }
}

@media (min-width: 42em) {
  .c-bolt-video-thumbnail__subtitles--desktop-only {
    display: block;
  }

  .c-bolt-video-thumbnail__subtitles--mobile-only {
    display: none;
  }
}

@media (max-width: 42em) {
  .c-bolt-video-thumbnail__subtitles--desktop-only {
    display: none;
  }

  .c-bolt-video-thumbnail__subtitles--mobile-only {
    display: block;
  }
}
