$gear-color: #e0e2eb;
$gear-size: 60px;
$large-gear-size: $gear-size * 2;
$center: 10px;
$gear-animation-time: 12s;

@keyframes clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes counter-clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.c-bds-gearbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
}

.c-bds-gearbox__inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 1rem;
  border: 1px solid rgba(#e0e2eb, 0.8);
  border-radius: 3px;
  background-color: white;
}

.c-bds-gearbox__overlay {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding: 1.5rem 1rem;
  border-radius: 3px;
}

.c-bds-gearbox__gear {
  opacity: 0.5;
  position: absolute;
  width: $gear-size;
  height: $gear-size;
  border-radius: $gear-size / 2;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: $gear-size - 24px;
    height: $gear-size - 24px;
    margin-top: -(($gear-size - 24px) / 2);
    margin-left: -(($gear-size - 24px) / 2);
    border-radius: 36px;
    box-shadow: 0px 0px 10px white(0.1),
      inset 0px 0px 10px black(0.1),
      inset 0px 2px 0px 0px darken($gear-color, 30%),
      inset 0px -1px 0px 0px lighten($gear-color, 20%);
    background: white;
  }
}

.c-bds-gearbox__gear--large {
  width: $large-gear-size;
  height: $large-gear-size;
  border-radius: $large-gear-size / 2;

  &:after {
    $large-gear-inner-size: $large-gear-size - 24px;
    width: $large-gear-size - 24px;
    height: $large-gear-size - 24px;
    margin-top: -(($large-gear-size - 24px) / 2);
    margin-left: -(($large-gear-size - 24px) / 2);
    border-radius: ($large-gear-size - 24px) / 2;
  }
}

.c-bds-gearbox__gear--one {
  top: $center + 2px;
  left: $center;
}

.c-bds-gearbox__gear--two {
  top: 51 + $center;
  left: 50px + $center;
}

.c-bds-gearbox__gear--three {
  top: 100px + $center;
  left: $center;
}

.c-bds-gearbox__gear--four {
  top: $center + 3px;
  left: $center + 118px;
}

.c-bds-gearbox__gear-inner {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: $gear-size / 2;
  background: $gear-color;
  animation-iteration-count: infinite;

  .c-bds-gearbox__gear--large & {
    border-radius: $large-gear-size / 2;
  }

  .c-bds-gearbox__gear--one & {
    animation: counter-clockwise $gear-animation-time infinite linear;
  }

  .c-bds-gearbox__gear--two & {
    animation: clockwise $gear-animation-time infinite linear;
  }

  .c-bds-gearbox__gear--three & {
    animation: counter-clockwise $gear-animation-time infinite linear;
  }

  .c-bds-gearbox__gear--four & {
    animation: counter-clockwise $gear-animation-time * 2 infinite linear;
  }
}

.c-bds-gearbox__gear-bar {
  $bar-width: 16px;
  $bar-height: 8px;

  $actual-height: $bar-width;
  $actual-width: ($bar-height * 2) + $gear-size;
  position: absolute;
  top: 50%;
  left: 50%;
  width: $actual-width;
  height: $actual-height;
  margin-top: -($actual-height / 2);
  margin-left: -($actual-width / 2);
  border-right: 1px solid rgba(white, 0.1);
  border-left: 1px solid rgba(white, 0.1);
  border-radius: 2px;
  background: $gear-color;

  .c-bds-gearbox__gear--large & {
    $large-bar-width: ($bar-height * 2) + ($gear-size * 2);
    width: $large-bar-width;
    margin-left: -($large-bar-width / 2);
  }

  &:nth-child(2) {
    transform: rotate(60deg);
  }

  &:nth-child(3) {
    transform: rotate(120deg);
  }

  &:nth-child(4) {
    transform: rotate(90deg);
  }

  &:nth-child(5) {
    transform: rotate(30deg);
  }

  &:nth-child(6) {
    transform: rotate(150deg);
  }
}

.c-bds-gearbox__gear-text {
  margin: 0;
  font-size: 0.9rem;
  font-weight: bolder;
  line-height: 1.51;
  text-align: center;
}
