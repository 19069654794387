@import '@bolt/core';

// Register Custom Block Element
@include bolt-custom-element('bolt-ol', block, medium);

bolt-ol {
  counter-reset: li;
}

// Styles
.c-bolt-ol {
  margin: 0 0 calc(var(--bolt-spacing-y-xxsmall) * -1) 0;
  padding: 0;
  list-style: none;

  &--nested {
    margin-top: var(--bolt-spacing-y-xxsmall);
  }
}

.c-bolt-ul,
.c-bolt-ol {
  .c-bolt-ul,
  .c-bolt-ol {
    margin-top: var(--bolt-spacing-y-xxsmall);
  }
}
