/* ------------------------------------ *\
   Blockquote
\* ------------------------------------ */

@import '@bolt/core';

$bolt-blockquote-border-width: 4px;
$bolt-blockquote-border-style: $bolt-border-style;
$bolt-blockquote-border-color: var(--m-bolt-text-on-tertiary);
$bolt-blockquote-image-border-width: $bolt-border-width;
$bolt-blockquote-image-border-style: $bolt-border-style;
$bolt-blockquote-image-border-color: var(--m-bolt-border);
$bolt-blockquote-image-size: 4rem;
$bolt-blockquote-image-border-radius: bolt-border-radius(full);

@mixin bolt-lang-specific-quotes($type: 'open') {
  $quote-selector: 'p:first-of-type::before';

  @if ($type == 'close') {
    $quote-selector: 'p:last-of-type::after';
  }

  #{$quote-selector} {
    content: map-get-deep($bolt-quotation-marks, 'en', $type);
  }

  @at-root [lang='de'] &,
    &[lang='de'] {
    #{$quote-selector} {
      content: map-get-deep($bolt-quotation-marks, 'de', $type);
    }
  }

  @at-root [lang='fr'] &,
    &[lang='fr'] {
    #{$quote-selector} {
      content: map-get-deep($bolt-quotation-marks, 'fr', $type);
    }
  }

  @at-root [lang='ja'] &,
    &[lang='ja'] {
    #{$quote-selector} {
      content: map-get-deep($bolt-quotation-marks, 'ja', $type);
    }
  }
}

.c-bolt-blockquote {
  --c-bolt-blockquote-max-width: 44rem;

  display: block;
  margin: 0;

  &:before,
  &:after {
    content: '';
    width: 100%;
    max-width: 300px;
    height: $bolt-blockquote-border-width;
    vertical-align: top;
    background-color: $bolt-blockquote-border-color;
  }

  &:before {
    margin-bottom: var(--bolt-spacing-y-medium);
  }

  &:after {
    margin-top: var(--bolt-spacing-y-medium);
  }
}

// Logo
.c-bolt-blockquote__logo {
  display: block;
  margin-bottom: var(--bolt-spacing-y-small);
}

.c-bolt-blockquote__logo--bottom {
  margin-top: var(--bolt-spacing-y-small);
  margin-bottom: 0;
}

// Quotation
.c-bolt-blockquote__quote {
  display: block;
  position: relative;
  max-width: var(--c-bolt-blockquote-max-width);
  color: var(--m-bolt-headline);

  $sizes: 'large', 'xlarge', 'xxlarge';

  @each $size in $sizes {
    &--#{$size} {
      font-size: var(--bolt-type-font-size-#{$size});
      line-height: var(--bolt-type-line-height-#{$size});
    }
  }

  $weights: 'semibold', 'bold';

  @each $weight in $weights {
    &--#{$weight} {
      font-weight: var(--bolt-type-font-weight-#{$weight});
    }
  }

  @include bolt-lang-specific-quotes('open');
  @include bolt-lang-specific-quotes('close');

  p:first-of-type:before,
  p:last-of-type:after {
    font-family: Georgia, serif; // TODO: Replace with Noto Serif when it is added.
  }

  // Prop-specific styles
  // "left" is default alignment
  &,
  &.c-bolt-blockquote--align-start & {
    p:first-of-type:before {
      position: absolute;
      transform: translate3d(-110%, 0, 0);
    }
  }

  &.c-bolt-blockquote--align-center & {
    p:first-of-type:before {
      padding: 0 2px;
    }
  }

  &.c-bolt-blockquote--align-end & {
    p:first-of-type:before {
      padding: 0 2px;
    }

    p:last-of-type:after {
      position: absolute;
      transform: translate3d(10%, 0, 0);
    }
  }

  &.c-bolt-blockquote--no-quotes {
    p:before,
    p:after {
      display: none;
    }
  }

  // Just in case user puts <p> in 'author' or 'cite' slot
  [slot] p {
    &:before,
    &:after {
      display: none;
    }
  }
}

// Attribution
.c-bolt-blockquote__author-name,
.c-bolt-blockquote__author-title {
  font-size: var(--bolt-type-font-size-xsmall);
  color: var(--m-bolt-headline);
  line-height: var(--bolt-type-line-height-xsmall);
}

.c-bolt-blockquote__author-name {
  font-weight: var(--bolt-type-font-weight-bold);
}

.c-bolt-blockquote__image {
  display: inline-block;
  box-sizing: border-box;
  width: $bolt-blockquote-image-size;
  height: $bolt-blockquote-image-size;
  overflow: hidden;
  margin-bottom: var(--bolt-spacing-y-small);
  vertical-align: middle;
  border-radius: $bolt-blockquote-image-border-radius;
  border-width: $bolt-blockquote-image-border-width;
  border-style: $bolt-blockquote-image-border-style;
  border-color: $bolt-blockquote-image-border-color;

  > * {
    max-width: 100%;
  }
}

.c-bolt-blockquote__footer {
  margin-top: var(--bolt-spacing-y-medium);
  color: var(--m-bolt-text);
  line-height: calc(
    var(--bolt-type-line-height-medium) *
      var(--bolt-type-line-height-multiplier-tight)
  );
}

.c-bolt-blockquote__footer-item {
  display: block;

  &:last-child {
    margin-bottom: 0;
  }
}

// Horizontal alignment of items inside
.c-bolt-blockquote--align-start {
  text-align: left;

  .c-bolt-blockquote__quote,
  .c-bolt-blockquote__logo > * {
    margin-right: auto;
    margin-left: 0;
  }
}

.c-bolt-blockquote--align-center {
  text-align: center;

  .c-bolt-blockquote__quote,
  .c-bolt-blockquote__logo > * {
    margin-right: auto;
    margin-left: auto;
  }

  .c-bolt-blockquote__logo > *::slotted(*) {
    margin-right: auto;
    margin-left: auto;
  }
}

.c-bolt-blockquote--align-end {
  text-align: right;

  .c-bolt-blockquote__quote,
  .c-bolt-blockquote__logo > * {
    margin-right: 0;
    margin-left: auto;
  }

  .c-bolt-blockquote__logo > *::slotted(*) {
    margin-right: 0;
    margin-left: auto;
  }
}

// Border Options
.c-bolt-blockquote--border-vertical {
  padding: 0 var(--bolt-spacing-x-medium);
  border-style: $bolt-blockquote-border-style;
  border-color: $bolt-blockquote-border-color;

  &:before,
  &:after {
    display: none;
  }

  &.c-bolt-blockquote--align-start {
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: $bolt-blockquote-border-width;
  }

  &.c-bolt-blockquote--align-center {
    @include bolt-mq(large) {
      margin: 0 var(--bolt-spacing-x-xxlarge);
    }

    border-top-width: 0;
    border-right-width: $bolt-blockquote-border-width;
    border-bottom-width: 0;
    border-left-width: $bolt-blockquote-border-width;
  }

  &.c-bolt-blockquote--align-end {
    border-top-width: 0;
    border-right-width: $bolt-blockquote-border-width;
    border-bottom-width: 0;
    border-left-width: 0;
  }
}

.c-bolt-blockquote--border-horizontal {
  &:before,
  &:after {
    display: inline-block;
    display: inline-flex;
  }
}

.c-bolt-blockquote--border-none {
  &:before,
  &:after {
    display: none;
  }
}

// Indent options
.c-bolt-blockquote--indented {
  margin: 0 var(--bolt-spacing-x-medium);

  &.c-bolt-blockquote--align-start {
    margin-right: 0;
  }

  &.c-bolt-blockquote--align-end {
    margin-left: 0;
  }
}
