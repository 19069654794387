@keyframes bds-logo-fade {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0.4, 0.4);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.6, 0.6);
  }
}

@keyframes bds-logo-scale-in-out {
  0%,
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    transform: translate3d(0, 0, 0) scale(0.9);
  }
}

.c-bds-logo {
  opacity: 0;
  position: relative;
  transform: translate3d(0, 0, 0) scale(0.6, 0.6);
  max-width: 600px;
  overflow: visible; // so the logo doesn't get chopped off
  margin: 0 auto;
  perspective: 1000px;

  .is-ready & {
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-name: bds-logo-fade;
    animation-timing-function: cubic-bezier(0.22, 1, 0.32, 1);
  }
}

.c-bds-logo__inner {
  animation: bds-logo-scale-in-out 24s ease-in-out infinite;
}
