/* ------------------------------------ *\
   Table
\* ------------------------------------ */

@use '@bolt/core' as core;

.e-bolt-table-wrapper {
  @include core.bolt-scroll;

  display: inline-block;
  max-width: 100%;
  max-height: 75vh;
  line-height: var(--bolt-type-line-height-xsmall);
  border-radius: core.bolt-border-radius(small);
  box-shadow: 0 0 0 1px var(--m-bolt-table-border);
  background-color: var(--m-bolt-table-border);
  overscroll-behavior: none;

  &--full-width {
    display: block;
  }

  & > .e-bolt-table {
    width: 100%;
  }
}

.e-bolt-table {
  font-size: var(--bolt-type-font-size-xsmall);
  border-radius: core.bolt-border-radius(small);
  box-shadow: 0 0 0 1px var(--m-bolt-table-border);
  // The bg color here serves as border color because the cells have spacing in-between which will expose the bg color of the table. It creates the illusion of borders.

  @media print {
    max-width: unset;
    max-height: unset;
  }

  thead {
    --m-bolt-text: var(--m-bolt-headline);
    --m-bolt-link: var(--m-bolt-headline);
  }

  caption,
  td {
    background-color: var(--m-bolt-bg);
  }

  caption {
    font-size: var(--bolt-type-font-size-xsmall);
    line-height: var(--bolt-type-line-height-xsmall);
  }

  thead td,
  th {
    background-color: var(--m-bolt-table-header);
  }

  thead td,
  thead th,
  tbody:first-child tr:first-of-type td,
  tbody:first-child tr:first-of-type th {
    &:first-child {
      border-top-left-radius: core.bolt-border-radius(small);
    }

    &:last-child {
      border-top-right-radius: core.bolt-border-radius(small);
    }
  }

  tbody:last-child tr:last-of-type td,
  tbody:last-child tr:last-of-type th,
  tfoot:last-child td,
  tfoot:last-child th {
    &:first-child {
      border-bottom-left-radius: core.bolt-border-radius(small);
    }

    &:last-child {
      border-bottom-right-radius: core.bolt-border-radius(small);
    }
  }

  th,
  td {
    padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
    transition: box-shadow var(--bolt-transition),
      background-color var(--bolt-transition);
  }

  tfoot td,
  tfoot th {
    font-weight: var(--bolt-type-font-weight-bold);
  }

  tbody,
  tfoot {
    th:first-of-type {
      border-inline-start: 2px solid var(--m-bolt-table-header);
      transition: border-left var(--bolt-transition),
        box-shadow var(--bolt-transition),
        background-color var(--bolt-transition);
    }

    tr:hover {
      th:first-of-type {
        border-inline-start: 2px solid var(--m-bolt-headline);
      }

      th,
      td {
        background-color: var(--m-bolt-table-highlight);
      }
    }
  }

  caption {
    padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
    text-align: start;
    border-top: 1px solid var(--m-bolt-table-border);
    caption-side: bottom;
  }

  &.e-bolt-table--borderless {
    box-shadow: none;
    border-spacing: 0;

    th {
      box-shadow: none;
    }
  }
}

.e-bolt-table--full-width {
  width: 100%;
}

.e-bolt-table--numeric {
  td,
  th {
    font-family: var(--bolt-type-font-family-code);
    text-align: center;
    white-space: nowrap;
  }

  tbody th,
  tfoot th {
    width: 1%;
    text-align: end;
  }

  tbody th {
    font-family: var(--bolt-type-font-family-body);
  }
}

.e-bolt-table--sticky-side-headers {
  th {
    &:first-of-type {
      position: sticky;
      inset-inline-start: 1px;
      box-shadow: 0 0 0 1px var(--m-bolt-table-border);
    }
  }

  &.e-bolt-table--borderless {
    th {
      &:first-of-type {
        inset-inline-start: 0;
        box-shadow: none;
      }
    }
  }
}

.e-bolt-table--sticky-top-headers {
  thead th {
    position: sticky;
    top: 1px;
    box-shadow: 0 0 0 1px var(--m-bolt-table-border);
  }

  &.e-bolt-table--borderless {
    thead th {
      top: 0;
    }
  }
}

.e-bolt-table--fixed-width-columns {
  &--first {
    tr th:first-of-type:not(th:nth-child(2)),
    tr td:first-of-type:not(td:nth-child(2)) {
      width: 1%;
      white-space: nowrap;
    }
  }

  &--second {
    tr th:first-of-type + th:not(th:nth-child(3)),
    tr th:first-of-type + td:not(td:nth-child(3)),
    tr td:first-of-type + td:not(td:nth-child(3)),
    tr td:first-of-type + th:not(th:nth-child(3)) {
      width: 1%;
      white-space: nowrap;
    }
  }

  &--first-two {
    tr th:first-of-type:not(th:nth-child(2)),
    tr td:first-of-type:not(td:nth-child(2)),
    tr th:first-of-type + th:not(th:nth-child(3)),
    tr th:first-of-type + td:not(td:nth-child(3)),
    tr td:first-of-type + td:not(td:nth-child(3)),
    tr td:first-of-type + th:not(th:nth-child(3)) {
      width: 1%;
      white-space: nowrap;
    }
  }
}

.e-bolt-table--fixed-width-table {
  table-layout: fixed;

  tr th,
  tr td,
  tr th:first-of-type,
  tr td:first-of-type,
  tr th:first-of-type + th,
  tr th:first-of-type + td,
  tr td:first-of-type + td,
  tr td:first-of-type + th {
    width: auto;
    white-space: normal;
    word-break: break-all;
  }
}

.e-bolt-table__expand-button[aria-expanded='true'] {
  .e-bolt-icon {
    transform: rotate(180deg);
  }
}

.e-bolt-table__controls {
  display: flex;
  justify-content: space-between;
  gap: var(--bolt-spacing-x-small);
  align-items: center;

  .e-bolt-text-link__icon-after {
    pointer-events: none;
  }
}
