/* ------------------------------------ *\
   Page Header (desktop mega nav)
\* ------------------------------------ */

@use '@bolt/core' as core;
@use 'page-header-settings-and-tools' as page-header-settings-and-tools;

@include core.bolt-mq($from: large) {
  .c-bolt-page-header--full-width {
    --bolt-page-padding-x: var(--bolt-page-padding-x-medium);
  }

  // Primary section container
  .c-bolt-page-header__primary {
    padding-top: var(--c-bolt-page-header-desktop-padding-top);
    padding-right: var(--bolt-page-padding-x);
    padding-bottom: var(--c-bolt-page-header-desktop-padding-bottom);
    padding-left: var(--bolt-page-padding-x);
    background: linear-gradient(
      rgba(core.bolt-color(gray), 0.1) 0%,
      rgba(core.bolt-color(gray), 0.1)
        page-header-settings-and-tools.$bolt-page-header-desktop-util-nav-height,
      var(--m-bolt-bg)
        page-header-settings-and-tools.$bolt-page-header-desktop-util-nav-height,
      var(--m-bolt-bg) 100%
    );
    grid-template-columns:
      minmax(82px, var(--c-bolt-page-header-logo-max-width, auto))
      1fr;
    grid-gap: var(--bolt-spacing-x-xsmall);
  }

  // Logo
  .c-bolt-page-header__logo {
    // prettier-ignore
    margin-inline-start: calc(
      #{page-header-settings-and-tools.$bolt-page-header-action-trigger-spacing-x} * -1
    );
  }

  // Toolbar
  .c-bolt-page-header__toolbar {
    flex: 1;
    justify-content: flex-end;
    // prettier-ignore
    margin-inline-end: calc(
      #{page-header-settings-and-tools.$bolt-page-header-action-trigger-spacing-x} * -1
    );

    > * {
      order: 2;
    }
  }

  .c-bolt-page-header__action-trigger--nav {
    display: none;
  }

  .c-bolt-page-header__action-trigger {
    & ~ #{&} {
      margin-inline-start: var(--bolt-spacing-x-xxsmall);
    }
  }

  .c-bolt-page-header__action-trigger__icon {
    font-size: var(--bolt-type-font-size-large);
  }

  // Search panel
  .c-bolt-page-header__action-trigger--search {
    z-index: 2;
    pointer-events: all;
  }

  .c-bolt-page-header__search {
    opacity: 0;
    transform: translate3d(0, calc(var(--bolt-spacing-y-small) * -1), 0);
    z-index: 1;
    padding-right: calc(var(--bolt-page-padding-x) + 8vw);
    padding-left: calc(var(--bolt-page-padding-x) + 8vw);
    transition: opacity var(--bolt-transition),
      visibility var(--bolt-transition), transform var(--bolt-transition);

    @at-root .c-bolt-page-header__action-trigger--search[aria-expanded='true']
        ~ #{&} {
      opacity: 1;
      pointer-events: all;
    }

    &:before {
      content: '';
      opacity: 0.8;
      position: fixed;
      top: calc(var(--bolt-page-header-height) * -1);
      right: 0;
      left: 0;
      z-index: 0;
      height: var(--bolt-page-header-height);
      background-color: var(--m-bolt-bg);
    }

    &:before,
    &:after {
      pointer-events: none;
    }
  }

  // Mega primary nav
  .c-bolt-page-header__nav {
    order: 1;
    margin-inline-end: auto;
  }

  .c-bolt-page-header__nav--align-site-nav-items-start {
    margin-inline: 0 auto;
  }

  .c-bolt-page-header__nav--align-site-nav-items-center {
    margin-right: auto;
    margin-left: auto;
  }

  .c-bolt-page-header__nav--align-site-nav-items-end {
    margin-inline: auto 0;
  }

  .c-bolt-page-header__nav-list-group {
    display: flex;
    flex-wrap: nowrap;
  }

  .c-bolt-page-header__nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .c-bolt-page-header__nav-list--user,
  .c-bolt-page-header__nav-list--related-sites {
    display: flex;
    position: absolute;
    top: 0;
    min-height: page-header-settings-and-tools.$bolt-page-header-desktop-util-nav-height;
    font-family: var(--bolt-type-font-family-headline);
    font-size: var(--bolt-type-font-size-xsmall);
    font-weight: var(--bolt-type-font-weight-semibold);
    line-height: var(--bolt-type-line-height-xsmall);

    .c-bolt-page-header__nav-link {
      @include core.bolt-button-native-styles-reset;

      display: block;
      min-height: page-header-settings-and-tools.$bolt-page-header-desktop-util-nav-height;
      padding: 0 var(--bolt-spacing-x-xsmall);
      font-family: var(--bolt-type-font-family-headline);
      color: var(--m-bolt-link);
      line-height: page-header-settings-and-tools.$bolt-page-header-desktop-util-nav-height;
      outline: none;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        border-radius: core.bolt-border-radius(small);
        box-shadow: inset 0 0 0 1px currentColor;
        outline: none; // Repeated here to remove Firefox default outline.
      }

      &.c-bolt-page-header__nav-link--tooltip {
        .c-bolt-page-header__nav-link__content__signifier--only
          + .c-bolt-page-header__nav-link__content__text {
          @include core.bolt-shadow('level-10');

          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: 0;
          inset-inline-start: calc(50% - var(--bolt-spacing-x-xsmall) / 2);
          transform: translate3d(
            -50%,
            calc(var(--bolt-spacing-y-medium) - 6px),
            0
          );
          z-index: core.bolt-z-index(modal);
          width: var(--c-bolt-page-header-desktop-popover-width);
          padding: var(--bolt-spacing-y-xsmall) var(--bolt-spacing-x-xsmall);
          color: var(--m-bolt-text);
          line-height: 1;
          border-radius: core.bolt-border-radius(small);
          border-width: core.$bolt-border-width;
          border-style: core.$bolt-border-style;
          border-color: var(--m-bolt-border);
          background-color: var(--m-bolt-bg);
          transition: opacity var(--bolt-transition),
            visibility var(--bolt-transition), transform var(--bolt-transition);
          white-space: nowrap;
        }

        &:hover,
        &:focus {
          .c-bolt-page-header__nav-link__content__signifier--only
            + .c-bolt-page-header__nav-link__content__text {
            visibility: visible;
            opacity: 1;
            // prettier-ignore
            transform: translate3d(
              -50%,
              calc(
                #{page-header-settings-and-tools.$bolt-page-header-desktop-util-nav-height} - 2px
              ),
              0
            );
          }
        }

        .c-bolt-page-header__nav-link__content__signifier--only
          ~ .c-bolt-page-header__counter {
          transform: translate3d(50%, -20%, 0);
        }
      }

      &:not(.c-bolt-page-header__nav-link--tooltip) {
        .c-bolt-page-header__nav-link__content__signifier--only
          + .c-bolt-page-header__nav-link__content__text {
          @include core.bolt-visuallyhidden;
        }
      }
    }

    .c-bolt-page-header__nav-link__content {
      position: relative;
      min-height: inherit;
    }

    .c-bolt-page-header__nav-link__content__signifier {
      // prettier-ignore
      font-size: calc(
        #{page-header-settings-and-tools.$bolt-page-header-desktop-util-nav-height} * 0.6
      );
    }

    .c-bolt-page-header__nav-link__content__signifier
      + .c-bolt-page-header__nav-link__content__text {
      margin-inline-start: var(--bolt-spacing-x-xxsmall);
    }

    .c-bolt-page-header__counter {
      position: absolute;
      // prettier-ignore
      top: calc(
        #{page-header-settings-and-tools.$bolt-page-header-desktop-util-nav-height} * 0.2
      );
      margin-inline-end: 0;
    }

    .c-bolt-page-header__nav-link__content__signifier--only
      ~ .c-bolt-page-header__counter {
      transform: translate3d(25%, -20%, 0);
    }

    .c-bolt-page-header__nav-link__content__signifier--before
      ~ .c-bolt-page-header__counter {
      transform: translate3d(75%, 0, 0);
    }

    .c-bolt-page-header__nav-content {
      padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
    }

    .c-bolt-page-header__nav-list-item--popover {
      position: relative;

      &.t-bolt-dark {
        color: inherit;
        background-color: inherit;

        > .c-bolt-page-header__nav-link {
          color: inherit;
          background-color: inherit;
        }
      }

      > .c-bolt-page-header__nav-list {
        @include core.bolt-shadow('level-10');
        @include core.bolt-vertical-scroll;

        --c-bolt-page-header-desktop-popover-width: max(13rem, 10vw);
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translate3d(0, calc(var(--bolt-spacing-y-medium) - 6px), 0);
        z-index: core.bolt-z-index(modal);
        width: var(--c-bolt-page-header-desktop-popover-width);
        max-height: 80vh;
        border-radius: core.bolt-border-radius(small);
        border-width: core.$bolt-border-width;
        border-style: core.$bolt-border-style;
        border-color: var(--m-bolt-border);
        background-color: var(--m-bolt-bg);
        transition: opacity var(--bolt-transition),
          visibility var(--bolt-transition), transform var(--bolt-transition);

        .c-bolt-page-header__nav-list-item:not(:last-of-type) {
          border-bottom-color: var(--m-bolt-border);
          border-bottom-style: core.$bolt-border-style;
          border-bottom-width: core.$bolt-border-width;
        }

        .c-bolt-page-header__nav-link {
          @include page-header-settings-and-tools.bolt-page-header-action-trigger;

          & {
            min-height: auto;
            padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
            font-size: var(--bolt-type-font-size-xsmall);
            line-height: var(--bolt-type-line-height-xsmall);
            border-radius: 0;
          }

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }

        .c-bolt-page-header__nav-list-item.is-selected
          .c-bolt-page-header__nav-link {
          padding-inline-end: calc(
            var(--bolt-spacing-x-small) + var(--bolt-spacing-x-small) * 2
          );
          color: var(--m-bolt-headline);
          cursor: default;

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 45%;
            inset-inline-end: calc(
              var(--bolt-spacing-x-small) + var(--bolt-spacing-x-small) * 2
            );
            transform: rotate(-45deg) translate3d(150%, 250%, 0);
            min-width: var(--bolt-spacing-x-small);
            height: var(--bolt-spacing-x-xsmall);
            border-bottom: 1px solid;
            border-inline-start: 1px solid;
          }
        }
      }

      > .c-bolt-page-header__nav-link[aria-expanded='true']
        ~ .c-bolt-page-header__nav-list {
        visibility: visible;
        opacity: 1;
        // prettier-ignore
        transform: translate3d(
          0,
          calc(
            #{page-header-settings-and-tools.$bolt-page-header-desktop-util-nav-height} - 2px
          ),
          0
        );
      }

      > .c-bolt-page-header__nav-list--edge-start {
        inset-inline-start: -2ch;
      }

      > .c-bolt-page-header__nav-list--edge-end {
        inset-inline-end: -2ch;
      }
    }
  }

  .c-bolt-page-header__nav-list--user {
    flex-flow: row-reverse;
    order: 3;
    inset-inline-end: calc(
      var(--bolt-page-padding-x) - var(--bolt-spacing-x-xsmall)
    );
    height: var(--bolt-spacing-y-medium);
  }

  .c-bolt-page-header__nav-list--related-sites {
    order: 2;
    inset-inline-start: calc(
      var(--bolt-page-padding-x) - var(--bolt-spacing-x-xsmall)
    );
  }

  .c-bolt-page-header__nav-list--site {
    display: flex;
    order: 1;

    > .c-bolt-page-header__nav-list-item {
      > .c-bolt-page-header__nav-link {
        @include page-header-settings-and-tools.bolt-page-header-action-trigger;

        & {
          --c-bolt-page-header-desktop-current-indicator-opacity: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          // prettier-ignore
          min-height: calc(
            #{page-header-settings-and-tools.$bolt-page-header-click-target-size} / 2 - 4px
          );
          padding: 0 var(--c-bolt-page-header-desktop-site-nav-link-spacing-x);
          font-size: var(
            --c-bolt-page-header-desktop-primary-nav-link-font-size,
            var(--bolt-type-font-size-small)
          );
          color: var(--m-bolt-text);
          // prettier-ignore
          line-height: calc(
            #{page-header-settings-and-tools.$bolt-page-header-click-target-size} / 2 - 4px
          );
          white-space: nowrap;
          transition: var(--bolt-transition);
        }

        &:before {
          opacity: 0;
        }

        &:after {
          content: '';
          opacity: var(--c-bolt-page-header-desktop-current-indicator-opacity);
          position: absolute;
          top: 50%;
          right: 1px;
          left: 1px;
          transform: translate3d(0, -50%, 0);
          z-index: 0;
          height: calc(
            var(--c-bolt-page-header-desktop-spacing-y) * 2 + #{page-header-settings-and-tools.$bolt-page-header-click-target-size}
          );
          background: linear-gradient(
            transparent 0%,
            transparent calc(100% - 3px),
            var(--m-bolt-link) calc(100% - 3px),
            var(--m-bolt-link) 100%
          );
          transition: opacity var(--bolt-transition),
            transform var(--bolt-transition);
        }

        &:hover,
        &[aria-expanded='true'] {
          color: var(--m-bolt-link);

          &:before {
            opacity: 0;
          }
        }
      }

      &.is-current > .c-bolt-page-header__nav-link,
      > .c-bolt-page-header__nav-link:hover,
      > .c-bolt-page-header__nav-link[aria-expanded='true'] {
        --c-bolt-page-header-desktop-current-indicator-opacity: 1;
      }
    }

    > .c-bolt-page-header__nav-list-item.has-children {
      > .c-bolt-page-header__nav-list {
        display: flex;
        flex-wrap: wrap;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: var(--bolt-page-header-height);
        right: 0;
        left: 0;
        transform: translate3d(0, 0, 0);
        z-index: 1;
        width: 100%;
        padding-top: var(--bolt-spacing-y-medium);
        padding-right: calc(
          var(--bolt-page-padding-x) - var(--bolt-spacing-x-medium)
        );
        padding-bottom: var(--bolt-spacing-y-medium);
        padding-left: calc(
          var(--bolt-page-padding-x) - var(--bolt-spacing-x-medium)
        );
        border-top-color: var(--m-bolt-border);
        border-top-style: core.$bolt-border-style;
        border-top-width: core.$bolt-border-width;
        border-bottom-color: var(--m-bolt-border);
        border-bottom-style: core.$bolt-border-style;
        border-bottom-width: core.$bolt-border-width;
        box-shadow: 0 var(--bolt-spacing-y-xxsmall) var(--bolt-spacing-y-xsmall)
          rgba(black, 0.05);
        background-color: var(--m-bolt-bg);
        transition: opacity var(--bolt-transition),
          visibility var(--bolt-transition);

        > .c-bolt-page-header__nav-list-item {
          flex: 1 1 20%;
          margin-bottom: var(--bolt-spacing-y-medium);
          padding-right: var(--bolt-spacing-x-medium);
          padding-left: var(--bolt-spacing-x-medium);
          border-inline-start-color: var(--m-bolt-border);
          border-inline-start-style: core.$bolt-border-style;
          border-inline-start-width: core.$bolt-border-width;

          &:first-of-type,
          &:nth-child(6n + 6) {
            border-inline-start-width: 0;
          }

          .c-bolt-page-header__nav-link--heading {
            margin-bottom: var(--bolt-spacing-y-xsmall);
          }

          > .c-bolt-page-header__nav-link--heading {
            margin-bottom: var(--bolt-spacing-y-small);
            font-family: var(--bolt-type-font-family-headline);
            font-size: var(--bolt-type-font-size-regular);
            font-weight: var(--bolt-type-font-weight-bold);
            color: var(--m-bolt-headline);
            line-height: var(--bolt-type-line-height-regular);
          }

          .c-bolt-page-header__nav-list {
            font-size: var(--bolt-type-font-size-xsmall);
            font-weight: var(--bolt-type-font-weight-semibold);
            line-height: var(--bolt-type-line-height-xsmall);

            .c-bolt-page-header__nav-list {
              font-weight: var(--bolt-type-font-weight-regular);
            }
          }
        }

        .c-bolt-page-header__nav-link {
          display: block;
          padding: 1px;

          > .c-bolt-page-header__nav-link__content {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            color: inherit;
            line-height: inherit;
          }
        }

        a.c-bolt-page-header__nav-link,
        button.c-bolt-page-header__nav-link {
          @include core.bolt-button-native-styles-reset;

          color: var(--m-bolt-headline);
          outline: none;

          &:hover {
            text-decoration: underline;
          }

          &:focus {
            border-radius: core.bolt-border-radius(small);
            box-shadow: 0 0 0 1px var(--m-bolt-link);
            outline: none; // Repeated here to remove Firefox default outline.
          }
        }

        .c-bolt-page-header__nav-list {
          .c-bolt-page-header__nav-list-item:not(last-of-type) {
            margin-bottom: var(--bolt-spacing-y-xsmall);
          }

          .c-bolt-page-header__nav-list-item.has-children
            + .c-bolt-page-header__nav-list-item {
            margin-top: var(--bolt-spacing-y-small);
          }

          .c-bolt-page-header__nav-list {
            margin-inline-start: var(--bolt-spacing-x-small);
          }
        }

        .c-bolt-page-header__nav-list-item--view-all {
          font-size: var(--bolt-type-font-size-xsmall);
          font-weight: var(--bolt-type-font-weight-semibold);
          line-height: var(--bolt-type-line-height-xsmall);

          .c-bolt-page-header__nav-link {
            color: var(--m-bolt-link);
          }
        }

        .c-bolt-page-header__nav-list-item--full-width {
          display: grid;
          flex-basis: 100%;
          margin-bottom: 0;
          text-align: center;
          border-inline-start-width: 0;
          place-items: center;
        }

        .c-bolt-page-header__nav-list-item.has-children {
          .c-bolt-page-header__nav-link[aria-expanded] {
            display: none;
          }
        }
      }

      &.c-bolt-page-header__nav-list-item--flat {
        position: relative;

        & > .c-bolt-page-header__nav-list {
          display: block;
          position: absolute;
          // prettier-ignore
          top: calc(
            (
              #{page-header-settings-and-tools.$bolt-page-header-click-target-size} / 1.45
            ) + var(--c-bolt-page-header-desktop-padding-bottom)
          );
          inset-inline-end: auto;
          inset-inline-start: 50%;
          transform: translate3d(-50%, 0, 0);
          width: auto;
          padding-right: 0;
          padding-left: 0;
          border-radius: core.bolt-border-radius(small);
          border-width: core.$bolt-border-width;
          border-style: core.$bolt-border-style;
          border-color: var(--m-bolt-border);

          > .c-bolt-page-header__nav-list-item {
            margin-bottom: var(--bolt-spacing-y-small);
            border-inline-start-width: 0;
            white-space: nowrap;

            &:last-of-type {
              margin-bottom: 0;
            }

            .c-bolt-page-header__nav-link {
              margin-bottom: 0;
              font-size: var(--bolt-type-font-size-xsmall);
              font-weight: var(--bolt-type-font-weight-semibold);
              line-height: var(--bolt-type-line-height-xsmall);
            }
          }
        }
      }

      > .c-bolt-page-header__nav-link[aria-expanded='true']
        ~ .c-bolt-page-header__nav-list {
        @include core.bolt-vertical-scroll;

        visibility: visible;
        opacity: 1;
        max-height: calc(100vh - var(--bolt-page-header-height));
      }

      > .c-bolt-page-header__nav-link[aria-expanded='false']
        ~ .c-bolt-page-header__nav-list {
        transition-delay: calc(var(--bolt-transition-timing) * 1.05);
      }
    }
  }

  .c-bolt-page-header__nav-list--site.c-bolt-page-header__nav-list--wrap-items {
    flex-wrap: wrap;

    > .c-bolt-page-header__nav-list-item {
      > .c-bolt-page-header__nav-link {
        &:after {
          display: none;
        }

        &:hover {
          &:before {
            opacity: 0.1;
          }
        }
      }
    }
  }

  // Subheadline
  .c-bolt-page-header__subheadline {
    order: 1;

    & ~ .c-bolt-page-header__nav {
      margin-inline: auto 0;
    }
  }

  // Main CTA
  .c-bolt-page-header__cta {
    transform: translate3d(0, 0, 0);
    margin-right: #{page-header-settings-and-tools.$bolt-page-header-action-trigger-spacing-x};
    margin-left: #{page-header-settings-and-tools.$bolt-page-header-action-trigger-spacing-x};
  }

  // User flag
  .c-bolt-page-header__user-flag {
    grid-template-columns: 48px 1fr;
    grid-gap: var(--bolt-spacing-x-small);
    align-items: center;
  }

  // Related Sites Grid View
  .c-bolt-page-header__related-sites {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--bolt-spacing-x-small);
  }

  // Notifications specific overrides
  .c-bolt-page-header {
    .c-bolt-notifications__list {
      z-index: 0; // Lower stacking order than header and footer.
    }

    // Make notifications header and footer sticky in the desktop popover
    .c-bolt-notifications__header,
    .c-bolt-notifications__footer {
      position: sticky;
      right: 0;
      left: 0;
      z-index: 1;
    }

    .c-bolt-notifications__header {
      top: 0;
    }

    .c-bolt-notifications__footer {
      bottom: 0;
    }
  }
}

@include core.bolt-mq($from: xlarge) {
  .c-bolt-page-header__primary {
    grid-gap: var(--bolt-spacing-x-medium);
  }
}
