/* ------------------------------------ *\
   Panel 
\* ------------------------------------ */

@import '@bolt/core';

.c-bolt-panel {
  @include bolt-shadow('level-20');

  display: grid;
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: #{bolt-border-radius(small)};
}

.c-bolt-panel__primary {
  display: grid;
  align-items: end;
  padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
  grid-template-rows: 1fr auto;
  grid-row-gap: var(--bolt-spacing-x-small);
}

.c-bolt-panel__primary--signifier {
  height: 100%;

  > * {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}

.c-bolt-panel__primary--footer {
  display: grid;
  grid-gap: var(--bolt-spacing-x-small);
  grid-template-columns: 1fr auto;
  align-items: center;
}

.c-bolt-panel__secondary {
  display: grid;
  visibility: hidden;
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
  background-color: var(--m-bolt-bg);
  grid-auto-rows: auto 1fr auto;
  animation-duration: var(--bolt-transition-timing);
  animation-name: slideout;

  .c-bolt-panel--short & {
    min-height: 0;
  }

  &[aria-expanded='true'] {
    visibility: visible;
    bottom: 0;
    animation-name: slidein;
  }
}

@keyframes slidein {
  0% {
    visibility: hidden;
    bottom: -100%;
  }

  1% {
    visibility: visible;
  }

  100% {
    bottom: 0;
  }
}

@keyframes slideout {
  0% {
    visibility: visible;
    bottom: 0%;
  }

  99% {
    bottom: -100%;
  }

  100% {
    visibility: hidden;
  }
}

.c-bolt-panel__secondary--header {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: var(--bolt-spacing-y-medium);
}

.c-bolt-panel__secondary--footer {
  margin-top: var(--bolt-spacing-y-medium);
  margin-bottom: var(--bolt-spacing-y-small);
}
