@import '@bolt/core';

/* ------------------------------------ *\
   Figure
\* ------------------------------------ */

// Register Custom Block Element
@include bolt-custom-element('bolt-figure', block, medium);

.c-bolt-figure {
  display: block;
  margin: 0;
  padding: 0;
}

.c-bolt-figure__media {
  display: block;
}

.c-bolt-figure__caption {
  display: block;
  padding-top: var(--bolt-spacing-y-xsmall);
  font-size: var(--bolt-type-font-size-xsmall);
  line-height: var(--bolt-type-line-height-xsmall);
}
