@import '@bolt/core';

/**
 * Side nav
 */

.c-bolt-side-nav {
  @include bolt-mq(medium) {
    position: relative;
    overflow: hidden;
  }
}

.c-bolt-side-nav__trigger {
  display: none;

  @include bolt-mq(medium) {
    @include bolt-button-native-styles-reset;

    display: grid;
    width: var(--bolt-spacing-x-medium);
    height: var(--bolt-spacing-x-medium);
    font-size: var(--bolt-type-font-size-regular);
    font-weight: var(--bolt-type-font-weight-bold);
    color: var(--m-bolt-bg);
    line-height: var(--bolt-type-line-height-regular);
    border-radius: bolt-border-radius(full);
    box-shadow: 0 0 0.125em currentColor;
    background-color: var(--m-bolt-headline);
    transition: opacity var(--bolt-transition),
      background-color var(--bolt-transition);
    place-items: center;

    > * {
      transition: transform var(--bolt-transition);
    }

    &[aria-expanded='true'] {
      opacity: 0.8;
      position: absolute;
      top: calc(
        (
            var(--bolt-type-line-height-regular) * 1rem +
              var(--bolt-spacing-y-small)
          ) / 2
      );
      right: var(--bolt-spacing-x-xsmall);
      transform: translate3d(0, -50%, 0);

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    &[aria-expanded='false'] {
      > * {
        transform: rotate(540deg);
      }

      & ~ .c-bolt-side-nav__content {
        display: none;
      }
    }

    &:hover {
      // background-color: var(--m-bolt-link);
    }

    &:focus {
      outline: var(--bolt-focus-ring);
      outline-offset: 0;
    }
  }
}

.c-bolt-side-nav__trigger__text {
  @include bolt-mq(medium) {
    @include bolt-visuallyhidden;
  }
}

.c-bolt-side-nav__content {
  @include bolt-mq($from: medium, $until: xxxxlarge) {
    width: var(
      --l-bolt-holy-grail-layout-sidebar-width
    ); // Width is set if side nav is used inside the Holy Grail layout, otherwise it will take up full width of the parent container.
  }
}

.c-bolt-side-nav__headline {
  display: block;
  margin: 0;
  padding: 0;
}

.c-bolt-side-nav__headline__link {
  display: block;
  padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
  font-size: var(--bolt-type-font-size-regular);
  font-weight: var(--bolt-type-font-weight-bold);
  color: var(--m-bolt-headline);
  line-height: var(--bolt-type-line-height-regular);
  text-decoration: none;
  border-radius: bolt-border-radius(small);

  @include bolt-mq(small) {
    padding: var(--bolt-spacing-y-xsmall) var(--bolt-spacing-x-small);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include bolt-mq(medium) {
    padding-right: var(--bolt-spacing-x-large);
  }

  &:hover {
    color: inherit;
    text-decoration: underline;
  }

  &:focus {
    outline: var(--bolt-focus-ring);
    outline-offset: -4px;
  }

  &[aria-current] {
    color: var(--m-bolt-bg);
    background-color: var(--m-bolt-headline);
  }
}

.c-bolt-side-nav__list {
  margin: 0;
  padding: 0;
  font-family: var(--bolt-type-font-family-headline);
  font-size: var(--bolt-type-font-size-small);
  list-style: none;
  line-height: var(--bolt-type-line-height-small);

  .c-bolt-side-nav__list {
    grid-column: 1/3;

    .c-bolt-side-nav__link {
      font-weight: var(--bolt-type-font-weight-regular);
    }

    .c-bolt-side-nav__list-item {
      .c-bolt-side-nav__link:not(.c-bolt-side-nav__link--toggle-trigger) {
        padding-left: calc(var(--bolt-spacing-x-small) * 2);
      }

      .c-bolt-side-nav__list-item {
        .c-bolt-side-nav__link:not(.c-bolt-side-nav__link--toggle-trigger) {
          padding-left: calc(var(--bolt-spacing-x-small) * 3);
        }

        .c-bolt-side-nav__list-item {
          .c-bolt-side-nav__link:not(.c-bolt-side-nav__link--toggle-trigger) {
            padding-left: calc(var(--bolt-spacing-x-small) * 4);
          }

          .c-bolt-side-nav__list-item {
            .c-bolt-side-nav__link:not(.c-bolt-side-nav__link--toggle-trigger) {
              padding-left: calc(var(--bolt-spacing-x-small) * 5);
            }

            .c-bolt-side-nav__list-item {
              .c-bolt-side-nav__link:not(.c-bolt-side-nav__link--toggle-trigger) {
                padding-left: calc(var(--bolt-spacing-x-small) * 6);
              }

              .c-bolt-side-nav__list-item {
                .c-bolt-side-nav__link:not(.c-bolt-side-nav__link--toggle-trigger) {
                  padding-left: calc(var(--bolt-spacing-x-small) * 7);
                }

                .c-bolt-side-nav__list-item {
                  .c-bolt-side-nav__link:not(.c-bolt-side-nav__link--toggle-trigger) {
                    padding-left: calc(var(--bolt-spacing-x-small) * 8);
                  }

                  .c-bolt-side-nav__list-item {
                    .c-bolt-side-nav__link:not(.c-bolt-side-nav__link--toggle-trigger) {
                      padding-left: calc(var(--bolt-spacing-x-small) * 9);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.c-bolt-side-nav__list-item {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: 0;
}

.c-bolt-side-nav__link {
  @include bolt-button-native-styles-reset;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  position: relative;
  padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
  font-weight: var(--bolt-type-font-weight-semibold);
  color: var(--m-bolt-link);
  text-decoration: none;
  transition: color var(--bolt-transition);

  @include bolt-mq(small) {
    padding: var(--bolt-spacing-y-xsmall) var(--bolt-spacing-x-xsmall)
      var(--bolt-spacing-y-xsmall) var(--bolt-spacing-x-small);
  }

  &:before {
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background-color: var(--bolt-color-navy-light);
    transition: opacity var(--bolt-transition);
  }

  &:hover {
    &:before {
      opacity: 0.05;
    }

    & + .c-bolt-side-nav__link {
      &:before {
        opacity: 0.1;
      }
    }
  }

  &:focus {
    outline: var(--bolt-focus-ring);
    outline-offset: -4px;
  }

  &:not(#{&}--toggle-trigger) {
    border-radius: bolt-border-radius(small);

    @include bolt-mq(medium) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:hover,
    &:focus {
      color: var(--m-bolt-headline);
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;
    }

    &[aria-current] {
      font-weight: var(--bolt-type-font-weight-semibold);

      &,
      & + .c-bolt-side-nav__link {
        color: var(--m-bolt-bg);
        background-color: var(--m-bolt-headline);
      }
    }
  }

  &--toggle-trigger {
    display: grid;
    width: var(--bolt-spacing-x-medium);
    padding-right: 0;
    padding-left: 0;
    place-items: center;

    &:before {
      border-radius: 0;
    }

    &:after {
      content: '\25B6';
      font-size: 0.7em;
      transition: transform var(--bolt-transition);
    }

    &:hover {
      &:before {
        opacity: 0.1;
      }
    }

    &[aria-expanded='false'] {
      & ~ .c-bolt-side-nav__list {
        display: none;
      }
    }

    &[aria-expanded='true'] {
      &:after {
        transform: rotate(450deg);
      }

      & ~ .c-bolt-side-nav__list {
        display: block;
      }
    }
  }
}
