@import '@bolt/core';

/**
 * Figure Element
 */

.e-bolt-figure {
  display: block;
  margin: 0;
  padding: 0;
}

.e-bolt-figure__caption {
  display: block;
  padding-top: var(--bolt-spacing-y-xsmall);
  font-size: var(--bolt-type-font-size-xsmall);
  line-height: var(--bolt-type-line-height-xsmall);
}
