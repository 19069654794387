/* ------------------------------------ *\
  GENERIC - HARD RESET
\* ------------------------------------ */
@use '@bolt/core' as core;

$bolt-color-selection-background-color: var(--bolt-color-navy-xlight);

/*
* Reset all spacing for block elements
*/
body,
dd,
figure,
#{core.$bolt-all-block-elements},
#{core.$bolt-all-heading-elements} {
  margin: 0;
  padding: 0;
}

/*
* Remove text-shadow in selection highlight:
* https://twitter.com/miketaylr/status/12228805301
*
* These selection rule sets have to be separate.
* Customize the background color to match your design.
*/
::-moz-selection {
  text-shadow: none;
  background-color: $bolt-color-selection-background-color;
}

::selection {
  text-shadow: none;
  background-color: $bolt-color-selection-background-color;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0; /* [1] */
  border: 0;
}
