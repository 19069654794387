/* ------------------------------------ *\
   Heading resets
\* ------------------------------------ */

#{$bolt-all-heading-elements} {
  margin-bottom: var(--bolt-spacing-y-xsmall);
  font-family: var(--bolt-type-font-family-headline);
  font-size: var(--bolt-type-font-size-medium);
  font-weight: var(--bolt-type-font-weight-bold);
  line-height: var(--bolt-type-line-height-medium);

  &:last-child {
    margin-bottom: 0;
  }
}
