/* ------------------------------------ *\
  #FLEX UTILITIES
\* ------------------------------------ */

@use 'sass:map';
@use 'sass:string';
@use 'sass:list';
@use '@bolt/core' as core;
@use '@bolt/tokens' as tokens;

// Add additional spacing size rules for handling auto and zero'd rules
$bolt-display-props: map.get(tokens.$tokens, display);

@mixin bolt-display-utils($breakpoint: null) {
  @each $name, $value in $bolt-display-props {
    .u-bolt-#{$name}#{$breakpoint} {
      display: string.unquote($value) !important;
    }
  }
}

@include bolt-display-utils;

// Loop over our breakpoints
@each $breakpoint in core.$bolt-breakpoints {
  $breakpointName: list.nth($breakpoint, 1);
  @include core.bolt-mq($breakpointName) {
    @include bolt-display-utils(\@#{$breakpointName});
  }
}
