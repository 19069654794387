/* ------------------------------------ *\
   Text decoration utils
\* ------------------------------------ */

@use 'sass:map';
@use '@bolt/tokens';

.u-bolt-text-decoration-none {
  text-decoration: none !important;
}

.u-bolt-text-decoration-underline {
  text-decoration: underline !important;
}

.u-bolt-text-decoration-line-through {
  text-decoration: line-through !important;
}

$bolt-text-decoration-props: map.get(tokens.$tokens, text-decoration);
