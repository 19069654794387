bolt-button-group:not(:last-child) {
  margin-bottom: var(--bolt-spacing-y-medium);
}

.c-bolt-button-group {
  display: inline-flex;
  flex-flow: row wrap;
  margin-bottom: 0;
  margin-inline-start: calc(var(--bolt-spacing-x-small) * -1);
  list-style: none;
  letter-spacing: -0.35em;

  &:last-child {
    margin-bottom: calc(var(--bolt-spacing-y-small) * -1);
  }
}

.c-bolt-button-group__item {
  display: inline-flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  padding-bottom: var(--bolt-spacing-y-small);
  padding-inline-start: var(--bolt-spacing-x-small);
  letter-spacing: normal;
}
