/* ------------------------------------ *\
   Action Blocks Settings and Tools
\* ------------------------------------ */

@use '@bolt/core/styles/01-settings/settings-global/settings-global' as settings-global;

// Local Variables
$bolt-action-block-item-max-width: 20ch;
$bolt-action-block-background: var(--m-bolt-tertiary);
$bolt-action-blocks-border-width: settings-global.$bolt-border-width;
$bolt-action-blocks-border-style: settings-global.$bolt-border-style;
