/* ------------------------------------ *\
   Table of Contents Item
\* ------------------------------------ */

@import '@bolt/core';

.c-bolt-toc__item {
  display: block;
  position: relative;
  transform: translate3d(0, 0, 0);
  padding: calc(var(--bolt-spacing-y-small) / 2) var(--bolt-spacing-x-small);
  font-family: var(--bolt-type-font-family-headline);
  font-size: var(--bolt-type-font-size-small);
  font-weight: var(--bolt-type-font-weight-regular);
  color: var(--m-bolt-text);
  line-height: var(--bolt-type-line-height-small);
  text-decoration: none;
  transition: color var(--bolt-transition);

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 0; // Resets the stacking order to be the lowest within the item container.
    user-select: none;
  }

  &:before {
    opacity: 0.05;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    width: 0;
    height: 100%;
    background-color: var(--m-bolt-link);
    transition: width var(--bolt-transition),
      border-radius var(--bolt-transition);
  }

  &:after {
    top: 50%;
    bottom: 0;
    left: 0;
    transform: translate3d(0, -50%, 0);
    width: var(--bolt-spacing-x-xxsmall);
    height: 0;
    background-color: var(--bolt-color-teal);
    transition: height var(--bolt-transition);
  }

  &:hover {
    color: var(--m-bolt-link);

    &:before {
      width: 100%;
      border-radius: 0;
    }
  }

  &:active {
    background-color: var(--m-bolt-tertiary);
  }

  &:focus,
  &--current {
    font-weight: var(--bolt-type-font-weight-semibold);
    color: var(--m-bolt-headline);

    &:before {
      width: 100%;
      border-radius: 0;
    }

    &:after {
      height: calc(100% - var(--bolt-spacing-y-small));
    }
  }

  &--current {
    pointer-events: none;
  }
}
