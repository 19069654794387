.c-bds-docs--sassdoc__item {
  margin-bottom: var(--bolt-spacing-y-xlarge);

  &:first-of-type hr:first-child {
    display: none;
  }
}

.c-bds-docs__return,
.c-bds-docs__example,
.c-bds-docs-table--sassdoc {
  margin-bottom: var(--bolt-spacing-y-medium);
}

.c-bds-docs__return-label {
  font-family: monospace;
  font-weight: var(--bolt-type-font-weight-bold);
  color: var(--bolt-color-navy-light);
}

.c-bds-docs__meta {
  display: flex;
  justify-content: space-between;
}

.c-bds-docs__meta-item {
  details {
    margin: 0;

    &[open] summary {
      font-weight: var(--bolt-type-font-weight-bold);
    }
  }

  summary {
    margin: 0;
    user-select: none;

    &:focus {
      outline: none;
    }
  }
}

/*
** Our floating navigation needs to have an overflow when items expand beyond viewer-height
* [1] Floating nav receives 4rem from .c-bds-docs__page-nav-list and we add .5 rem to reach the bottom
 */
.c-bds-docs__page-nav-list--sassdoc {
  max-height: calc(100vh - 4.5rem); // [1]
  overflow-y: auto;
}
