.pega-editor-ui {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 30vh;

  &__slots {
    display: flex;
    min-height: 1px;
  }

  &__slot {
    overflow: scroll;
    padding-bottom: 20px;
    border-right: 3px solid rgba(0, 0, 0, 0.1);

    &:last-child {
      border-right: 0;
    }

    &:empty {
      display: none;
    }

    &--blocks {
      width: 45%;
    }

    &--layers {
      width: 35%;
      line-height: 1;
    }

    &--selected {
      width: 20%;
    }
  }

  .gjs-trt-trait {
    display: block;

    .gjs-label {
      width: 100%;
    }

    .gjs-label-wrp {
      width: 100%;
    }

    .gjs-field {
      width: 100%;

      &.gjs-field-checkbox {
        max-width: 17px; // core Grapejs styles for checkboxes use `!important` on `width` and so I'm side-stepping that with `max-width`. checkboxes were 100% width x 17px height and it looked bad
      }
    }
  }

  .gjs-block {
    width: auto;
    height: auto;
    min-height: auto;
  }
}

.gjs-pega-editor-panels-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  padding: 2px;

  >.gjs-pega-editor-panels-btn {
    display: inline-block;
    margin: 3px;
    padding: 3px;
    font-size: 80%;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.3);

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &--bug {
      margin-left: auto;
      background-color: #661A00; // @todo use var
    }
  }
}

.gjs-dashed {
  [data-highlightable] {
    [slot] {
      outline: dotted 1px blue;
    }
  }
}
