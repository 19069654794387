/* ------------------------------------ *\
   Type
\* ------------------------------------ */

@import '@bolt/core';

/**
 * Dev notes:
 *
 * 1. Special tricks are used here to make inline icon work, reference the mixin scss to understand it.
 * 2. `em` unit is used to make sure the spacing between icon and text is relative to the text's font-size.
 * 3. Reset margins for inline text.
 * 4. When an eyebrow appears above a headline or subheadline, the eyebrow bottom margin is effectively removed.
 * 5. Hierarchy comes with defaults for attributes that can be modified with other props (e.g. color, size, weight).
 *    If a user explicity sets these other props, they should override the hierarchy default, so CSS for those
 *    props must come lower down in this file.
 * 6. This is a type utility class that will change the font from 'Open Sans' to 'Lora'
 */

$_bolt-type-eyebrow-bottom-margin: var(--bolt-spacing-y-xsmall);

.e-bolt-type {
  --e-bolt-type-font-family: unset;
  --e-bolt-type-font-size: unset;
  --e-bolt-type-font-weight: unset;
  --e-bolt-type-color: unset;
  --e-bolt-type-line-height: unset;
  --e-bolt-type-letter-spacing: unset;
  --e-bolt-type-margin-bottom: var(--bolt-spacing-y-medium);

  margin: 0 0 var(--e-bolt-type-margin-bottom) 0;
  padding: 0;
  font-family: var(--e-bolt-type-font-family);
  font-size: var(--e-bolt-type-font-size);
  font-weight: var(--e-bolt-type-font-weight);
  color: var(--e-bolt-type-color);
  line-height: var(--e-bolt-type-line-height);
  letter-spacing: var(--e-bolt-type-letter-spacing);

  &:last-child {
    margin: 0;
  }
}

// Headline, subheadline, eyebrow [5]
.e-bolt-type--headline {
  --e-bolt-type-font-weight: var(--bolt-type-font-weight-bold);
}

.e-bolt-type--subheadline {
  --e-bolt-type-font-weight: var(--bolt-type-font-weight-regular);
}

.e-bolt-type--eyebrow {
  --e-bolt-type-font-size: var(--bolt-type-font-size-xsmall);
  --e-bolt-type-font-weight: var(--bolt-type-font-weight-regular);
  --e-bolt-type-color: var(--m-bolt-neutral);
  --e-bolt-type-letter-spacing: var(--bolt-type-letter-spacing-wide);
  --e-bolt-type-margin-bottom: $_bolt-type-eyebrow-bottom-margin;

  text-transform: uppercase;
}

.e-bolt-type--eyebrow + .e-bolt-type--headline,
.e-bolt-type--eyebrow + .e-bolt-type--subheadline {
  margin-top: calc(-1 * #{$_bolt-type-eyebrow-bottom-margin}); /* [4] */
}

.e-bolt-type--headline,
.e-bolt-type--subheadline,
.e-bolt-type--eyebrow {
  --e-bolt-type-margin-bottom: var(--bolt-spacing-y-xsmall);
  --e-bolt-type-font-family: var(--bolt-type-font-family-headline);
}

.e-bolt-type--headline,
.e-bolt-type--subheadline {
  --e-bolt-type-color: var(--m-bolt-headline);

  &.e-bolt-type--size-xxxlarge,
  &.e-bolt-type--size-xxlarge {
    --e-bolt-type-letter-spacing: var(--bolt-type-letter-spacing-narrow);
  }
}

// Font size
@each $size in $bolt-type-font-size-system {
  $size-name: nth($size, 1);

  .e-bolt-type--size-#{$size-name} {
    --e-bolt-type-font-size: var(--bolt-type-font-size-#{$size-name});
    --e-bolt-type-line-height: var(--bolt-type-line-height-#{$size-name});

    @include bolt-mq($until: medium) {
      @if $size-name ==
        'largedisplay' or
        $size-name ==
        'display' or
        $size-name ==
        'xxxlarge'
      {
        font-size: var(--bolt-type-font-size-xxlarge);
        line-height: var(--bolt-type-line-height-xxlarge);
      }
    }
  }
}

// Font weight
@each $weight in $bolt-type-font-weight-system {
  $weight-name: nth($weight, 1);

  .e-bolt-type--weight-#{$weight-name} {
    --e-bolt-type-font-weight: var(--bolt-type-font-weight-#{$weight-name});
  }
}

// Font color
@each $color, $shades in $bolt-brand-colors {
  .e-bolt-type--color-#{$color} {
    --e-bolt-type-color: var(--bolt-color-#{$color});
  }
}

// Icons
[class*='e-bolt-type__icon'] {
  @include bolt-inline-icon-wrapper; /* [1] */
}

.e-bolt-type__icon-before {
  margin-right: calc(
    var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xsmall) * 1em
  ); /* [2] */
}

.e-bolt-type__icon-after {
  margin-left: calc(
    var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xsmall) * 1em
  ); /* [2] */
}

// Inline text
span.e-bolt-type {
  margin: 0; /* [3] */
}

// Headline, subheadline, eyebrow [6]
.e-bolt-type--lora {
  --e-bolt-type-font-family: var(--bolt-type-font-family-lora);

  @each $size in $bolt-type-font-size-system-lora {
    $size-name: nth($size, 1);
    &.e-bolt-type--size-#{$size-name} {
      --e-bolt-type-font-size: var(--bolt-type-font-size-#{$size-name}--lora);
      --e-bolt-type-line-height: var(--bolt-type-line-height-#{$size-name});
      @include bolt-mq($until: medium) {
        @if $size-name ==
          'xlargedisplay' or
          $size-name ==
          'largedisplay' or
          $size-name ==
          'display' or
          $size-name ==
          'xxxlarge'
        {
          font-size: var(--bolt-type-font-size-xxlarge--lora);
          line-height: var(--bolt-type-line-height-xxlarge);
        }
      }
    }
  }

  @each $weight in $bolt-type-font-weight-system-lora {
    $weight-name: nth($weight, 1);
    &.e-bolt-type--weight-#{$weight-name} {
      --e-bolt-type-font-weight: var(
        --bolt-type-font-weight-#{$weight-name}--lora
      );
    }
  }
}

// eyebrow
.e-bolt-type--chakrapetch {
  --e-bolt-type-font-family: var(--bolt-type-font-family-chakrapetch);

  @each $size in $bolt-type-font-size-system {
    $size-name: nth($size, 1);
  }
}
