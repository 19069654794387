/* ------------------------------------ *\
  COLOR UTILITIES
\* ------------------------------------ */

@use 'sass:list';
@use '@bolt/core' as core; // We need this for including within shadowDOM

// Color utility classes
@each $color in core.$bolt-colors {
  $colorName: list.nth($color, 1);

  @each $colorShade, $colorValue in list.nth($color, 2) {
    @if ($colorShade == core.$bolt-color-default) {
      .u-bolt-color-#{$colorName} {
        @include core.bolt-color($colorName, $colorShade, important);
      }
    } @else {
      .u-bolt-color-#{$colorName}-#{$colorShade} {
        @include core.bolt-color($colorName, $colorShade, important);
      }
    }
  }
}
