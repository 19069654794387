/**
 * Text Link
 *
 * Dev notes:
 * 1. Special tricks are used here to make inline icon work, reference the mixin scss to understand it.
 * 2. `em` unit is used to make sure the spacing between icon and text is relative to the text's font-size.
 * 3. Offsets the white space around chevron icons' SVG files.
 * 4. Initial value necessary for Safari to calculate correctly.
 */

@use '@bolt/core' as core;

.e-bolt-text-link {
  @include core.bolt-button-native-styles-reset;
  @include core.bolt-text-link;
}

[class*='e-bolt-text-link__icon'] {
  @include core.bolt-inline-icon-wrapper; /* [1] */
}

.e-bolt-text-link__icon-before {
  margin-inline-end: calc(
    var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xsmall) * 1em
  ); /* [2] */

  > bolt-icon[size] {
    margin-inline-end: var(--bolt-spacing-x-xxsmall);
  }

  > bolt-icon[name*='chevron-'] {
    margin-inline-start: calc(
      var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xxsmall) * -2em
    ); /* [3] */
  }
}

.e-bolt-text-link__icon-after {
  margin-inline-start: calc(
    var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xsmall) * 1em
  ); /* [2] */

  > bolt-icon[size] {
    margin-inline-start: var(--bolt-spacing-x-xxsmall);
  }

  > bolt-icon[name*='chevron-'] {
    margin-inline-end: calc(
      var(--bolt-spacing-x) * var(--bolt-spacing-multiplier-xxsmall) * -2em
    ); /* [3] */
  }
}

.e-bolt-text-link--reversed-underline {
  text-decoration: none;
  text-decoration-thickness: 0; /* [4] */
  text-underline-offset: 0; /* [4] */

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
  }
}

.e-bolt-text-link--expand-click-target {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
