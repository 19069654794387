/* ------------------------------------ *\
   Spacing System (CSS Custom Props)
\* ------------------------------------ */

@use 'sass:list';
@use '@bolt/core' as core;

/**
 * Generate global spacing variables
 *
 * 1. Maps are kept in core/settings.
 */
:root {
  // Base tokens are for public use and user overrides
  // Note: user can customize these to generate their own spacing tokens. Spacing tokens are using formulas based on these.
  @each $size in core.$bolt-spacing-system {
    $size-dir: list.nth($size, 1);
    $size-value: list.nth($size, 2);

    --bolt-spacing-#{$size-dir}: #{$size-value};
  }
  @each $size in core.$bolt-spacing-multiplier-system {
    $size-name: list.nth($size, 1);
    $size-value: list.nth($size, 2);

    --bolt-spacing-multiplier-#{$size-name}: #{$size-value};
  }

  // Spacing tokens are for public use
  // Note: while user overrides are possible but they are not encouraged. Customization should be done through base tokens.
  @each $size in core.$bolt-spacing-x-system {
    $size-name: list.nth($size, 1);
    $size-value: list.nth($size, 2);

    --bolt-spacing-x-#{$size-name}: #{$size-value};
  }
  @each $size in core.$bolt-spacing-y-system {
    $size-name: list.nth($size, 1);
    $size-value: list.nth($size, 2);

    --bolt-spacing-y-#{$size-name}: #{$size-value};
  }
}
