@use 'sass:math';
@use '@bolt/core' as core;

$bolt-ipad-viewer-height: 768px + (90px * 2);
$bolt-ipad-viewer-width: 576px + (25px * 2);

$bolt-ipad-viewer-padding-sm: 25px;
$bolt-ipad-viewer-padding-lg: 90px;

@function bolt-calculate-size($size, $isHeight) {
  @if ($isHeight) {
    @return math.div($size, $bolt-ipad-viewer-height) * 100%;
  }
  @return math.div($size, $bolt-ipad-viewer-width) * 100%;
}

.c-bolt-ipad-viewer {
  width: 100%;
  max-width: $bolt-ipad-viewer-width * .6;
  border-top-right-radius: bolt-calculate-size(44px, false) bolt-calculate-size(44px, true);
  border-top-left-radius: bolt-calculate-size(44px, false) bolt-calculate-size(44px, true);
  border-bottom-right-radius: bolt-calculate-size(44px, false) bolt-calculate-size(44px, true);
  border-bottom-left-radius: bolt-calculate-size(44px, false) bolt-calculate-size(44px, true);
  background: #242324;

  @include core.bolt-shadow('level-40');


  &.c-bolt-ipad-viewer--landscape {
    max-width: $bolt-ipad-viewer-height * .8;
    border-top-right-radius: bolt-calculate-size(44px, true) bolt-calculate-size(44px, false);
    border-top-left-radius: bolt-calculate-size(44px, true) bolt-calculate-size(44px, false);
    border-bottom-right-radius: bolt-calculate-size(44px, true) bolt-calculate-size(44px, false);
    border-bottom-left-radius: bolt-calculate-size(44px, true) bolt-calculate-size(44px, false);
  }

  &.c-bolt-ipad-viewer--silver {
    background: #bcbcbc;
  }
}

.c-bolt-ipad-viewer__inner {
  padding: bolt-calculate-size(90px, false) bolt-calculate-size(25px, false);

  .c-bolt-ipad-viewer--landscape & {
    padding: bolt-calculate-size(25px, true) bolt-calculate-size(90px, true);
  }

  &:before {
    top: bolt-calculate-size(4px, true);
    right: bolt-calculate-size(4px, false);
    bottom: bolt-calculate-size(4px, true);
    left: bolt-calculate-size(4px, false);

    width: auto;
    height: auto;
    border-top-right-radius: bolt-calculate-size(40px, false) bolt-calculate-size(40px, true);

    border-top-left-radius: bolt-calculate-size(40px, false) bolt-calculate-size(40px, true);
    border-bottom-right-radius: bolt-calculate-size(40px, false) bolt-calculate-size(40px, true);
    border-bottom-left-radius: bolt-calculate-size(40px, false) bolt-calculate-size(40px, true);
    background: #1e1e1e;

    .c-bolt-ipad-viewer--landscape & {
      border-top-right-radius: bolt-calculate-size(40px, true) bolt-calculate-size(40px, false);
      border-top-left-radius: bolt-calculate-size(40px, true) bolt-calculate-size(40px, false);
      border-bottom-right-radius: bolt-calculate-size(40px, true) bolt-calculate-size(40px, false);
      border-bottom-left-radius: bolt-calculate-size(40px, true) bolt-calculate-size(40px, false);
    }

    .c-bolt-ipad-viewer--silver & {
      background: #fcfcfc;
    }
  }
}


.c-bolt-ipad-viewer__screen {
  padding-bottom: 133.33159722%;
  background: white;

  .c-bolt-ipad-viewer--landscape & {
    padding-bottom: 73.82908632%;
  }
}

.c-bolt-ipad-viewer__top-bar,
.c-bolt-ipad-viewer__bottom-bar {
  display: block;
  width: 100%;
  height: 3px;
  background: black;
}

.c-bolt-ipad-viewer__middle-bar {
  top: 0px;
  left: 90px;
  width: 3px;
  height: 4px;
  background: black;
}

.c-bolt-ipad-viewer__camera {
  $leftOffset: bolt-calculate-size(44px, false);
  top: bolt-calculate-size(44px, true);
  left: 50%;

  transform: translateX(-50%); // Center align without knowing size of element
  width: bolt-calculate-size(10px, false);
  height: bolt-calculate-size(10px, true);
  background: #3c3d3d;

  .c-bolt-ipad-viewer--landscape & {
    // right: 44px / $bolt-ipad-viewer-width * 100%;
    top: 50%;
    left: calc(100% - #{$leftOffset});
    transform: translateY(-50%) translateX(100%); // Center align without knowing size of element
    width: bolt-calculate-size(10px, true);
    height: bolt-calculate-size(10px, false);
  }
}

.c-bolt-ipad-viewer__top-bar,
.c-bolt-ipad-viewer__bottom-bar {
  display: none;
}

.c-bolt-ipad-viewer__home {
  bottom: bolt-calculate-size(22px, true);
  left: 50%;
  transform: translateX(-50%); // Center align without knowing size of element
  width: bolt-calculate-size(50px, false);
  height: bolt-calculate-size(50px, true);
  background: #242324;

  .c-bolt-ipad-viewer--silver & {
    box-shadow: inset 0 0 0 1px #bcbcbc;
    background: #fcfcfc;

    &:after {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }

  .c-bolt-ipad-viewer--landscape & {
    top: 50%;
    bottom: auto;
    left: bolt-calculate-size(22px, false);
    transform: translateY(-50%) translateX(-25%);
    width: bolt-calculate-size(50px, true);
    height: bolt-calculate-size(50px, false);
    margin-left: 0;// Center align without knowing size of element
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: calc(15 / 50 * 100%);
    height: calc(15 / 50 * 100%);
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.1);
  }
}
