/* ==========================================================================
   #FLAG (AKA MEDIA OBJECT)
   ========================================================================== */

$bolt-flag-sizes: ('small', 'medium');

$bolt-flag-alignments: (
  'top': flex-start,
  'middle': center,
  'bottom': flex-end,
);

.o-bolt-flag {
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: var(--bolt-spacing-y-medium);
  }
}

.o-bolt-flag__body {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%; // Required so IE11 properly calculates the component's layout (and doesn't overflow content) when flex / inline-flex items are nested inside of the flag body
}

.o-bolt-flag--rev {
  direction: rtl;

  > .o-bolt-flag__body {
    direction: ltr;
  }
}

// Flag alignment
@each $alignment, $value in $bolt-flag-alignments {
  .o-bolt-flag--#{$alignment} {
    align-items: $value;
    vertical-align: $alignment;
  }
}

// Flag sizes
@each $size in $bolt-flag-sizes {
  .o-bolt-flag--#{$size} > .o-bolt-flag__figure {
    max-width: 50%; // ensure that Flag media is never wider than 50% the total component width
    margin-right: var(--bolt-spacing-x-#{$size});
  }
}
