@import '@bolt/core';

.c-bds-placeholder {
  display: block;
  height: 100%;
  overflow: hidden;
  color: var(--bolt-color-gray);
  text-align: center;
  text-transform: uppercase;
  text-shadow: 1px 1px 0.08px var(--bolt-color-gray-light);
  border: 3px dashed var(--bolt-color-gray);
  background-color: var(--bolt-color-gray-light);

  &:not(:last-child) {
    margin-bottom: var(--bolt-spacing-y-medium);
  }
}

// Size Options
.c-bds-placeholder--xsmall {
  padding: var(--bolt-spacing-y-xsmall) var(--bolt-spacing-x-xsmall);
}

.c-bds-placeholder--small {
  padding: var(--bolt-spacing-y-small) var(--bolt-spacing-x-small);
}

.c-bds-placeholder--medium {
  padding: var(--bolt-spacing-y-medium) var(--bolt-spacing-x-medium);
}

.c-bds-placeholder--large {
  padding: var(--bolt-spacing-y-large) var(--bolt-spacing-x-large);
}

.c-bds-placeholder--xlarge {
  padding: var(--bolt-spacing-y-xlarge) var(--bolt-spacing-x-xlarge);
}
