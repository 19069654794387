.c-bds-docs__opacity-demo {
  @extend %bolt-demo-wrapper;
  margin-bottom: 2rem;
}

.c-bds-docs__opacity-item {
  @extend %bolt-demo-item-border;
  margin-top: 1rem;
  padding: 1rem;
}
