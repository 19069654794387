/* ------------------------------------ *\
  GENERIC - FONT STACKS
\* ------------------------------------ */

@use 'sass:map';
@use 'sass:list';
@use 'sass:string';
@use '@bolt/tokens' as tokens;

$bolt-font-stacks: (
  openSansLight: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansLight'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansLight'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansLight'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansLight'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansLight'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  openSansRegular: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansRegular'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansRegular'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansRegular'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansRegular'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansRegular'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  openSansItalic: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansItalic'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansItalic'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansItalic'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansItalic'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansItalic'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  openSansSemiBold: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansSemiBold'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansSemiBold'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansSemiBold'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansSemiBold'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansSemiBold'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  openSansSemiBoldItalic: (
    fontFamily:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'openSansSemiBoldItalic'
        ),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'openSansSemiBoldItalic'
        ),
        'style'
      ),
    weight:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'openSansSemiBoldItalic'
        ),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'openSansSemiBoldItalic'
            ),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'openSansSemiBoldItalic'
            ),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  openSansBold: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansBold'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansBold'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansBold'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansBold'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansBold'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  openSansBoldItalic: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansBoldItalic'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansBoldItalic'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansBoldItalic'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'openSansBoldItalic'
            ),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'openSansBoldItalic'
            ),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  openSansExtraBold: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansExtraBold'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansExtraBold'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'openSansExtraBold'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'openSansExtraBold'
            ),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'openSansExtraBold'
            ),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  openSansExtraBoldItalic: (
    fontFamily:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'openSansExtraBoldItalic'
        ),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'openSansExtraBoldItalic'
        ),
        'style'
      ),
    weight:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'openSansExtraBoldItalic'
        ),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'openSansExtraBoldItalic'
            ),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'openSansExtraBoldItalic'
            ),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  loraBold: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraBold'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraBold'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraBold'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraBold'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraBold'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  loraBoldItalic: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraBoldItalic'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraBoldItalic'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraBoldItalic'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraBoldItalic'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraBoldItalic'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  loraMedium: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraMedium'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraMedium'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraMedium'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraMedium'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraMedium'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  loraMediumItalic: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraMediumItalic'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraMediumItalic'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraMediumItalic'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraMediumItalic'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraMediumItalic'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  loraSemiBold: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraSemiBold'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraSemiBold'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraSemiBold'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraSemiBold'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraSemiBold'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  loraSemiBoldItalic: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraSemiBoldItalic'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraSemiBoldItalic'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraSemiBoldItalic'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'loraSemiBoldItalic'
            ),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'loraSemiBoldItalic'
            ),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  loraItalic: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraItalic'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraItalic'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraItalic'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraItalic'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraItalic'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  loraRegular: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraRegular'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraRegular'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraRegular'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraRegular'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'loraRegular'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  chakraPetchBold: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchBold'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchBold'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchBold'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchBold'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchBold'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  chakraPetchBoldItalic: (
    fontFamily:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'chakraPetchBoldItalic'
        ),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'chakraPetchBoldItalic'
        ),
        'style'
      ),
    weight:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'chakraPetchBoldItalic'
        ),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchBoldItalic'
            ),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchBoldItalic'
            ),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  chakraPetchItalic: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchItalic'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchItalic'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchItalic'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchItalic'
            ),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchItalic'
            ),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  chakraPetchLight: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchLight'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchLight'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchLight'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchLight'),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchLight'),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  chakraPetchLightItalic: (
    fontFamily:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'chakraPetchLightItalic'
        ),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'chakraPetchLightItalic'
        ),
        'style'
      ),
    weight:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'chakraPetchLightItalic'
        ),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchLightItalic'
            ),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchLightItalic'
            ),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  chakraPetchRegular: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchRegular'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchRegular'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchRegular'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchRegular'
            ),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchRegular'
            ),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  chakraPetchSemiBold: (
    fontFamily:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchSemiBold'),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchSemiBold'),
        'style'
      ),
    weight:
      map.get(
        map.get(map.get(tokens.$tokens, 'font-stacks'), 'chakraPetchSemiBold'),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchSemiBold'
            ),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchSemiBold'
            ),
            'src'
          ),
          'woff'
        ),
    ),
  ),
  chakraPetchSemiBoldItalic: (
    fontFamily:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'chakraPetchSemiBoldItalic'
        ),
        'fontFamily'
      ),
    style:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'chakraPetchSemiBoldItalic'
        ),
        'style'
      ),
    weight:
      map.get(
        map.get(
          map.get(tokens.$tokens, 'font-stacks'),
          'chakraPetchSemiBoldItalic'
        ),
        'weight'
      ),
    src: (
      woff2:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchSemiBoldItalic'
            ),
            'src'
          ),
          'woff2'
        ),
      woff:
        map.get(
          map.get(
            map.get(
              map.get(tokens.$tokens, 'font-stacks'),
              'chakraPetchSemiBoldItalic'
            ),
            'src'
          ),
          'woff'
        ),
    ),
  ),
);

@each $fontName, $fontValue in $bolt-font-stacks {
  $fontSrc: null;
  $fontSources: map.get($fontValue, 'src');

  @each $ext, $path in $fontSources {
    $fontSrc: list.append($fontSrc, url('#{$path}') format(string.quote($ext)), comma);
  }

  @font-face {
    font-family: map.get($fontValue, 'fontFamily');
    font-style: map.get($fontValue, 'style');
    font-weight: map.get($fontValue, 'weight');
    src: $fontSrc;
    font-display: swap;
  }
}
