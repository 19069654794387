/* ------------------------------------ *\
   Share
\* ------------------------------------ */

@use '@bolt/core' as core;

$bolt-share-block-link-inset: 'small';
$bolt-share-block-link-inline-spacing: 'xsmall';

@include core.bolt-custom-element('bolt-share', block, medium);

.c-bolt-share {
  display: block;
}

@each $key, $value in core.$bolt-opacities {
  @if ($key != '0') {
    .c-bolt-share--opacity-#{$key} {
      @include core.bolt-opacity($key);
      transition: opacity var(--bolt-transition);

      &:hover {
        opacity: core.bolt-opacity(100);
      }
    }
  }
}

.c-bolt-share__label {
  opacity: core.bolt-opacity(60);

  font-size: var(--bolt-type-font-size-xsmall);
  color: var(--m-bolt-text);
  line-height: var(--bolt-type-line-height-xsmall);
}

.c-bolt-share__label--medium {
  font-weight: var(--bolt-type-font-weight-bold);
  text-transform: uppercase;
}

.c-bolt-share__label--small {
  font-weight: var(--bolt-type-font-weight-semibold);
}

.c-bolt-share__link {
  display: block;
  color: var(--m-bolt-link);
  text-decoration: none;
  white-space: nowrap;
  transition: opacity var(--bolt-transition);

  &:hover {
    opacity: core.$bolt-global-link-hover-opacity;
  }

  &:active,
  &:focus:active {
    opacity: core.$bolt-global-link-active-opacity;
  }
}

.c-bolt-share__link-text {
  @include core.bolt-visuallyhidden;
}

// Custom animation is used here because the markup is different than the default copy to clipboard animation.
.c-bolt-share__copy-animation {
  display: inline-block;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: a-bolt-share-copy-animation-spin;
  animation-timing-function: linear;
}

@keyframes a-bolt-share-copy-animation-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1620deg);
  }
}
