/* ------------------------------------ *\
   <a> and <button> resets
\* ------------------------------------ */

a {
  color: var(--m-bolt-link);
}

a:not([class]) {
  @include bolt-text-link;
}
